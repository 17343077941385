import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Link,
  Typography
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import { 
  makeStyles,
} from '@material-ui/core/styles';

import { 
  Save as SaveIcon,
  Print as PrintIcon,
  EditOutlined as EditOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    textTransform: 'capitalize',
    padding: theme.spacing(1, 4),
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  white: {
    color: theme.palette.white,
    borderColor: theme.palette.white
  },
  datePickerContainer: {
    [theme.breakpoints.up('md')] : {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  datePickerRange: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')] : {
      marginTop: 0
    }
  },
  datePickerRangeStart: {
    marginRight: theme.spacing(1)
  }
}));


const AddButton = (buttonType = "text", buttonLabel = "Update", buttonLink = "", buttonData = "") => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = (buttonLink, buttonData) => {
      history.push({
          pathname: buttonLink,
          state: { record: buttonData }
      })
    }

    if( buttonType === 'text' ) {
        return (
            <Link href={buttonLink}>
                <Button 
                    className={clsx(classes.white, classes.button)}
                    variant="outlined"
                    size="small" 
                    startIcon={buttonLabel === "Update" ? <SaveIcon /> : <PrintIcon />}
                    aria-label={buttonLabel}
                    disableElevation
                >
                    {buttonLabel}
                </Button>
            </Link>
        )

    } else if ( buttonType === "icon" ) {
        return (
            <Link href={buttonLink}>
                <IconButton 
                    className={classes.white}
                    aria-label={buttonLabel === "Edit" ? "Edit" : "View"}
                >
                    {buttonLabel === "Edit" ? <EditOutlinedIcon fontSize="small" /> : <VisibilityOutlinedIcon fontSize="small" />}
                </IconButton>
            </Link>
        )
     } else if ( buttonType === "update" ) {
        return (
            <Button 
                className={clsx(classes.white, classes.button)}
                variant="outlined"
                size="small" 
                startIcon={<SaveIcon />}
                aria-label={buttonLabel}
                disableElevation
                onClick={() => handleClick(buttonLink, buttonData)}
            >
                {buttonLabel}
            </Button>
        )      
     }
}

const AddDatePickerRange = () => {
    const classes = useStyles();
    const [selectedStartDate, handleStartDateChange] = useState(new Date());
    const [selectedEndDate, handleEndDateChange] = useState(new Date());

    return (
        <Box display="flex" className={classes.datePickerRange}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    margin="dense"
                    label="Start Date"
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    value={selectedStartDate}
                    className={clsx("datepicker-range", classes.datePickerRangeStart)}
                    onChange={handleStartDateChange}
                    animateYearScrolling
                />
                <DatePicker
                    margin="dense"
                    label="End Date"
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    value={selectedEndDate}
                    className="datepicker-range"
                    onChange={handleEndDateChange}
                    animateYearScrolling
                />
            </MuiPickersUtilsProvider>
        </Box>
    )
}


const BoxTitle = (props) => {
    const classes = useStyles();

    return (
        <>
        { 
            props.datePickerRange === true ? 
                <Box className={clsx("card-title", classes.root, classes.datePickerContainer)}>
                    <Typography component="h2" variant="h2" className={classes.white}>{props.children}</Typography>
                    {AddDatePickerRange()}
                </Box>
            :
                <Box 
                    className={clsx("card-title", classes.root)}
                    height={60}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography component="h2" variant="h2" className={classes.white}>{props.children}</Typography>
                    {props.button === true ? AddButton(props.buttonType, props.buttonLabel, props.buttonLink, props.buttonData) : ''}
                </Box>
        }
        </>
    )
}


export default BoxTitle;
