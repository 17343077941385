import { colors, createMuiTheme } from '@material-ui/core';
import palette from './palette';

const theme = createMuiTheme();

export default {
  h1: {
    color: colors.grey[900],
    fontWeight: 700,
    fontSize: 24
  },
  h2: {
    fontWeight: 500,
    fontSize: 18
  },
  h3: {
    color: palette.primary.dark,
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  body1: {
    color: colors.grey[900],
    fontSize: 14,
    letterSpacing: '-0.05px',
    lineHeight: '22px'
  },
  button: {
    color: palette.text.primary,
    fontSize: 14,
    textTransform: 'capitalize'
  }
};
