import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
	Box,
	Breadcrumbs,
	Button,
	FormControl,
	Grid,
	InputLabel,
	Link,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
	FormHelperText
} from '@material-ui/core';

import { 
		Alert 
} from '@material-ui/lab';

import {
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';

import { 
	makeStyles 
} from '@material-ui/core/styles';


import { 
	BoxTitle,
	PageTitle 
} from './../../../_components';

import { 
	Home as HomeIcon
} from '@material-ui/icons';

import { 
	authenticationService
} from './../../../_services';

import { 
    ADMIN_NATUREDEBITSBYLOCATION,
    ADMIN_TENANTSBYLOCATION
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%'
	},
	button: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
}));


const CreateDebit = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [debitsList, setDebitsList] = useState([]);
	const [tenantsList, setTenantsList] = useState([]);
	const [currentUser] = useState(authenticationService.currentUserValue);
	const [debit] = useState(props.location.state && props.location.state.record);
	const [selectedDate, handleDateChange] = useState(debit ? debit.selectedDate : new Date());
	const [selectedPeriod, handlePeriodChange] = useState(debit ? debit.selectedPeriod : new Date());

	useEffect(() => {
		async function fetchData() {
			const result = await axios.get(
				ADMIN_NATUREDEBITSBYLOCATION,
				{ params: { location: currentUser.locations } }
			);
			setDebitsList(result.data);

			const result2 = await axios.get(
				ADMIN_TENANTSBYLOCATION,
				{ params: { location: currentUser.locations } }
			);
			setTenantsList(result2.data);
		}
				
		if(currentUser) fetchData();
	}, [setDebitsList, setTenantsList, currentUser]);

	 const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
			setSubmitting(true);
			setError(null);
			setSuccess(null);

			const  data = {
				 debitdate: selectedDate,
				 selectedDate: selectedDate,
				 tenant: values.tenant,
				 amount: values.amount,
				 natureOfDebit: values.natureOfDebit,
				 period: selectedPeriod,
				 selectedPeriod: selectedPeriod,
				 remarks: values.remarks,
				 location: currentUser.locations,
				 createdBy: currentUser.id
			}

			const t  = tenantsList.filter(tenants => tenants.id === values.tenant)
			data.tenantname = t[0].lastname + ', ' + t[0].firstname + ' ' + t[0].middlename + ' - Unit ' + t[0].roomnumber

			const d  = debitsList.filter(debits => debits.id === values.natureOfDebit) 
			data.natureofdebitname = d[0].name


			history.push({
				 pathname: '/admin/verify-create-debit',
				 state: { record: data }
			})
	 }

	 const renderSelectNatureOfDebitOptions = () => {
				return debitsList.map((dt, i) => {
						return (
								<MenuItem key={i} value={dt.id}>
										{dt.name}
								</MenuItem>
						);
				});
		}

	 const renderSelectTenantOptions = () => {
				return tenantsList.map((dt, i) => {
						return (
								<MenuItem key={i} value={dt.id}>
										{dt.lastname + ', ' + dt.firstname + ' ' + dt.middlename + ' - Unit ' + dt.roomnumber}
								</MenuItem>
						);
				});
		}

	return (
		<>
			<PageTitle>
				<Typography 
					component="h1" 
					variant="h1" 
					paragraph={false}
				>
					Bills
				</Typography>

				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
					</Link>
					<Link color="inherit" href="#">
						Tenants
					</Link>
					<Link href="#" aria-current="page">
						Create Bill
					</Link>
				</Breadcrumbs>
			</PageTitle>

						{ error &&            
								<Alert severity="error">{ error } </Alert>
						}

						{ success &&
								<Alert severity="success">{ success } </Alert>
						}

						<Formik
								initialValues={{ 
										tenant: debit ? debit.tenant : '', 
										amount: debit ? debit.amount : '',
										natureOfDebit: debit ? debit.natureOfDebit : '',
										remarks: debit ? debit.remarks : '',
								}}
								onSubmit={ handleSubmit }
								validationSchema={Yup.object().shape({
									tenant: Yup.string()
										.required('Required'),
									amount: Yup.number()
										.required('Required'),
									natureOfDebit: Yup.string()
										.required('Required'),
									remarks: Yup.string(),
								})}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleBlur,
										handleSubmit
									} = props;
									return (

						<form onSubmit={handleSubmit} >
				<Paper className="card-box">
					<BoxTitle>Create Bill</BoxTitle>
					
					<Box m={4} className="card-content">
						<Grid container spacing={2} justify="center">
							<Grid item xs={12}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										fullWidth
										label="Billing Date"
										inputVariant="outlined"
										format="MM/dd/yyyy"
										value={selectedDate}
										onChange={handleDateChange}
										animateYearScrolling
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12}>
								<FormControl variant="outlined" className={classes.formControl}>
													<InputLabel id="tenant-name-label">Tenant Name</InputLabel>
														<Select
																label="Tenant Name"
																 labelId="tenant-name-label"
																 id="tenant"
																 name="tenant"
																 value={values.tenant}
																 onChange={handleChange}
																 onBlur={handleBlur}
																 error={errors.tenant && touched.tenant}
															>
																{renderSelectTenantOptions()}
														</Select>
													<FormHelperText>{(errors.tenant && touched.tenant) && errors.tenant}</FormHelperText>
												</FormControl>
							</Grid>

							<Grid item xs={12}>
											<TextField 
													fullWidth
													label="Amount"
													id="amount"
													variant="outlined"
													value={values.amount}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.amount && touched.amount) && errors.amount}
													 error={errors.amount && touched.amount}
												/>
							</Grid>

							<Grid item xs={12}>
								<FormControl variant="outlined" className={classes.formControl}>
													<InputLabel id="nature-of-debit-label">Nature of Bills</InputLabel>
														<Select
																label="Nature of Bills"
																 labelId="nature-of-debit-label"
																 id="natureOfDebit"
																 name="natureOfDebit"
																 value={values.natureOfDebit}
																 onChange={handleChange}
																 onBlur={handleBlur}
																 error={errors.natureOfDebit && touched.natureOfDebit}
															>
																{renderSelectNatureOfDebitOptions()}
														</Select>
													<FormHelperText>{(errors.natureOfDebit && touched.natureOfDebit) && errors.natureOfDebit}</FormHelperText>
												</FormControl>
							</Grid>

							<Grid item xs={12}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										fullWidth
										label="For Period"
										inputVariant="outlined"
												openTo="year"
												views={["year", "month"]}
										value={selectedPeriod}
										onChange={handlePeriodChange}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12}>
											<TextField 
													fullWidth
													label="Remarks"
													id="remarks"
													variant="outlined"
													value={values.remarks}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.remarks && touched.remarks) && errors.remarks}
													 error={errors.remarks && touched.remarks}
												/>
							</Grid>
						</Grid>

									<Button
										type="submit"
										disabled={isSubmitting}
										variant="contained"
										color="secondary"
										className={classes.button}
									>
										 Next
									</Button>
					</Box>
				</Paper>
			</form>
				);
								}}
							</Formik>
		</>
	)
}

export default CreateDebit;