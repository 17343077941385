import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { 
      Alert 
} from '@material-ui/lab';

import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import { 
  makeStyles 
} from '@material-ui/core/styles';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
  authenticationService
} from './../../../_services';

import { 
    ADMIN_FACILITIESBYLOCATION,
    ADMIN_TENANTSBYLOCATION,
    ADMIN_UPDATEBOOKING
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
  formControl: {
	 width: '100%'
  },
  button: {
	 marginTop: theme.spacing(4),
	 marginBottom: theme.spacing(4),
	 marginRight: 5
  },
}));


const EditBooking = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [facilityList, setFacilityList] = useState([]);
	const [tenantsList, setTenantsList] = useState([]);
	const [currentUser] = useState(authenticationService.currentUserValue);
	const [booking] = useState(props.location.state && props.location.state.record);
	const [selectedDate, handleDateChange] = useState(booking ? booking.bookingdate : new Date());

   useEffect(() => {
      async function fetchData() {
         const result = await axios.get(
            ADMIN_FACILITIESBYLOCATION, 
            { params: { location: currentUser.locations } }
         );
         setFacilityList(result.data);

         const result2 = await axios.get(
            ADMIN_TENANTSBYLOCATION,
            { params: { location: currentUser.locations } }
         );
         setTenantsList(result2.data);
      }
            
      if(currentUser) fetchData();
   }, [setFacilityList, setTenantsList, currentUser]);

   const goBack = () => {
     	history.push({
        	pathname: '/admin/view-bookings'
      })
   }

	const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
    	setSubmitting(true);
      setError(null);
      setSuccess(null);

      values.bookingdate = selectedDate
      console.log(values)
        axios
            .post(  
                ADMIN_UPDATEBOOKING,
                values,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               (result.isSuccess) ? setSuccess(result.message) : setError(result.message)
               setSubmitting(false);         
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
                setError(result.message) 
                setSubmitting(false);
            });

	}

   const renderSelectFacilitiesOptions = () => {
      return facilityList.map((dt, i) => {
         return (
               <MenuItem key={i} value={dt.id}>
                  {dt.name}
               </MenuItem>
            );
         });
   }

   const renderSelectTenantOptions = () => {
      return tenantsList.map((dt, i) => {
         return (
            <MenuItem key={i} value={dt.id}>
               {dt.lastname + ', ' + dt.firstname + ' ' + dt.middlename + ' - Unit ' + dt.roomnumber}
            </MenuItem>
         );
      });
   }

	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Bookings
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Tenants
			 		</Link>
			 		<Link href="#" aria-current="page">
						Edit Booking
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

         { error &&            
            <Alert severity="error">{ error } </Alert>
         }

         { success &&
            <Alert severity="success">{ success } </Alert>
         }

         <Formik
            initialValues={{
            	bookingid: booking ? booking.id : '',
            	bookingdate: booking ? booking.selectedDate : '', 
               tenant: booking ? booking.tenant : '', 
               amount: booking ? booking.amount : '',
               facility: booking ? booking.facility : '',
               notes: booking ? booking.notes : '',
               status: booking ? booking.status : '',
               hour: booking ? booking.hour : '12',
               minute: booking ? booking.minute : '00',
               ampm: booking ? booking.ampm : 'AM',
            }}
            onSubmit={ handleSubmit }
            validationSchema={Yup.object().shape({
               tenant: Yup.string()
                  .required('Required'),
               amount: Yup.number()
                  .required('Required'),
               facility: Yup.string()
                  .required('Required'),
               remarks: Yup.string(),
               status: Yup.string()
                  .required('Required'),
               hour: Yup.string()
                  .required('Required'),
               minute: Yup.string()
                  .required('Required'),
               ampm: Yup.string()
                  .required('Required'),
            })}
         >
            {(props) => {
               const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
               } = props;
               
               return (
                  <form onSubmit={handleSubmit} >

		  					<Paper className="card-box">
			 					<BoxTitle>Edit Booking</BoxTitle>
			 					<Box m={4} className="card-content">
									<Grid container spacing={2} justify="center">
				  						<Grid item xs={12}>
					 						<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DatePicker
													fullWidth
												  	label="Date of Booking"
												  	inputVariant="outlined"
												  	format="MM/dd/yyyy"
												  	value={selectedDate}
												  	onChange={handleDateChange}
												  	animateYearScrolling
												/>
					 						</MuiPickersUtilsProvider>
				  						</Grid>

				  						<Grid item xs={12}>
						  					<Grid container spacing={1}>
						  						<Grid item xs={2}>
			                              <FormControl variant="outlined" className={classes.formControl}>
			                                 <InputLabel id="hour-name-label">Hour</InputLabel>
			                                 <Select
			                                    label="Hour"
			                                    labelId="hour-name-label"
			                                    id="hour"
			                                    name="hour"
			                                    value={values.hour}
			                                    onChange={handleChange}
			                                    onBlur={handleBlur}
			                                    error={errors.hour && touched.hour}
			                                 >
			                                 	<MenuItem key={'h1'} value={'01'}>01</MenuItem>
			                                 	<MenuItem key={'h2'} value={'02'}>02</MenuItem>
			                                 	<MenuItem key={'h3'} value={'03'}>03</MenuItem>
			                                 	<MenuItem key={'h4'} value={'04'}>04</MenuItem>
			                                 	<MenuItem key={'h5'} value={'05'}>05</MenuItem>
			                                 	<MenuItem key={'h6'} value={'06'}>06</MenuItem>
			                                 	<MenuItem key={'h7'} value={'07'}>07</MenuItem>
			                                 	<MenuItem key={'h8'} value={'08'}>08</MenuItem>
			                                 	<MenuItem key={'h9'} value={'09'}>09</MenuItem>
			                                 	<MenuItem key={'h10'} value={'10'}>10</MenuItem>
			                                 	<MenuItem key={'h11'} value={'11'}>11</MenuItem>
			                                 	<MenuItem key={'h12'} value={'12'}>12</MenuItem>
			                                 </Select>
			                                 <FormHelperText>{(errors.hour && touched.hour) && errors.hour}</FormHelperText>
			                              </FormControl>
		                              </Grid>

						  						<Grid item xs={2}>
			                              <FormControl variant="outlined" className={classes.formControl}>
			                                 <InputLabel id="minute-name-label">Minute</InputLabel>
			                                 <Select
			                                    label="Minute"
			                                    labelId="minute-name-label"
			                                    id="minute"
			                                    name="minute"
			                                    value={values.minute}
			                                    onChange={handleChange}
			                                    onBlur={handleBlur}
			                                    error={errors.minute && touched.minute}
			                                 >
			                                 	<MenuItem key={'m00'} value={'00'}>00</MenuItem>
			                                 	<MenuItem key={'m01'} value={'01'}>01</MenuItem>
			                                 	<MenuItem key={'m02'} value={'02'}>02</MenuItem>
			                                 	<MenuItem key={'m03'} value={'03'}>03</MenuItem>
			                                 	<MenuItem key={'m04'} value={'04'}>04</MenuItem>
			                                 	<MenuItem key={'m05'} value={'05'}>05</MenuItem>
			                                 	<MenuItem key={'m06'} value={'06'}>06</MenuItem>
			                                 	<MenuItem key={'m07'} value={'07'}>07</MenuItem>
			                                 	<MenuItem key={'m08'} value={'08'}>08</MenuItem>
			                                 	<MenuItem key={'m09'} value={'09'}>09</MenuItem>
			                                 	<MenuItem key={'m10'} value={'10'}>10</MenuItem>
			                                 	<MenuItem key={'m11'} value={'11'}>11</MenuItem>
			                                 	<MenuItem key={'m12'} value={'12'}>12</MenuItem>
			                                 	<MenuItem key={'m13'} value={'13'}>13</MenuItem>
			                                 	<MenuItem key={'m14'} value={'14'}>14</MenuItem>
			                                 	<MenuItem key={'m15'} value={'15'}>15</MenuItem>
			                                 	<MenuItem key={'m16'} value={'16'}>16</MenuItem>
			                                 	<MenuItem key={'m17'} value={'17'}>17</MenuItem>
			                                 	<MenuItem key={'m18'} value={'18'}>18</MenuItem>
			                                 	<MenuItem key={'m19'} value={'19'}>19</MenuItem>
			                                 	<MenuItem key={'m20'} value={'20'}>20</MenuItem>
			                                 	<MenuItem key={'m21'} value={'21'}>21</MenuItem>
			                                 	<MenuItem key={'m22'} value={'22'}>22</MenuItem>
			                                 	<MenuItem key={'m23'} value={'23'}>23</MenuItem>
			                                 	<MenuItem key={'m24'} value={'24'}>24</MenuItem>
			                                 	<MenuItem key={'m25'} value={'25'}>25</MenuItem>
			                                 	<MenuItem key={'m26'} value={'26'}>26</MenuItem>
			                                 	<MenuItem key={'m27'} value={'27'}>27</MenuItem>
			                                 	<MenuItem key={'m28'} value={'28'}>28</MenuItem>
			                                 	<MenuItem key={'m29'} value={'29'}>29</MenuItem>
			                                 	<MenuItem key={'m30'} value={'30'}>30</MenuItem>
			                                 	<MenuItem key={'m31'} value={'31'}>31</MenuItem>
			                                 	<MenuItem key={'m32'} value={'32'}>32</MenuItem>
			                                 	<MenuItem key={'m33'} value={'33'}>33</MenuItem>
			                                 	<MenuItem key={'m34'} value={'34'}>34</MenuItem>
			                                 	<MenuItem key={'m35'} value={'35'}>35</MenuItem>
			                                 	<MenuItem key={'m36'} value={'36'}>36</MenuItem>
			                                 	<MenuItem key={'m37'} value={'37'}>37</MenuItem>
			                                 	<MenuItem key={'m38'} value={'38'}>38</MenuItem>
			                                 	<MenuItem key={'m39'} value={'39'}>39</MenuItem>
			                                 	<MenuItem key={'m40'} value={'40'}>40</MenuItem>
			                                 	<MenuItem key={'m41'} value={'41'}>41</MenuItem>
			                                 	<MenuItem key={'m42'} value={'42'}>42</MenuItem>
			                                 	<MenuItem key={'m43'} value={'43'}>43</MenuItem>
			                                 	<MenuItem key={'m44'} value={'44'}>44</MenuItem>
			                                 	<MenuItem key={'m45'} value={'45'}>45</MenuItem>
			                                 	<MenuItem key={'m46'} value={'46'}>46</MenuItem>
			                                 	<MenuItem key={'m47'} value={'47'}>47</MenuItem>
			                                 	<MenuItem key={'m48'} value={'48'}>48</MenuItem>
			                                 	<MenuItem key={'m49'} value={'49'}>49</MenuItem>
			                                 	<MenuItem key={'m50'} value={'50'}>50</MenuItem>
			                                 	<MenuItem key={'m51'} value={'51'}>51</MenuItem>
			                                 	<MenuItem key={'m52'} value={'52'}>52</MenuItem>
			                                 	<MenuItem key={'m53'} value={'53'}>53</MenuItem>
			                                 	<MenuItem key={'m54'} value={'54'}>54</MenuItem>
			                                 	<MenuItem key={'m55'} value={'55'}>55</MenuItem>
			                                 	<MenuItem key={'m56'} value={'56'}>56</MenuItem>
			                                 	<MenuItem key={'m57'} value={'57'}>57</MenuItem>
			                                 	<MenuItem key={'m58'} value={'58'}>58</MenuItem>
			                                 	<MenuItem key={'m59'} value={'59'}>59</MenuItem>
			                                 </Select>
			                                 <FormHelperText>{(errors.minute && touched.minute) && errors.minute}</FormHelperText>
			                              </FormControl>
		                              </Grid>

						  						<Grid item xs={2}>
			                              <FormControl variant="outlined" className={classes.formControl}>
			                                 <InputLabel id="ampm-name-label">AM / PM</InputLabel>
			                                 <Select
			                                    label="AM / PM"
			                                    labelId="ampm-name-label"
			                                    id="ampm"
			                                    name="ampm"
			                                    value={values.ampm}
			                                    onChange={handleChange}
			                                    onBlur={handleBlur}
			                                    error={errors.ampm && touched.ampm}
			                                 >
			                                 	<MenuItem key={'ampm1'} value={'AM'}>AM</MenuItem>
			                                 	<MenuItem key={'ampm2'} value={'PM'}>PM</MenuItem>
			                                 </Select>
			                                 <FormHelperText>{(errors.ampm && touched.ampm) && errors.ampm}</FormHelperText>
			                              </FormControl>
		                              </Grid>
						  					</Grid>
					  					</Grid>

									  	<Grid item xs={12}>
	                              <FormControl variant="outlined" className={classes.formControl}>
	                                 <InputLabel id="tenant-name-label">Tenant Name</InputLabel>
	                                 <Select
	                                    label="Tenant Name"
	                                    labelId="tenant-name-label"
	                                    id="tenant"
	                                    name="tenant"
	                                    value={values.tenant}
	                                    onChange={handleChange}
	                                    onBlur={handleBlur}
	                                    error={errors.tenant && touched.tenant}
	                                 >
	                                    {renderSelectTenantOptions()}
	                                 </Select>
	                                 <FormHelperText>{(errors.tenant && touched.tenant) && errors.tenant}</FormHelperText>
	                              </FormControl>
									  	</Grid>

					  					<Grid item xs={12}>
	                              <TextField 
	                                 fullWidth
	                                 label="Amount"
	                                 id="amount"
	                                 variant="outlined"
	                                 value={values.amount}
	                                 onChange={handleChange}
	                                 onBlur={handleBlur}
	                                 helperText={(errors.amount && touched.amount) && errors.amount}
	                                 error={errors.amount && touched.amount}
	                              />
					  					</Grid>

					  					<Grid item xs={12}>
	                              <FormControl variant="outlined" className={classes.formControl}>
	                                 <InputLabel id="facility-label">Facility</InputLabel>
	                                 <Select
	                                    label="Facility"
	                                    labelId="facility-label"
	                                    id="facility"
	                                    name="facility"
	                                    value={values.facility}
	                                    onChange={handleChange}
	                                    onBlur={handleBlur}
	                                    error={errors.facility && touched.facility}
	                                 >
	                                    {renderSelectFacilitiesOptions()}
	                                 </Select>
	                                 <FormHelperText>{(errors.facility && touched.facility) && errors.facility}</FormHelperText>
	                              </FormControl>
					  					</Grid>

					  					<Grid item xs={12}>
						 					<FormControl variant="outlined" className={classes.formControl}>
												<InputLabel id="status-label">Status</InputLabel>
												<Select
													label="Status"
													labelId="status-label"
													id="status"
													name="status"
													value={values.status}
													onChange={handleChange}
													onBlur={handleBlur}
	                                    error={errors.status && touched.status}
												>
												  	{[
														{ label: 'Active', value: 1 }, 
													 	{ label: 'Inactive', value: 0 }
												  	].map((item) => (
														<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
												  	))}
												</Select>
												<FormHelperText>{(errors.status && touched.status) && errors.status}</FormHelperText>
						 					</FormControl>
					  					</Grid>

					  					<Grid item xs={12}>
	                              <TextField 
	                                 fullWidth
	                                 label="Remarks"
	                                 id="notes"
	                                 variant="outlined"
	                                 value={values.notes}
	                                 onChange={handleChange}
	                                 onBlur={handleBlur}
	                                 helperText={(errors.notes && touched.notes) && errors.notes}
	                                 error={errors.notes && touched.notes}
	                              />
					  					</Grid>
									</Grid>

                  			<Grid item xs={12} container justify="flex-start">
                     			<Button
                        				type="button"
                        				variant="contained" 
                        				color="default" 
                        				className={classes.button}
                        				onClick={ () => goBack() }
                     			>
                        				Back
                     			</Button>
										<Button
											type="submit"
											disabled={isSubmitting}
											variant="contained"
											color="secondary"
											className={classes.button}
										>
										 Update
										</Button>
                  			</Grid>
								</Box>
		  					</Paper>
						</form>
               );
            }}
         </Formik>
    </>
  )
}


export default EditBooking;