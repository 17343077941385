import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import {
	Box,
 	Button,
  	Breadcrumbs,
  	IconButton,
  	Link,
  	Grid,
  	Paper,
  	Table,
  	TableContainer,
  	TableRow,
  	TableBody,
  	TableCell,
  	Typography,
} from '@material-ui/core';

import {
  	PageTitle 
} from './../../../_components';

import { 
  	Home as HomeIcon,
  	EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_DORMS,
    ADMIN_LOCATIONBYID
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    textTransform: 'capitalize',
    padding: theme.spacing(1, 4),
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
    spacing: {
        padding: theme.spacing(3, 2)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    heading: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.primary.main,
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(0, 2)
    },
  white: {
    color: theme.palette.white,
    borderColor: theme.palette.white
  },
}));



const ViewDorms = () => {
	const classes = useStyles();
  	const history = useHistory();
  	const [data, setData] = useState([]);
  	const [currentUser] = useState(authenticationService.currentUserValue);


  	useEffect(() => {
		async function fetchData() {
			 // You can await here
			 const result = await axios(
				ADMIN_DORMS,
			 );
			 setData(result.data);
		}

		if(currentUser) fetchData();
  	}, [setData, currentUser]);

	const enterDorm = (id, property) => {
		currentUser.locations = id;
		currentUser.property = property;
		localStorage.setItem('user', JSON.stringify(currentUser));

		history.push({
			pathname: '/admin/dashboard'
		})
	}

	const editDorm = async (id, property) => {

	 	const res = await axios.get(
			ADMIN_LOCATIONBYID,
			{ params: { location: id } }
		);	 	
		const record = handleData(res.data[0])

		history.push({
			pathname: '/superadmin/edit-dorm',
			state: { record: record }
		})
	}

	const handleData = (data) => {

      	const rooms = [];

		let record = {
			id: data.id,
			name: data.property,
			address: data.address,
			status: data.status,
			numfloors: data.floors,
			rooms: []
		}

  		let r = []
  		for(let i=0; i<data.floors; i++) {
  			let x = {
        		floornum: i,
        		numroom: 0,
        		names: []
        	}
        	let n = []
	       	data.rooms.map( (room, k) => {
	        	let j = {
	        		roomid: room.id,
	        		roomname: room.roomname
	        	}
	        	if( room.floornum === (i+1) ){
	        		x.floornum = room.floornum
	        		x.numroom = room.roomnum
	        		n.push(j)
	        	}

	        	if( (data.rooms.length-1) === k)
	        		x.names.push(n)
	        })

        	record.rooms.push(x)
  		}

		return record
	}



  return (
	 <>
		<PageTitle>
		  <Typography 
			 component="h1" 
			 variant="h1" 
			 paragraph={false}
		  >
			 Dorms
		  </Typography>

		  <Breadcrumbs aria-label="breadcrumb">
			 <Link color="inherit" href="/dashboard">
				<HomeIcon fontSize="small" />
			 </Link>
			 <Link href="#" aria-current="page">
				View Dorms
			 </Link>
		  </Breadcrumbs>
		</PageTitle>

		<Grid container spacing={4}>
		{ data.map( (value, index) => ( 
		  <Grid key={ index } item xs={12} lg={4}>
			 <Paper className="card-box">
                <Box 
                    className={classes.root}
                    height={60}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                	<Typography component="h2" variant="h2" className={classes.white}>{value.property}</Typography>
	                <IconButton 
	                    className={classes.white}
	                    aria-label={"Edit"}
	                    onClick={ () => editDorm(value.id, value.property ) }
	                >
	                    <EditOutlinedIcon fontSize="small" />
	                </IconButton>
                </Box>

				<TableContainer component={Paper} className={classes.spacing}>
				  <Typography component="h3" className={classes.heading}>Financial Status</Typography>
				  <Table size="small" className={classes.table}>
					 <TableBody>
						  <TableRow>
							 <TableCell>Cash Collected</TableCell>
							 <TableCell>{ new Intl.NumberFormat("en-US", {
						  style: "currency",
						  currency: "PHP",
						  currencyDisplay: 'narrowSymbol',
						  minimumFractionDigits: 2,
						  maximumFractionDigits: 2
					}).format(value.totcredits) }</TableCell>
						  </TableRow>
						  <TableRow>
							 <TableCell>Revenues Recognized</TableCell>
							 <TableCell>{ new Intl.NumberFormat("en-US", {
						  style: "currency",
						  currency: "PHP",
						  currencyDisplay: 'narrowSymbol',
						  minimumFractionDigits: 2,
						  maximumFractionDigits: 2
					}).format(value.totdebits) }</TableCell>
						  </TableRow>
						  <TableRow>
							 <TableCell>Over/(Under) Payment</TableCell>
							 <TableCell>{ new Intl.NumberFormat("en-US", {
						  style: "currency",
						  currency: "PHP",
						  currencyDisplay: 'narrowSymbol',
						  minimumFractionDigits: 2,
						  maximumFractionDigits: 2
					}).format(value.overunder) }</TableCell>
						  </TableRow>
						  <TableRow>
							 <TableCell>Unpaid Balance</TableCell>
							 <TableCell>{ new Intl.NumberFormat("en-US", {
						  style: "currency",
						  currency: "PHP",
						  currencyDisplay: 'narrowSymbol',
						  minimumFractionDigits: 2,
						  maximumFractionDigits: 2
					}).format(value.receivables) }</TableCell>
						  </TableRow>
					 </TableBody>
				  </Table>

				  <Typography component="h3" className={classes.heading}>Occupancy Status</Typography>
				  <Table size="small" className={classes.table}>
					 <TableBody>
						  <TableRow>
							 <TableCell>Units Available</TableCell>
							 <TableCell>{value.available}</TableCell>
						  </TableRow>
						  <TableRow>
							 <TableCell>Units Occupied</TableCell>
							 <TableCell>{value.occupied}</TableCell>
						  </TableRow>
						  <TableRow>
							 <TableCell>Units Vacant</TableCell>
							 <TableCell>{value.available - value.occupied}</TableCell>
						  </TableRow>
					 </TableBody>
				  </Table>
				  <Button
					 onClick={ () => enterDorm(value.id, value.property ) }
					 variant="contained" 
					 color="secondary" 
					 className={classes.button}
				  >
					 Enter Dorm
				  </Button>
				</TableContainer>

			 </Paper>
		  </Grid>

		 ))}

		</Grid>
	 </>
  )
}


export default ViewDorms;
