import React, { useState } from 'react';
import {
    Link,
    useHistory
} from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    TextField,
    Typography 
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { 
    ADMIN_CHANGEPASSWORD,
    TENANT_CHANGEPASSWORD
} from '../../_helpers';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        marginTop: theme.spacing(3),
    }
}));

function ChangePassword(props) {
    const classes = useStyles();
    const history = useHistory();

    const [error, setError] = useState(null);

    const pathname = props.location.pathname;

    const handleSubmit = ( values, setSubmitting ) => {
        setSubmitting(true);
        
        const params = {
            id: props.location.state.record.id,
            password: values.password
        }

        if(pathname === '/change-password')
            adminChangePassword(params, setSubmitting)
        else
            tenantChangePassword(params, setSubmitting)

    }

    const adminChangePassword = ( params, setSubmitting ) => {
          axios
            .post(  
                ADMIN_CHANGEPASSWORD,
                params,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
                if(response.status === 200){
                    
                    setSubmitting(false);
                    history.replace({
                        pathname: '/success',
                        state: { type: 'change-password' }
                    })

                } else {
                    setSubmitting(false);
                    setError(response.data);
                }
            })
            .catch( (error) => {
                setSubmitting(false);
                (error.response) ? setError(error.response.data.msg) :  setError(error.message)
            });      
    }

    const tenantChangePassword = ( params, setSubmitting ) => {
          axios
            .post(  
                TENANT_CHANGEPASSWORD,
                params,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
                if(response.status === 200){
                    
                    setSubmitting(false);
                    history.replace({
                        pathname: '/tenant/success',
                        state: { type: 'change-password' }
                    })

                } else {
                    setSubmitting(false);
                    setError(response.data);
                }
            })
            .catch( (error) => {
                setSubmitting(false);
                (error.response) ? setError(error.response.data.msg) :  setError(error.message)
            });      
    }

    return (
        <div className={classes.paper}>
            <Typography className={classes.title} component="h2" variant="h1">
                Change Password
            </Typography>

            { error &&            
                <Alert severity="error">{ error } </Alert>
            }

            <Formik
                initialValues={{ password: '', passwordConfirm: '' }}
                onSubmit={ (values, { setSubmitting }) => { handleSubmit(values, setSubmitting) }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .required('Required'),
                  passwordConfirm: Yup.string()
                    .oneOf([Yup.ref('password'), null], "Passwords don't match")
                    .required('Required'),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (


            <form className={classes.form} onSubmit={handleSubmit} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.password && touched.password) && errors.password}
                    error={errors.password && touched.password}
                    type="password"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="passwordConfirm"
                    label="Confirm Password"
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.passwordConfirm && touched.passwordConfirm) && errors.passwordConfirm}
                    error={errors.passwordConfirm && touched.passwordConfirm}
                    type="password"
                    id="passwordConfirm"
                />
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Submit
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link to={ pathname === ('/change-password') ? '/sign-in' : '/tenant/sign-in' }>
                            Sign In
                        </Link>
                    </Grid>
     
                </Grid>
            </form>

                  );
                }}
              </Formik>

        </div>
    );
}


export default ChangePassword;