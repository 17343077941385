import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
	authenticationService
} from './../../../_services';

import {
    TENANT_CREDITS
} from './../../../_helpers';

const ViewCredits = props => {
	 const [data, setData] = useState(null);
	 const [currentUser] = useState(authenticationService.currentTenantValue);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios.get(
		  		TENANT_CREDITS,
		  		{ params: { tenant: currentUser.id } }
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);

  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
		  	const item = {
			 	id: idx,
			 	creditid: key.id,
			 	creditdate: new Date(key.creditdate).toLocaleDateString(),
			 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
			 	unit: key.roomnumber,
			 	refno: key.refno === null ? '-----' : key.refno,
			 	txnid: key.txnid === null ? '-----' : key.txnid,
			 	amount: new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "PHP",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                     	}).format(key.amount),
			 	mannerofcredit: key.name,
			 	notes: key.notes
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const columns = [
	  	{
			name: "creditid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "creditdate",
		 	label: "Credit Date",
		 	options: {
		  		display: true,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "txnid",
		 	label: "Transaction ID",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "refno",
		 	label: "Reference Number",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "amount",
		 	label: "Amount",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "mannerofcredit",
		 	label: "Manner of Payment",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: false,
  		print: false,
  		onRowClick: rowData => console.log(rowData)
	};

	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Payments
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Payment History
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Paper className="card-box">
		  		<BoxTitle button={true} buttonLabel="Print">Payment History</BoxTitle>
				{data &&
					<MUIDataTable
						data={data}
						columns={columns}
						options={options}
					/>
				}
			</Paper>
	 	</>
  	)
}


export default ViewCredits;