import { colors } from '@material-ui/core';
import typography from '../typography';

export default {
  root: {
    ...typography.body1
  },
  head: {
    color: colors.blueGrey[700]
  },
  body: {
    color: colors.grey[900]
  },
  sizeSmall: {
    border: 0
  }
};
