import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	 Box,
	 Button,
	 Grid,
	 Paper,
	 Typography,
	 List,
	 ListItem,
	 ListItemText
} from '@material-ui/core';

import {
	Alert
} from '@material-ui/lab';

import { 
	makeStyles 
} from '@material-ui/core/styles';

import {
	BoxTitle,
	PageTitle,
} from './../../../../_components';


// Style
const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%'
	},
	button: {
		margin: theme.spacing(1)
	},
}));


const Verified = (props) => {
	 const classes = useStyles();
	 const history = useHistory();
	 const [credit] = useState(props.location.state && props.location.state.record);
	 const [result] = useState(props.location.state && props.location.state.result);

	const goBack = () => {
		history.push({
			pathname: '/tenant/add-credits',
			state: { record: credit }
		})
	}

	 const done = () => {
		history.push({
			pathname: '/tenant/view-credits'
		})
	 }

	return (
		<>
			<PageTitle>
				<Typography 
					component="h1" 
					variant="h1" 
					paragraph={false}
				>
					Payments
				</Typography>
			</PageTitle>

			<Paper className="card-box">
				<BoxTitle>Payment Created</BoxTitle>

				<Box m={4} className="card-content">

				  { result && !result.isSuccess && <Alert severity="error">{ result.message } </Alert> }
				  { result && result.isSuccess && <Alert severity="success">{ result.message } </Alert> }

					<Grid container spacing={2} justify="flex-start">
						<List>
							<ListItem>
								<ListItemText 
									primary={new Intl.NumberFormat("en-US", {
										style: "currency",
										 currency: "PHP",
										 minimumFractionDigits: 2,
										 maximumFractionDigits: 2
									  }).format(credit.amount)}
									secondary="Amount" />
							</ListItem>
							<ListItem>
								<ListItemText 
									primary={credit.txnid}
									secondary="Transaction ID" />
							</ListItem>
						</List>

						<Grid item xs={12} container justify="flex-start">
							{ !result.isSuccess &&
							<Button
								type="button"
								variant="contained" 
								color="default" 
								className={classes.button}
								onClick={ () => goBack() }
							>
								Back
							</Button>
							}
							<Button 
								type="button"
								variant="contained" 
								color="secondary" 
								className={classes.button}
								onClick={ () => done() }
							>
								Close
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	)
}

export default Verified;