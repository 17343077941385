import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
   Box,
   Breadcrumbs,
   Button,
   FormControl,
   FormHelperText,
   Grid,
   InputLabel,
   Link,
   MenuItem,
   Paper,
   Select,
   TextField,
   Typography
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import { 
   BoxTitle,
   PageTitle,
} from './../../../_components';

import { 
   Home as HomeIcon
} from '@material-ui/icons';

import { 
   authenticationService
} from './../../../_services';

import { 
    ADMIN_DORMS
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
   },
}));


const CreateAdminUser = (props) => {
   const classes = useStyles();
   const history = useHistory();

   const [admin] = useState(props.location.state && props.location.state.record);
   const [dorms, setDorms] = useState([]);
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState(null);
   const [currentUser] = useState(authenticationService.currentUserValue);

   useEffect(() => {
      async function fetchData() {
         const result = await axios(
            ADMIN_DORMS,
         );
         setDorms(result.data);
      }
      if(currentUser)  fetchData();
   }, [setDorms, currentUser]);

   const renderSelectOptions = () => {
      return dorms.map((dt, i) => {
         return (
            <MenuItem key={i} value={dt.id}>
               {dt.property + ' (' + dt.address + ')'}
            </MenuItem>
         );
      });
   }



   const handleSubmit = (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
      setSubmitting(true);
      setError(null);
      setSuccess(null);

      if(values.privilege === 1) {
         values.dorm = ''
         values.property = null
      }else{
         const d  = dorms.filter(dorm => dorm.id === values.dorm)
         values.property = d[0].property + ' (' + d[0].address + ')'
      }

      history.push({
         pathname: '/superadmin/verify-create-admin-user',
         state: { record: values }
      })
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Admin Users
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link href="#" aria-current="page">
                  Create Admin User
               </Link>
            </Breadcrumbs>
         </PageTitle>


         { error && <Alert severity="error">{ error } </Alert> }
         { success && <Alert severity="success">{ success } </Alert> }

         <Formik
            initialValues={{ 
               email: admin ? admin.email : '', 
               firstname: admin ? admin.firstname : '', 
               lastname: admin ? admin.lastname : '', 
               privilege: admin ? admin.privilege : '', 
               dorm: admin ? admin.dorm : '',  }}
            onSubmit={ handleSubmit }
            validationSchema={Yup.object().shape({
              email: Yup.string()
               .email()
               .required('Required'),
              firstname: Yup.string()
               .required('Required'),
              lastname: Yup.string()
               .required('Required'),
              privilege: Yup.string()
               .required('Required'),
              dorm: Yup.string()
               .when('privilege', {
                 is: value => value === '2'  ,
                 then: Yup.string().required('Rquired'),
                 otherwise: Yup.string()
              })
            })}
           >
            {(props) => {
              const {
               values,
               touched,
               errors,
               isSubmitting,
               handleChange,
               handleBlur,
               handleSubmit,
              } = props;
              return (

               <form onSubmit={handleSubmit} >
                 <Paper className="card-box">
                  <BoxTitle>Create Admin User</BoxTitle>

                  <Box m={4} className="card-content">
                    <Grid container spacing={2} justify="center">

                     <Grid item xs={12}>
                       <TextField 
                        fullWidth 
                        label="Email"
                        id="email"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.email && touched.email) && errors.email}
                        error={errors.email && touched.email}
                       />
                     </Grid>

                     <Grid item xs={12}>
                       <TextField 
                        fullWidth 
                        label="First Name"
                        id="firstname"
                        variant="outlined"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.firstname && touched.firstname) && errors.firstname}
                        error={errors.firstname && touched.firstname}
                       />
                     </Grid>

                     <Grid item xs={12}>
                       <TextField 
                        fullWidth 
                        label="Last Name"
                        id="lastname"
                        variant="outlined"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.lastname && touched.lastname) && errors.lastname}
                        error={errors.lastname && touched.lastname}
                       />
                     </Grid>

                     <Grid item xs={12}>
                       <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="privilege-label">Privilege</InputLabel>
                        <Select
                          label="Privilege"
                          labelId="privilege-label"
                          id="privilege"
                          name="privilege"
                          value={values.privilege}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.privilege && touched.privilege}
                        >
                          {[
                           { label: 'Super Admin', value: 1 }, 
                           { label: 'Admin', value: 2 },
                          ].map((item, index) => (
                           <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{(errors.privilege && touched.privilege) && errors.privilege}</FormHelperText>
                       </FormControl>
                     </Grid>

                     { (values.privilege === 2) &&
                     <Grid item xs={12}>
                       <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="dorm-label">Dorm</InputLabel>
                        <Select
                          label="Dorm"
                          labelId="dorm-label"
                          id="dorm"
                          name="dorm"
                          value={values.dorm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.dorm && touched.dorm}
                        >
                          {renderSelectOptions()}
                        </Select>
                        <FormHelperText>{(errors.dorm && touched.dorm) && errors.dorm}</FormHelperText>
                       </FormControl>
                     </Grid>
                     }
                    </Grid>

                    <Button 
                     type="submit"
                     disabled={isSubmitting} 
                     variant="contained" 
                     color="secondary" 
                     className={classes.button}
                    >
                     Next
                    </Button>
                  </Box>
                 </Paper>
               </form>

              );
            }}
           </Formik>

   </>
  )
}


export default CreateAdminUser;