import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff"
    },
    pdfContainer: {
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "row",
        padding: 5
    },
    pdfDetails: {
        display: "flex",
        marginLeft: 5
    },
    pdfTitle: {
        fontSize: 15,
        marginBottom: 10
    },
    pdfOverview: {
        fontSize: 10
    },
    image: {
        height: 200,
        width: 150
    },
    subtitle: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: 150,
        alignItems: "center",
        marginBottom: 5
    },
    vote: {
        display: "flex",
        flexDirection: "row"
    },
    vote_pop: {
        fontSize: 10,
        marginLeft: 10
    },
    vote_pop_text: {
        fontSize: 10,
        marginLeft: 4
    },
    overviewContainer: {
        minHeight: 110,
        marginTop: 20
    }
});

// Create Document Component
const SecurityDepositReport = (props) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.pdfContainer}>
                    <View style={styles.pdfDetails}>
                        <Text style={styles.pdfTitle}>Request to Deposit</Text>
                        <View style={styles.subtitle}>
                            <View style={styles.vote}>
                                <Text style={styles.vote_pop_text}>Name:</Text>
                                <Text style={styles.vote_pop}>{ props.tenant.firstname + ' ' + props.tenant.lastname }</Text> 
                            </View>
                        </View>
                        <View style={styles.subtitle}>
                            <View style={styles.vote}>
                                <Text style={styles.vote_pop_text}>Code:</Text>
                                <Text style={styles.vote_pop}>{ props.tenant.tenantcode }</Text> 
                            </View>
                        </View>
                        <View style={styles.subtitle}>
                            <View style={styles.vote}>
                                <Text style={styles.vote_pop_text}>Building:</Text>
                                <Text style={styles.vote_pop}>{ props.tenant.property }</Text> 
                            </View>
                        </View>
                        <View style={styles.subtitle}>
                            <View style={styles.vote}>
                                <Text style={styles.vote_pop_text}>Room Number:</Text>
                                <Text style={styles.vote_pop}>{ props.tenant.roomnumber }</Text> 
                            </View>
                        </View>
                        <View style={styles.overviewContainer}>
                            <Text style={styles.pdfOverview}>Request to deposit the amount of { new Intl.NumberFormat("en-US", {
                              style: "currency",
                               currency: "PHP",
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2
                             }).format(props.data.amount) }</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};


export default SecurityDepositReport;