import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
 	Link,
  	useHistory
} from "react-router-dom";

import {
 	Avatar,
  	Box,
  	Collapse,
  	Drawer,
  	Hidden,
  	List,
  	ListItem,
  	ListItemIcon,
  	ListItemText,
  	Typography
} from '@material-ui/core';

import { 
  	makeStyles,
  	useTheme
} from '@material-ui/core/styles';

import {
  	KeyboardArrowUp as ChevronUpIcon,
  	KeyboardArrowRight as ChevronRightIcon,
  	DashboardTwoTone as DashboardTwoToneIcon,
  	Assessment as AssessmentIcon,
  	AddToPhotos as AddToPhotosIcon
} from '@material-ui/icons';

import { authenticationService } from './../../../../_services';
import balaySoliven from "assets/dorms/balay-soliven.jpg"
import dormsLogo from "assets/logo/dorms-logo-white.png"

// Style
const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
 	drawer: {
		[theme.breakpoints.up('sm')]: {
	  		width: drawerWidth,
	  		flexShrink: 0,
		},
 	},
  	drawerPaper: {
		width: drawerWidth,
		border: 0,
		boxShadow: '0 5px 5px rgba(0, 0, 0, 0.15)'
  	},
  	logoContainer: {
		backgroundColor: theme.palette.tertiary.main,
		boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
		position: 'fixed',
		top: 0,
		width: drawerWidth,
		padding: theme.spacing(0, 2),
		zIndex: 5
  	},
  	logo: {
		width: theme.spacing(6),
		marginRight: theme.spacing(2)
  	},
  	userDetails: {
		backgroundImage: 'url('+ balaySoliven +')',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		padding: theme.spacing(2)
  	},
  	avatar: {
		color: theme.palette.tertiary.contrastText,
		width: theme.spacing(7),
		height: theme.spacing(7),
		margin: `0 auto ${theme.spacing(1)}px`
  	},
  	smallText: {
		fontSize: 13,
		color: theme.palette.white
  	},
  	name: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.white
  	},
  	nested: {
		paddingLeft: theme.spacing(5)
  	},
  	listSubheader: {
		color: theme.palette.primary.main,
		textTransform: 'uppercase',
		paddingLeft: theme.spacing(4)
  	},
 	arrowIcon: {
		color: theme.palette.grey[400],
		marginRight: theme.spacing(1)
  	},
  	toolbar: theme.mixins.toolbar,
}));


const Sidebar = props => {
 	const { container, onSidebarOpen, mobileOpen } = props;

  	const theme = useTheme();
  	const classes = useStyles();
  	const history = useHistory();
  	const [open, setOpen] = React.useState(true);
  	const [openReports, setOpenReports] = React.useState(true);
  	const [currentUser] = useState(authenticationService.currentUserValue);

  	if(!currentUser) {
	  	authenticationService.logout()
	  	history.replace({
		  	pathname: '/sign-in'
	  	})
  	}

  	const handleClick = () => {
		 setOpen(!open);
  	};

  	const handleClickReports = () => {
		 setOpenReports(!openReports);
  	};

  	const drawer = (
		<div className="sidebar">
	  		<Box className={classes.toolbar} />
	  		<Box 
				className={clsx(classes.toolbar, classes.logoContainer)}
				display="flex"
				alignItems="center"
	  		>
				<img src={dormsLogo} className={classes.logo} alt="Logo" />
	  		</Box>

	  		<Box
				component="div" 
				className={classes.userDetails} 
				textAlign="center"
	  		> 
				<Avatar 
					className={classes.avatar} 
					alt="Avatar">
					{ currentUser && currentUser.privilege === 2 && currentUser.firstname.charAt(0) }
					{ currentUser && currentUser.privilege !== 2 && currentUser.property.charAt(0) }
				</Avatar>

				<div>
		  			<Typography component="span" className={classes.smallText}>Welcome</Typography>
		  			<Typography className={classes.name}>
		  				{ currentUser && currentUser.privilege === 2 && currentUser.firstname }
		  				{ currentUser && currentUser.privilege !== 2 && currentUser.property }
		  			</Typography>
				</div>
	  		</Box>

	  		<List component="div">
				<Link to="/admin/dashboard" color="inherit" underline="none">
			  		<ListItem button>
						<ListItemIcon>
				  			<DashboardTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary="Dashboard" />
			  		</ListItem>
				</Link>

				<ListItem button onClick={handleClick}>
		  			<ListItemIcon>
						<AddToPhotosIcon />
		  			</ListItemIcon>
		  			<ListItemText primary="Actions" />
		  			{ open ? 
		  				<ChevronUpIcon className="chevron-icon active" fontSize="small" /> 
		  				: 
		  				<ChevronUpIcon className="chevron-icon" fontSize="small" />
		  			}
				</ListItem>
				<Collapse in={open} timeout="auto" unmountOnExit>
		  			<List component="div" disablePadding>
						{[
							{ label: 'Accounts', link: 'create-account' }, 
							{ label: 'Bills', link: 'create-debit' },
							{ label: 'Payments', link: 'create-credit' },
							{ label: 'Bookings', link: 'create-booking' }
						].map((item, index) => (
							<Link key={index} to={item.link} color="inherit" underline="none">
								<ListItem 
									button 
									className={classes.nested}
									key={item.link}
								>
									<ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
								  	<ListItemText primary={item.label} />
								</ListItem>
							</Link>
						))}
		  			</List>
				</Collapse>


				<ListItem button onClick={handleClickReports}>
		  			<ListItemIcon>
						<AssessmentIcon />
		  			</ListItemIcon>
		  			<ListItemText primary="Reports" />
		  			{ openReports ? 
		  				<ChevronUpIcon className="chevron-icon active" fontSize="small" /> 
		  				: 
		  				<ChevronUpIcon className="chevron-icon" fontSize="small" />
		  			}
				</ListItem>
				<Collapse in={openReports} timeout="auto" unmountOnExit>
		  			<List component="div" disablePadding>
						{[
							{ label: 'Profiles', link: 'profiles' },
			  				{ label: 'Unverified Profiles', link: 'profiles-unverified' }, 
			  				{ label: 'Billing History', link: 'view-debits' },
			  				{ label: 'Payment History', link: 'view-credits' },
			  				{ label: 'Balances', link: 'view-balances' },
			  				{ label: 'Bookings', link: 'view-bookings' }
						].map((item, index) => (
			  				<Link key={index} to={item.link} color="inherit" underline="none">
								<ListItem 
					  				button
					  				className={classes.nested}
					  				key={item.link}
								>
					  				<ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
					  				<ListItemText primary={item.label} />
								</ListItem>
			  				</Link>
						))}
		  			</List>
				</Collapse>
	  		</List>
		</div>
  	);

  	return (
		<nav className={classes.drawer} aria-label="nav sidebar">
	  		{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
	  		<Hidden smUp implementation="css">
				<Drawer
		  			container={container}
		  			anchor={theme.direction === 'rtl' ? 'right' : 'left'}
		  			open={mobileOpen}
		  			onClose={onSidebarOpen}
		  			classes={{
						paper: classes.drawerPaper,
		  			}}
		  			ModalProps={{
						keepMounted: true, // Better open performance on mobile.
		  			}}
				>
		  			{drawer}
				</Drawer>
	  		</Hidden>
	  
	  		<Hidden xsDown implementation="css">
				<Drawer
			  		classes={{
						paper: classes.drawerPaper,
			  		}}
			  		variant="permanent"
			  		open
				>
			  		{drawer}
				</Drawer>
	  		</Hidden>
		</nav>
  	)
};


Sidebar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  container: PropTypes.any,
  mobileOpen: PropTypes.bool
};

export default Sidebar;