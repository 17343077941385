import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography,
  	Grid,
  	FormControlLabel,
  	Switch
} from '@material-ui/core';

import { 
  	BoxTitle,
  	PageTitle 
} from './../../../_components';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_BALANCESBYLOCATION
} from './../../../_helpers';

const ViewBalances = props => {
  	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [checked, setChecked] = useState(true);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios.get(
		  		ADMIN_BALANCESBYLOCATION,
		  		{ params: { location: currentUser.locations, status: checked } }
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);


  	const handleChange = async () => {
	   	let prev = checked
	   	setChecked(!prev);
	   	const result = await axios.get(
		  	ADMIN_BALANCESBYLOCATION,
		  	{ params: { location: currentUser.locations, status: !prev } }
		);
		const d = handleData(result.data)
		setData(d);
  	};


	const formatNumber = (decimalsFormated) => {
	    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	 }

  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
	 		const security = Number(key.security)
	 		// const cash = parseFloat(key.credits) 
	 		const revenues = parseFloat(key.debits)

	 		const credits = parseFloat(key.totcredits)
	 		const debits = parseFloat(key.totdebits)
	 		const regular = (credits-security)

			const cash = (credits - security) - debits;

	 		const paymentsapplied = Math.min(debits, regular)
	 		const overunder = regular - debits
	 		const receivables = debits - paymentsapplied

		  	const item = {
			 	id: idx,
			 	tenantid: key.id,
			 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
			 	tenantcode: key.tenantcode,
			 	unit: key.roomnumber,
			 	specialcb: security ? formatNumber(security.toFixed(2)) : 0,
			 	regularcb:  cash ? ( (cash > 0 ) ? formatNumber(cash.toFixed(2)) : 0) : 0,
			 	debits: debits ? formatNumber(debits.toFixed(2)) : 0,
			 	creditsapplied: paymentsapplied ? formatNumber(paymentsapplied.toFixed(2)) : 0,
			 	receivablebal: receivables ? formatNumber(receivables.toFixed(2)) : 0,
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const columns = [
	  	{
			name: "tenantid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "tenant",
		 	label: "Tenant",
		 	options: {
		  		display: true,
		  		filter: true,
		  		sort: false,
			}
	  	},
	  	{
			name: "tenantcode",
		 	label: "Tenant Code",
		 	options: {
		 		display: false,
		  		filter: false,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "unit",
		 	label: "Unit",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "specialcb",
		 	label: "Special Payment Balance",
		 	options: {
		  		filter: false,
		  		sort: false,
		  		setCellHeaderProps: () => ({ align: 'right' }),
		  		setCellProps: () => ({ align: 'right' })
		 	}
	  	},
	  	{
			name: "regularcb",
		 	label: "Regular Payment Balance",
		 	options: {
		  		filter: false,
		  		sort: false,
		  		setCellHeaderProps: () => ({ align: 'right' }),
		  		setCellProps: () => ({ align: 'right' })
		 	}
	  	},
	  	{
			name: "debits",
		 	label: "Bills",
		 	options: {
		  		filter: false,
		  		sort: false,
		  		setCellHeaderProps: () => ({ align: 'right' }),
		  		setCellProps: () => ({ align: 'right' })
		 	}
	  	},
	  	{
			name: "creditsapplied",
		 	label: "Payments Applied",
		 	options: {
		  		filter: false,
		  		sort: false,
		  		setCellHeaderProps: () => ({ align: 'right' }),
		  		setCellProps: () => ({ align: 'right' })
		 	}
	  	},
	  	{
			name: "receivablebal",
		 	label: "Unpaid Balance",
		 	options: {
		  		filter: false,
		  		sort: false,
		  		setCellHeaderProps: () => ({ align: 'right' }),
		  		setCellProps: () => ({ align: 'right' })
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
		downloadOptions: {
			filename: 'balances.csv',
			separator: ',',
			filterOptions: {
				useDisplayedColumnsOnly: true,
			    useDisplayedRowsOnly: true
			}
		},
  		print: false,
  		// onRowClick: rowData => console.log(rowData)
	};

	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Balances
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/admin/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Reports
			 		</Link>
			 		<Link href="#" aria-current="page">
						Balances
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
			   <FormControlLabel
	        		control={ <Switch checked={checked} onChange={ handleChange } /> }
	        		label={ checked ? "Active" : "Archived" }
	      		/>
	      	</Grid>

			<Paper className="card-box">
		  		<BoxTitle>Balances</BoxTitle>
				{data &&
					<MUIDataTable
						data={data}
						columns={columns}
						options={options}
					/>
				}
			</Paper>
	 	</>
  	)
}

export default ViewBalances;