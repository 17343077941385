import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme();

export default {
  root: {
    minWidth: 900,
    [theme.breakpoints.up('lg')]: {
      minWidth: 'unset'
    }
  }
}