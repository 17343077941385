import React, { useState } from 'react';
import {
    Link,
    useHistory
} from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    TextField,
    Typography 
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { 
    ADMIN_FORGOTPASSWORD,
    TENANT_FORGOTPASSWORD
} from '../../_helpers';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        marginTop: theme.spacing(3),
    }
}));

function ForgotPassword(props) {
    const classes = useStyles();
    const history = useHistory();

    const [error, setError] = useState(null);

    const pathname = props.location.pathname;

    const handleSubmit = ( values, setSubmitting ) => {
        setSubmitting(true)

        const params = {
            email: values.email,
        }

        if(pathname === '/forgot-password')
            adminForgotPassword(params, setSubmitting)
        else
            tenantForgotPassword(params, setSubmitting)
      
    }

    const adminForgotPassword = ( params, setSubmitting ) => {
        console.log('Admin Forgot Password')
        axios
            .post(  
                ADMIN_FORGOTPASSWORD,
                params,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
                if(response.status === 200){
                    
                    setSubmitting(false);
                    history.replace({
                        pathname: '/success',
                        state: { type: 'forgot-password' }
                    })

                } else {
                    setSubmitting(false);
                    setError(response.data);
                }
            })
            .catch( (error) => {
                setSubmitting(false);
                (error.response) ? setError(error.response.data.msg) :  setError(error.message)
            });
    }

    const tenantForgotPassword = ( params, setSubmitting ) => {
        console.log('Tenant Forgot Password')
        axios
            .post(  
                TENANT_FORGOTPASSWORD,
                params,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
                if(response.status === 200){
                    
                    setSubmitting(false);
                    history.replace({
                        pathname: '/tenant/success',
                        state: { type: 'forgot-password' }
                    })

                } else {
                    setSubmitting(false);
                    setError(response.data);
                }
            })
            .catch( (error) => {
                setSubmitting(false);
                (error.response) ? setError(error.response.data.msg) :  setError(error.message)
            });
    }

    return (
        <div className={classes.paper}>
            <Typography className={classes.title} component="h2" variant="h1">
                Forgot Password
            </Typography>

            { error &&            
                <Alert severity="error">{ error } </Alert>
            }

            <Formik
                initialValues={{ email: '' }}
                onSubmit={ (values, { setSubmitting }) => { handleSubmit(values, setSubmitting) }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .required('Required'),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (

                    <form className={classes.form} onSubmit={handleSubmit} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.email && touched.email) && errors.email}
                            error={errors.email && touched.email}
                            autoComplete="email"
                        />
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Submit
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to={ pathname === ('/forgot-password') ? '/sign-in' : '/tenant/sign-in' }>
                                    Sign In
                                </Link>
                            </Grid>
             
                        </Grid>
                    </form>

                  );
                }}
              </Formik>

        </div>
    );
}


export default ForgotPassword;