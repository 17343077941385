import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Link,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const Verified = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [booking] = useState(props.location.state && props.location.state.record);
    const [result] = useState(props.location.state && props.location.state.result);

   const goBack = () => {
      history.push({
         pathname: '/admin/create-booking',
         state: { record: booking }
      })
   }

    const done = () => {
      history.push({
         pathname: '/admin/view-bookings'
      })
    }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Bookings
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/dashboard">
                <HomeIcon fontSize="small" />
              </Link>
              <Link color="inherit" href="#">
                Tenants
              </Link>
              <Link href="#" aria-current="page">
                Create Booking
              </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Booking Created</BoxTitle>

            <Box m={4} className="card-content">

              { result && !result.isSuccess && <Alert severity="error">{ result.message } </Alert> }
              { result && result.isSuccess && <Alert severity="success">{ result.message } </Alert> }

               <Grid container spacing={2} justify="flex-start">
                 <List>
                     <ListItem>
                        <ListItemText 
                           primary={booking.bookingdate.toLocaleDateString()}
                           secondary="Date of Booking" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={booking.tenantname}
                           secondary="Tenant Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={new Intl.NumberFormat("en-US", {
                              style: "currency",
                               currency: "PHP",
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2
                             }).format(booking.amount)}
                           secondary="Amount" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={booking.facilityname}
                           secondary="Facility" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={ (booking.status === 1) ? 'Active' : 'Inactive' }
                           secondary="Status" />
                     </ListItem>
                     { booking.remarks !== "" &&
                     <ListItem>
                        <ListItemText 
                           primary={booking.remarks}
                           secondary="Remarks" />
                     </ListItem>
                    }
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     { !result.isSuccess &&
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     }
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => done() }
                     >
                        Close
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default Verified;