import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { 
	Alert 
} from '@material-ui/lab';

import {
	Box,
	Breadcrumbs,
	FormControl,
	Grid,
	Link,
	Paper,
	Typography,
} from '@material-ui/core';


import { 
	makeStyles 
} from '@material-ui/core/styles';


import { 
	BoxTitle,
	PageTitle,
} from './../../../_components';

import { 
	authenticationService
} from './../../../_services';

import { 
	ADMIN_PROFILEDETAILS
} from './../../../_helpers';

import { 
	Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	formControl: {
		width: '100%'
	},
	button: {
		margin: theme.spacing(4, 2, 0, 0)
	}
}));

const ViewProfile = (props) => {
	const classes = useStyles();
	const [account, setAccount] = useState(null);
	const [currentUser] = useState(authenticationService.currentTenantValue);


	useEffect(() => {
		async function fetchData() {
			const tenants = await axios(
				ADMIN_PROFILEDETAILS,
				{ params: { tenant: currentUser.id} }
			);
			handleAccount(tenants.data)
		}

		if(currentUser) fetchData();
	}, [setAccount]);

	const convertDate = (datetime) => {
      const cdatetime = new Date(datetime);
      const cdate = cdatetime.toISOString().slice(0, 19).replace('T', ' ');
      return cdate.split(' ')[0];
	}

const formatVal = (decimalsFormated) => {
    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  	const handleAccount = (data) => {
  		const details = data.details;
  		const ref = data.references;


      const birthdate = convertDate(details.birthdate)
      const startdate = convertDate(details.rentstart)

  		let account = {
            tenantid: details.id,
            firstname: details.firstname, 
            middlename: details.middlename,
            lastname: details.lastname,
            address: details.address,
            birthdate: birthdate,
            gender: (details.gender === 1) ? 'Male' : 'Female',
            mobile: details.mobile,
            position: details.positioncourse,
            landline: details.landline,
            civilstatus: (details.civilstatus === 1) ? 'Single' : 'Married',
            email: details.email,
            partnersname: details.spouse,
            instagram: details.instagram,
            sourceoffunds: details.sourceofpayment,
            employername: details.nameempschool,
            employeraddress: details.addressempschool,
            tenantcode: details.tenantcode,
            roomnumber: details.roomnumber,
            rentamount: formatVal(details.rent),
            withaircon: (details.aircon === 1) ? 'Yes' : 'No',
            advance: formatVal(details.advance),
            deposit: formatVal(details.deposit),
            contractterm: details.contractterm,
            startdate: startdate,
            refid1: ref[0].id,
			   refname1: ref[0].name,
            refaddress1: ref[0].address,
            refmobile1: ref[0].mobile,
            reflandline1: ref[0].landline,
            refrelationship1: ref[0].relationship,
            refid2: ref[1].id,
            refname2: ref[1].name,
            refaddress2: ref[1].address,
            refmobile2: ref[1].mobile,
            reflandline2: ref[1].landline,
            refrelationship2: ref[1].relationship
  		}
  		setAccount(account)
  	}

	return (
		<>
				<PageTitle>
					<Typography 
							component="h1" 
							variant="h1" 
							paragraph={false}
					>
							Profile
					</Typography>
				</PageTitle>
{ account ? 
				<Grid container spacing={4}>
			 		<Grid item lg={6}>
						<Paper className="card-box">
				  			<BoxTitle>Tenant</BoxTitle>

				  			<Box m={4} className="card-content">
					 			<Grid container spacing={2}>						
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		First Name
                          		</Typography>
                          		<Typography variant="body1" >
											{account.firstname}
                          		</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Middle Name
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.middlename}
                          		</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Last Name
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.lastname}
                          		</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Address
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.address}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Birth Date
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.birthdate}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Gender
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.gender}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Mobile Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.mobile}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Landline Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.landline}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Email Address
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.email}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Instagram
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.instagram}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Civil Status
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.civilstatus}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Partner's Name
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.partnersname}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Source of Funds
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.sourceoffunds}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Position/Course
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.positioncourse}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Name of Employer/School
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.employername}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Address of Employer/School
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.employeraddress}
                            	</Typography>
									</Grid>
								</Grid>
				  			</Box>
						</Paper>
			 		</Grid>

			 		<Grid item lg={6}>
						<Paper className="card-box">
				  			<BoxTitle>Rent Information</BoxTitle>

				  			<Box m={4} className="card-content">
					 			<Grid container spacing={2}>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Room Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.roomnumber}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		With Aircon
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.withaircon}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Start Date
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.startdate}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Contract Term (in months)
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.contractterm}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Rent Amount
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.rentamount}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Advance
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.advance}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Deposit
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.deposit}
                            	</Typography>
									</Grid>
					 			</Grid>
				  			</Box>
						</Paper>

						<Paper className="card-box">
				  			<BoxTitle>Emergency Contact Person</BoxTitle>

				  			<Box m={4} className="card-content">
					 			<Grid container spacing={2}>
									<Grid item xs={12}>
			                  	<Typography>Contact Person 1</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Name
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refname1}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Address
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refaddress1}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Mobile Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refmobile1}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Landline Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.reflandline1}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Relationship
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refrelationship1}
                            	</Typography>
									</Grid>

									<Grid item xs={12}>
			                  	<Typography>&nbsp;</Typography>
									</Grid>

									<Grid item xs={12}>
			                  	<Typography>Contact Person 2</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Name
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refname2}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Address
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refaddress2}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Mobile Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refmobile2}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Landline Number
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.reflandline2}
                            	</Typography>
									</Grid>
									<Grid item xs={12}>
			                  	<Typography variant="caption" display="block" gutterBottom>
                            		Relationship
                          		</Typography>
                          		<Typography variant="body1" >
                            		{account.refrelationship2}
                            	</Typography>
									</Grid>
					 			</Grid>
				  			</Box>
						</Paper>

			 		</Grid>
		  		</Grid>
:
<span>loading</span>
}
			</>

	)
}

export default ViewProfile;