import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Link,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const Verified = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [admin] = useState(props.location.state && props.location.state.record);
    const [result] = useState(props.location.state && props.location.state.result);

   const goBack = () => {
      history.push({
         pathname: '/superadmin/create-admin-user',
         state: { record: admin }
      })
   }

    const done = () => {
      history.push({
         pathname: '/superadmin/view-admin-users'
      })
    }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Admin Users
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link href="#" aria-current="page">
                  Create Admin User
               </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Admin User Created</BoxTitle>

            <Box m={4} className="card-content">

              { result && !result.isSuccess && <Alert severity="error">{ result.message } </Alert> }
              { result && result.isSuccess && <Alert severity="success">{ result.message } </Alert> }

               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={admin.email}
                           secondary="Email" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={admin.firstname}
                           secondary="First Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={admin.lastname} 
                           secondary="Last Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={ (admin.privilege === 1) ? 'Super Admin' : 'Admin'} 
                           secondary="Privilege" />
                     </ListItem>
                     {admin.property &&
                     <ListItem>
                        <ListItemText 
                           primary={admin.property} 
                           secondary="Dorm" />
                     </ListItem>
                     }
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     { !result.isSuccess &&
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     }
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => done() }
                     >
                        Close
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default Verified;