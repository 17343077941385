import React from 'react';

import {
  Box
} from '@material-ui/core';

import { 
  makeStyles 
} from '@material-ui/core/styles';


// Style
const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(1)
  }
}));


const FileUploader = (props) => {
  const classes = useStyles();

  return (
    <Box className="file-uploader" marginBottom={1}>
      { props.type === 'image' ? 
        <>
          <label htmlFor="file" className={classes.label}>Upload Image:</label>
          <input type="file" id="file" name="file" accept="image/*" /> 
        </>
        : 
        <>
          <label htmlFor="file" className={classes.label}>Upload File:</label>
          <input type="file" id="file" name="file" /> 
        </>
      }
    </Box>
  )
}


export default FileUploader;