import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Breadcrumbs,
   Button,
   Grid,
   Link,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
    ADMIN_SAVEDORM
} from './../../../../_helpers';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const VerifyCreateDorm = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [dorm] = useState(props.location.state && props.location.state.record);

   const goBack = () => {  
      history.push({
         pathname: '/superadmin/create-dorm',
         state: { record: dorm }
      })
   }

   const createDorm = () => {
      axios
         .post(  
            ADMIN_SAVEDORM,
            dorm,
            {
               headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
               }
            },
         )
         .then( (response)  => {
            const result = {
               isSuccess: false,
               message: ''
            }
            if(response.status === 200){
               result.isSuccess = true;
               result.message = response.data.data
            } else {
               result.isSuccess = false;
               result.message = response.data
            }

            history.push({
               pathname: '/superadmin/verified-create-dorm',
               state: { record: dorm, result: result }
            })
         })
         .catch( (error) => {
            const result = {
               isSuccess: false,
               message: error.message
            }
            history.push({
               pathname: '/superadmin/verified-create-dorm',
               state: { record: dorm, result: result }
            })
         });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Dorms
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link href="#" aria-current="page">
                  Create Dorm
               </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Verify Dorm</BoxTitle>

            <Box m={4} className="card-content">
               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={dorm.name}
                           secondary="Property Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={dorm.address}
                           secondary="Address" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={(dorm.status === 1) ? 'Active' : 'Inactive'} 
                           secondary="Status" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={dorm.numfloors} 
                           secondary="Number of Floors" />
                     </ListItem>
                  </List>


                  <Grid item xs={12} container justify="flex-start">
                     
                     {dorm.rooms.map((room, i) => {
                        return (
                           <Grid container>
                              <Grid item xs={3}>
                                 <Typography>Floor {room.floornum}</Typography>
                                 <Typography>{room.numroom} Rooms</Typography>
                                 <List>
                                 {room.names.map((name, y) => {
                                     let elements=[];
                                     for(let x=0;x<name.length;x++){
                                       elements.push(
                                         <ListItem>
                                             <ListItemText 
                                                primary="" 
                                                secondary={(x+1) + ". " + name[x].roomname} />
                                         </ListItem>
                                       );
                                     }
                                     return(
                                       <span key={y}>{elements}</span>
                                     )
                                 })}
                                 </List>
                              </Grid>
                           </Grid>
                        )
                     })}
                  </Grid>

                  <Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => createDorm() }
                     >
                        Create Dorm
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default VerifyCreateDorm;