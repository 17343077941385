import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';

const Copyright = props => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://dorms.ph/">
            Dorms.ph
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

Copyright.propTypes = {
    className: PropTypes.string
};

export default Copyright;


