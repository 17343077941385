import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Typography
} from '@material-ui/core';

import { 
  Save as SaveIcon
} from '@material-ui/icons';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { makeStyles } from '@material-ui/core/styles';

import { 
  authenticationService
} from './../../../_services';

import { 
    ADMIN_ANNOUNCEMENTS,
    TENANT_TOP5ARCHIVE,
    ADMIN_ALLROOMS,
    ADMIN_SUPERFINANCIAL,
    ADMIN_PROFILEDETAILS
} from './../../../_helpers';


// Style
const useStyles = makeStyles((theme) => ({
  spacing: {
    padding: theme.spacing(3, 2)
  },
  borderRight: {
    [theme.breakpoints.up('lg')]: {
      borderRight: `2px solid ${theme.palette.grey[300]}`,
    },
  },
  borderBottom: {
    [theme.breakpoints.up('lg')]: {
      borderBottom: `2px solid ${theme.palette.grey[300]}`
    }
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
}));


const financialStatusRows = [
  {
    id: 1,
    label: 'Cash Collected',
    amount: '0.00'
  },
  {
    id: 2,
    label: 'Revenues Recognized',
    amount: '0.00'
  },
  {
    id: 3,
    label: 'Over/(Under) Payment',
    amount: '0.00'
  },
  {
    id: 4,
    label: 'Unpaid Balance',
    amount: '0.00'
  }
];


const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const [announcements, setAnnouncements] = useState([]);
  const [globalAnnouncement, setGlobalAnnouncement] = useState([]);
  const [reminderAnnouncement, setReminderAnnouncement] = useState([]);
  const [adminAnnouncement, setAdminAnnouncement] = useState([]);
  const [archivedAccount, setArchivedAccount] = useState([]);
  const [occupancyRows, setOccupancyRows] = useState([]);
  const [financialStatusRows, setFinancialStatusRows] = useState([]);
  const [currentUser] = useState(authenticationService.currentUserValue);

  async function processRooms(room) {

    let total = {
      available: 0, 
      occupied: 0, 
      vacant: 0,
      occupancy: 0
    }

    let occupied = 0
    let available = 0
    room.forEach(r => {

      if(r.tenants === null)
          available += 1
      else
        occupied += 1      
    })

    
    let vacant = parseInt(available)
    let avail = vacant + parseInt(occupied)
    let x = (parseInt(occupied) / parseInt(avail) ) * 100

    total.available += parseInt(avail)
    total.occupied += parseInt(occupied)
    total.vacant += parseInt(vacant)
    total.occupancy += x
    
    let y = [
      {
        id: 1,
        label: 'Units Available',
        count: avail
      },
      {
        id: 2,
        label: 'Units Occupied',
        count: occupied
      },
      {
        id: 3,
        label: 'Units Vacant',
        count: vacant
      },
      {
        id: 4,
        label: 'Occupancy %',
        count: x.toFixed(2) + '%'
      }
    ]

    setOccupancyRows(y)
  }

  const formatVal = (decimalsFormated) => {
    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  async function processFinancials(financials) {
    const data = [
      {
        id: 1,
        label: 'Cash Collected',
        amount: financials.credits && formatVal(financials.credits.toFixed(2))
      },
      {
        id: 2,
        label: 'Revenues Recognized',
        amount: financials.debits && formatVal(financials.debits.toFixed(2))
      },
      {
        id: 3,
        label: 'Over/(Under) Payment',
        amount: financials.overunder && formatVal(financials.overunder.toFixed(2))
      },
      {
        id: 4,
        label: 'Unpaid Balance',
        amount: financials.receivables && formatVal(financials.receivables.toFixed(2))
      }
    ];

    setFinancialStatusRows(data)
  }

  useEffect(() => {
        async function fetchData() {
            const result = await axios(
                ADMIN_ANNOUNCEMENTS,
            );
            setGlobalAnnouncement(result.data[0].announcement);
            setReminderAnnouncement(result.data[1].announcement);
            setAdminAnnouncement(result.data[3].announcement);
            setAnnouncements(result.data);

            const archived = await axios(
                TENANT_TOP5ARCHIVE,
            );
            setArchivedAccount(archived.data);

            const r = await axios(
                ADMIN_ALLROOMS,
            );
            processRooms(r.data)

            const financials = await axios(
                ADMIN_SUPERFINANCIAL,
            );
            processFinancials(financials.data)

        }
        if(currentUser)  fetchData();
  }, [setGlobalAnnouncement, setArchivedAccount, currentUser]);

   const viewTenant = async (tenant_id) => {
      console.log(tenant_id)
      const tenants = await axios(
        ADMIN_PROFILEDETAILS,
        { params: { tenant: tenant_id} }
      );

      const account = handleAccount(tenants.data)

          history.push({
              pathname: '/superadmin/view-tenant',
              state: { record: account, prevPath: 'dashboard' }
          })  

   }

  const convertDate = (datetime) => {
      const cdatetime = new Date(datetime);
      const cdate = cdatetime.toISOString().slice(0, 19).replace('T', ' ');
      return cdate.split(' ')[0];
  }

  const formatNum = (decimalsFormated) => {
    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

    const handleAccount = (data) => {
      const details = data.details;
      const ref = data.references;


      const birthdate = convertDate(details.birthdate)
      const startdate = convertDate(details.rentstart)

      let account = {
            tenantid: details.id,
            firstname: details.firstname, 
            middlename: details.middlename,
            lastname: details.lastname,
            address: details.address,
            birthdate: birthdate,
            gender: (details.gender === 1) ? 'Male' : 'Female',
            mobile: details.mobile,
            position: details.positioncourse,
            landline: details.landline,
            civilstatus: (details.civilstatus === 1) ? 'Single' : 'Married',
            email: details.email,
            partnersname: details.spouse,
            instagram: details.instagram,
            sourceoffunds: details.sourceofpayment,
            employername: details.nameempschool,
            employeraddress: details.addressempschool,
            tenantcode: details.tenantcode,
            roomnumber: details.roomnumber,
            rentamount: formatNum(details.rent),
            withaircon: (details.aircon === 1) ? 'Yes' : 'No',
            advance: formatNum(details.advance),
            deposit: formatNum(details.deposit),
            contractterm: details.contractterm,
            startdate: startdate,
            refid1: ref[0].id,
          refname1: ref[0].name,
            refaddress1: ref[0].address,
            refmobile1: ref[0].mobile,
            reflandline1: ref[0].landline,
            refrelationship1: ref[0].relationship,
            refid2: ref[1].id,
            refname2: ref[1].name,
            refaddress2: ref[1].address,
            refmobile2: ref[1].mobile,
            reflandline2: ref[1].landline,
            refrelationship2: ref[1].relationship
      }
      
      return account
    }


  return (
    <>
      <PageTitle>
        <Typography 
          component="h1" 
          variant="h1" 
          paragraph={false}
        >
          Dashboard
        </Typography>
      </PageTitle>

      <Grid container spacing={4}>
        <Grid item lg={12}>
          <Paper className="card-box">
            <BoxTitle 
              button={true} 
              buttonType={"update"} 
              buttonLink={"/superadmin/announcement"}
              buttonData={announcements}
            >
              Announcements
            </BoxTitle>

            <Grid container>
              <Grid item lg={6}>
                <Box m={4} className={clsx("card-content", classes.borderRight)}>
                  <Box className="scroll-area">
                    <Box className="scroll-content">  
                      <Typography component="h3" variant="h3">Global</Typography>
                      <Typography style={{whiteSpace: 'pre-line'}}>{ globalAnnouncement }</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box m={4} className={clsx("card-content", classes.borderBottom)}>
                  <Typography component="h3" variant="h3">Reminder to Admins</Typography>
                  <Box className="scroll-area small">
                    <Box className="scroll-content">
                      <Typography paragraph={ true } style={{whiteSpace: 'pre-line'}}>{ reminderAnnouncement }</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box m={4} className="card-content">
                  <Typography component="h3" variant="h3">Message from Admin</Typography>
                  <Box className="scroll-area small">
                    <Box className="scroll-content">
                      <Typography style={{whiteSpace: 'pre-line'}}>{ adminAnnouncement }</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className="card-box">
            <BoxTitle 
              button={true} 
              buttonType="icon"
              buttonLink="/app/superadmin/view-archived-accounts"
            >
              Archived Accounts
            </BoxTitle>

            <TableContainer component={Paper} className={classes.spacing}>
              <Table size="small">
               <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Unit #</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell>Archive Date</TableCell>
                </TableRow>
               </TableHead>
                <TableBody>
                  { archivedAccount.length !== 0
                    ? archivedAccount.map((row, index) => (
                      <TableRow key={'arch-' + index}>
                        <TableCell><Button color="primary" onClick={ () => viewTenant(row.id) }>{ row.lastname + ', ' + row.firstname }</Button></TableCell>
                        <TableCell>{ 'Unit ' + row.roomnumber }</TableCell>
                        <TableCell>{ row.property }</TableCell>
                        <TableCell>{ new Date(row.dateupdated).toLocaleDateString() }</TableCell>
                      </TableRow>
                     )) 
                    : <TableRow>
                      <TableCell>No Archived Accounts</TableCell>
                     </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className="card-box">
            <BoxTitle>Financial Status</BoxTitle>

            <TableContainer component={Paper} className={classes.spacing}>
              <Table size="small">
                <TableBody>
                  {financialStatusRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>₱ {row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className="card-box">
            <BoxTitle>Occupancy Status</BoxTitle>

            <TableContainer component={Paper} className={classes.spacing}>
              <Table size="small">
                <TableBody>
                  {occupancyRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>{row.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}


export default Dashboard;