import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Breadcrumbs,
   Button,
   Grid,
   Link,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
    ADMIN_SAVETENANT
} from './../../../../_helpers';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	formControl: {
		width: '100%'
	},
	button: {
		margin: theme.spacing(4, 2, 0, 0)
	}
}));

const VerifyCreateAccount = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const [account] = useState(props.location.state && props.location.state.record);

   const goBack = () => {
      history.push({
         pathname: '/admin/create-account',
         state: { record: account }
      })
   }

   const createAccount = () => {
   		account.aircon = (account.withaircon === 'yes' ? 1 : 0)
   		account.civil = (account.civilstatus === 'single' ? 1 : 2)
   		account.sex = (account.gender === 'male' ? 1 : 2)

        axios
            .post(  
                ADMIN_SAVETENANT,
                account,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               history.push({
                  pathname: '/admin/verified-create-account',
                  state: { record: account, result: result }
               })
            
            })
            .catch( (error) => {
               let result = {
                  isSuccess: false,
                  message: error.message
               }

               if (error.response.status === 401) {
               	result.message = 'Email already exists. Please try different email.'
            	}
            	
               history.push({
                  pathname: '/admin/verified-create-account',
                  state: { record: account, result: result }
               })
            });
   }

	return (
		<>
			<PageTitle>
				<Typography 
					component="h1" 
					variant="h1" 
					paragraph={false}
				>
					Accounts
				</Typography>

				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
					</Link>
					<Link color="inherit" href="#">
						Tenants
					</Link>
					<Link href="#" aria-current="page">
						Create Account
					</Link>
				</Breadcrumbs>
			</PageTitle>
				
         <Paper className="card-box">
            <BoxTitle>Verify Create Account</BoxTitle>
				<Grid container spacing={4}>
					<Grid item lg={6}>
						<Paper className="card-box">
							<BoxTitle>Tenant</BoxTitle>

							<Box m={4} className="card-content">
								<Grid container spacing={2}>
						      	<List>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.firstname }
						                  secondary="First Name" />
						            </ListItem>
						            <ListItem>
						              	<ListItemText 
						                 	primary={ account.middlename }
						                  secondary="Middle Name" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.lastname }
						                  secondary="Last Name" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.address }
						                  secondary="Address" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.birthdate.toLocaleDateString() }
						                  secondary="Birth Date" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.gender.charAt(0).toUpperCase() + account.gender.slice(1) }
						                  secondary="Gender" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.mobile }
						                  secondary="Mobile Number" />
						            </ListItem>
						            { account.landline !== '' &&
						            <ListItem>
						               <ListItemText 
						                  primary={ account.landline }
						                  secondary="Landline Number" />
						            </ListItem>
						         	}
						            <ListItem>
						               <ListItemText 
						                  primary={ account.civilstatus.charAt(0).toUpperCase() + account.civilstatus.slice(1) }
						                  secondary="Civil Status" />
						            </ListItem>
						            { account.civilstatus === 'married' &&
						            <ListItem>
						               <ListItemText 
						                  primary={ account.partnersname }
						                  secondary="Partner's Name" />
						            </ListItem>
						         	}
						            <ListItem>
						               <ListItemText 
						                  primary={ account.email }
						                  secondary="Email Address" />
						            </ListItem>
						            { account.instagram !== '' &&
						            <ListItem>
						               <ListItemText 
						                  primary={ account.instagram }
						                  secondary="Instagram" />
						            </ListItem>	
						         	}
						            <ListItem>
						               <ListItemText 
						                  primary={ account.sourceoffunds }
						                  secondary="Source of Funds" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.position }
						                  secondary="Position/Course" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.employername }
						                  secondary="Name of Employer/School" />
						            </ListItem>
						            <ListItem>
						               <ListItemText 
						                  primary={ account.employeraddress }
						                  secondary="Address of Employer/School" />
						            </ListItem>						            
						      	</List>
								</Grid>
							</Box>
						</Paper>
					</Grid>

					<Grid item lg={6}>
						<Paper className="card-box">
							<BoxTitle>Rent Information</BoxTitle>

							<Box m={4} className="card-content">
								<Grid container spacing={2}>
									<List>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.roomnumber }
						                  secondary="Room Number" />
						            </ListItem>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.withaircon.charAt(0).toUpperCase() + account.withaircon.slice(1) }
						                  secondary="With Aircon" />
						            </ListItem>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.startdate.toLocaleDateString() }
						                  secondary="Start Date" />
						            </ListItem>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.contractterm }
						                  secondary="Contract Term (in months)" />
						            </ListItem>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.rentamount }
						                  secondary="Rent Amount" />
						            </ListItem>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.deposit }
						                  secondary="Deposit" />
						            </ListItem>
						         	<ListItem>
						            	<ListItemText 
						               	primary={ account.advance }
						                  secondary="Advance" />
						            </ListItem>		
						         </List>
								</Grid>
							</Box>
						</Paper>

						<Paper className="card-box">
							<BoxTitle>Emergency Contact Person</BoxTitle>
				  
							<Box m={4} className="card-content">
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Typography>Contact Person 1</Typography>
										<List>
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refname1 }
							                  secondary="Name" />
							            </ListItem>
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refaddress1 }
							                  secondary="Address" />
							            </ListItem>
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refmobile1 }
							                  secondary="Mobile Number" />
							            </ListItem>
							            { account.reflandline1 !== '' &&
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.reflandline1 }
							                  secondary="Landline Number" />
							            </ListItem>
							         	}
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refrelationship1 }
							                  secondary="Relationship" />
							            </ListItem>
						            </List>

						            { account.refname2 !== '' &&
						            <>
										<Typography>Contact Person 2</Typography>
										<List>
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refname2 }
							                  secondary="Name" />
							            </ListItem>
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refaddress2 }
							                  secondary="Address" />
							            </ListItem>
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refmobile2 }
							                  secondary="Mobile Number" />
							            </ListItem>
							            { account.reflandline2 !== '' &&
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.reflandline2 }
							                  secondary="Landline Number" />
							            </ListItem>
							         	}
							         	<ListItem>
							            	<ListItemText 
							               	primary={ account.refrelationship2 }
							                  secondary="Relationship" />
							            </ListItem>
						            </List>
						            </>
						         	}
									</Grid>
								</Grid>
							</Box>
						</Paper>
						
						<Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => createAccount() }
                     >
                        Create Account
                     </Button>
                  </Grid>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default VerifyCreateAccount;