import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SecurityDepositReport from "./../SecurityDepositReport";

import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Link,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const Verified = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [tenant] = useState(props.location.state && props.location.state.tenant);
    const [debit] = useState(props.location.state && props.location.state.record);
    const [result] = useState(props.location.state && props.location.state.result);
    const [show, setHide] = useState(false)

    const goBack = () => {
      history.push({
         pathname: '/admin/create-debit',
         state: { record: debit }
      })
    }

    const done = () => {
      history.push({
         pathname: '/admin/view-debits'
      })
    }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Bills
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/dashboard">
                <HomeIcon fontSize="small" />
              </Link>
              <Link color="inherit" href="#">
                Tenants
              </Link>
              <Link href="#" aria-current="page">
                Create Bill
              </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Bills Created</BoxTitle>

            <Box m={4} className="card-content">

              { result && !result.isSuccess && <Alert severity="error">{ result.message } </Alert> }
              { result && result.isSuccess &&
                  <div>
                    <Alert severity="success">{ result.message } </Alert>

                    { debit.natureofdebitname === 'Security Deposit' &&
                      <PDFDownloadLink
                        document={<SecurityDepositReport data={debit} tenant={tenant} />}
                        fileName={"Request To Deposit-" + tenant.firstname + ' ' + tenant.lastname + "-" + moment().format("MM-DD-YYYY") + ".pdf" }
                        style={{
                          textDecoration: "none",
                          padding: "10px",
                          color: "#4a4a4a",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #4a4a4a"
                        }}
                      >
                        {({ blob, url, loading, error }) =>
                          "Download Pdf"
                        }
                      </PDFDownloadLink>
                    }
                  </div>
              }

               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={debit.debitdate.toLocaleDateString()}
                           secondary="Billing Date" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={debit.tenantname}
                           secondary="Tenant Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={new Intl.NumberFormat("en-US", {
                              style: "currency",
                               currency: "PHP",
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2
                             }).format(debit.amount)}
                           secondary="Amount" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={debit.natureofdebitname}
                           secondary="Nature of Bills" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={new Intl.DateTimeFormat("en-US", {
                               year: "numeric",
                               month: "long",
                             }).format(debit.period)}
                           secondary="For Period" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={debit.remarks}
                           secondary="Remarks" />
                     </ListItem>
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     { !result.isSuccess &&
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     }
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => done() }
                     >
                        Close
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default Verified;