import React from 'react';
import { Router } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { history } from '_helpers';
import theme from 'theme';
import Routes from 'Routes';

//import './index.scss';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Routes />
            </Router>
        </ThemeProvider>
    );
}

export default App;
