import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Breadcrumbs,
   Button,
   Grid,
   Link,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
    ADMIN_SAVECREDIT
} from './../../../../_helpers';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const VerifyCreateCredit = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [credit] = useState(props.location.state && props.location.state.record);

   const goBack = () => {
      history.push({
         pathname: '/admin/create-credit',
         state: { record: credit }
      })
   }

   const createCredit = () => {
        console.log(credit)

        const data = {
            creditdate: credit.selectedDate,
            tenant: credit.tenant,
            amount: credit.amount,
            txnid: null,
            refno: null,
            mannerofcredit: credit.mannerOfCredit,
            notes: credit.remarks,
            location: credit.location,
            createdBy: credit.createdBy
        }

        axios
            .post(  
                ADMIN_SAVECREDIT,
                data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               history.push({
                  pathname: '/admin/verified-create-credit',
                  state: { record: credit, result: result }
               })
            
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
               history.push({
                  pathname: '/admin/verified-create-credit',
                  state: { record: credit, result: result }
               })
            });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Payments
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link color="inherit" href="#">
                  Tenants
               </Link>
               <Link href="#" aria-current="page">
                  Create Payment
               </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Verify Create Payment</BoxTitle>

            <Box m={4} className="card-content">
               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={credit.creditdate.toLocaleDateString()}
                           secondary="Date of Payment" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={credit.tenantname}
                           secondary="Tenant Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={new Intl.NumberFormat("en-US", {
                              style: "currency",
                               currency: "PHP",
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2
                             }).format(credit.amount)}
                           secondary="Amount" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={credit.mannerofcreditname}
                           secondary="Manner of Payment" />
                     </ListItem>
                     { credit.remarks !== "" &&
                     <ListItem>
                        <ListItemText 
                           primary={credit.remarks}
                           secondary="Remarks" />
                     </ListItem>
                    }
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => createCredit() }
                     >
                        Create Payment
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default VerifyCreateCredit;