import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom';

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Switch
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_BOOKINGSBYLOCATION,
    ADMIN_BOOKINGBYID
} from './../../../_helpers';

const ViewBookings = props => {
		const history = useHistory();
  	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [checked, setChecked] = useState(true);

  	useEffect(() => {
		 	async function fetchData() {
				const result = await axios.get(
			  		ADMIN_BOOKINGSBYLOCATION,
			  		{ params: { location: currentUser.locations, status: checked } }
				);
				const d = handleData(result.data)
				setData(d);
		 	}
		 	if(currentUser) fetchData();
  	}, [setData, currentUser]);

  	const handleChange = async () => {
	   	let prev = checked
	   	setChecked(!prev);
	   	const result = await axios.get(
		  	ADMIN_BOOKINGSBYLOCATION,
		  	{ params: { location: currentUser.locations, status: !prev } }
			);
			const d = handleData(result.data)
			setData(d);
  	};

  	const columns = [
	  	{
			name: "bookingid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "bookingdate",
		 	label: "Booking Date",
		 	options: {
		  		display: true,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "bookingtime",
		 	label: "Booking Time",
		 	options: {
		  		display: true,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "tenant",
		 	label: "Tenant",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "unit",
		 	label: "Unit",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "amount",
		 	label: "Amount",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "facility",
		 	label: "Facility",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "notes",
		 	label: "Notes",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "status",
		 	label: "Status",
		 	options: {
		 		display: false,
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
			downloadOptions: {
				filename: 'bookings.csv',
				separator: ',',
				filterOptions: {
					useDisplayedColumnsOnly: true,
				    useDisplayedRowsOnly: true
				}
			},
  		print: false,
  		setRowProps: row => {
				return {
        	style: { cursor: "pointer" }
        };
  		},
  		onRowClick: async (rowData, rowMeta) => {
	 			const res = await axios.get(
					ADMIN_BOOKINGBYID,
					{ params: { bookingid: data[rowMeta.dataIndex].bookingid } }
				);
				const result = res.data[0]

				result.selectedDate = new Date(result.bookingdate)

	  			history.push({
			        pathname: '/admin/edit-booking',
			        state: { record: result }
			    }) 	
	  	}
	};

  	const handleData = (result) => {
		 	const record = [];
		 	result.map( (key, idx) => {
			  	const item = {
				 	id: idx,
				 	bookingid: key.id,
				 	bookingdate: new Date(key.bookingdate).toLocaleDateString(),
				 	bookingtime: key.hour + ':' + key.minute + ' ' + key.ampm,
				 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
				 	unit: key.roomnumber,
				 	amount: key.amount,
				 	facility: key.facilityname,
				 	notes: key.notes,
				 	status: (key.status === 1) ? 'Active' : 'Inactive',
			  	}

			  	record.push(item)
			  	return console.log('');
			})

			return record;
  	}

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Bookings
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Reports
			 		</Link>
			 		<Link href="#" aria-current="page">
						Bookings
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
			   <FormControlLabel
	        		control={ <Switch checked={checked} onChange={ handleChange } /> }
	        		label={ checked ? "Active" : "Inactive" }
	      		/>
	      	</Grid>

			<Paper className="card-box">
		  		<BoxTitle>Bookings</BoxTitle>
				{data &&
					<MUIDataTable
						data={data}
						columns={columns}
						options={options}
					/>
				}
			</Paper>
		</>
  )
}

export default ViewBookings;