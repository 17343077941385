import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
	authenticationService
} from './../../../_services';

import {
    TENANT_DEBITS
} from './../../../_helpers';

const ViewDebits = props => {
	const [data, setData] = useState(null);
	const [currentUser] = useState(authenticationService.currentTenantValue);

	useEffect(() => {
		async function fetchData() {
			const result = await axios.get(
			 	TENANT_DEBITS,
			 	{ params: { tenant: currentUser.id } }
		  	);
		  	const d = handleData(result.data)
		  	setData(d);
		}
		if(currentUser) fetchData();
	}, [setData, currentUser]);

  	const monthName = (month) => {
  		if(month === 1)
  			return 'Jan'
  		if(month === 2)
  			return 'Feb'
  		if(month === 3)
  			return 'Mar'
  		if(month === 4)
  			return 'Apr'
  		if(month === 5)
  			return 'May'
  		if(month === 6)
  			return 'Jun'
  		if(month === 7)
  			return 'Jul'
  		if(month === 8)
  			return 'Aug'
  		if(month === 9)
  			return 'Sep'
  		if(month === 10)
  			return 'Oct'
  		if(month === 11)
  			return 'Nov'
  		if(month === 12)
  			return 'Dec'
  	}

  	const columns = [
	  	{
			name: "debitid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "debitdate",
		 	label: "Debit Date",
		 	options: {
		  		display: true,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "amount",
		 	label: "Amount",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "natureofdebit",
		 	label: "Nature of Bills",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "period",
		 	label: "Period",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

  	const options = {
		filterType: 'multiselect',
		responsive: 'vertical',
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		viewColumns: false,
		download: false,
		print: false,
		onRowClick: rowData => console.log(rowData)
  	};

	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
		  	const item = {
			 	id: idx,
			 	debitid: key.id,
			 	debitdate: new Date(key.debitdate).toLocaleDateString(),
			 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
			 	unit: key.roomnumber,
			 	period: monthName(key.month) + ' ' + key.year,
			 	amount: new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "PHP",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                     	}).format(key.amount),
			 	natureofdebit: key.name,
			 	notes: key.remarks
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
	}

	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Bills
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Billing History
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Paper className="card-box">
		  		<BoxTitle button={true} buttonLabel="Print">Billing History</BoxTitle>
		  		{data &&
					<MUIDataTable
						data={data}
						columns={columns}
						options={options}
					/>
				}
			</Paper>
	 	</>
  	)
}

export default ViewDebits;