import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography,
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import {
  	PageTitle
} from './../../../_components';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_ADMINSUNVERIFIED,
    ADMIN_ADMINSDELETE
} from './../../../_helpers';

const ViewAdminUsersUnverified = props => {
 	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
   	const [error, setError] = useState(null);
   	const [success, setSuccess] = useState(null);

	async function fetchData() {
		const result = await axios.get(
		  	ADMIN_ADMINSUNVERIFIED, { }
		);
		const d = handleData(result.data)
		setData(d);
	}

  	useEffect(() => {
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);


  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
		  	const item = {
			 	id: idx,
			 	name: key.firstname + ' ' + key.lastname,
			 	username: key.username,
			 	dorm: (key.property ===  null ) ? '---' : key.property,
			 	privilege: (key.privilege === 1) ? 'Super Admin' : 'Admin',
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const columns = [
	  	{
			name: "id",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "name",
		 	label: "Name",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "username",
		 	label: "Username",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "dorm",
		 	label: "Dorm",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "privilege",
		 	label: "Privilege",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: false,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
		downloadOptions: {
			filename: 'unverifiedAdminUsers.csv',
			separator: ',',
			filterOptions: {
				useDisplayedColumnsOnly: true,
			   useDisplayedRowsOnly: true
			}
		},
  		print: false,
		onRowsDelete: (rowsDeleted, dataRows) => {
			const usersToDelete = rowsDeleted.data.map(d => data[d.dataIndex].username);

		      axios
		          .post( 
		              ADMIN_ADMINSDELETE,
		              {usernames: usersToDelete},
		              {
		                  headers: {
		                      'Access-Control-Allow-Origin': '*',
		                      'Content-Type': 'application/json',
		                  }
		              },
		          )
		          .then( (response)  => {
					const d = handleData(response.data)
					setData(d);
      				setSuccess('Account/s successfully deleted');
		          })
		          .catch( (error) => {
		          	setError(error);
		          });
		}
  		// onRowClick: rowData => console.log(rowData)
	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Admin Users
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link href="#" aria-current="page">
						View Unverified Admin Users
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>


         { error && <Alert severity="error">{ error } </Alert> }
         { success && <Alert severity="success">{ success } </Alert> }

		  	<Paper className="card-box">
				{data &&
					<MUIDataTable
						title={"Unverified Admin Users"}
						data={data}
						columns={columns}
						options={options}
					/>
				}
		 	 </Paper>
	 </>
  )
}

export default ViewAdminUsersUnverified;