import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom';

import {
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography,
  	Switch,
  	FormControlLabel,
  	Grid
} from '@material-ui/core';

import {
  	PageTitle
} from './../../../_components';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_ADMINSBYSTATUS,
    ADMIN_ADMINBYID
} from './../../../_helpers';

const AdminUsers = props => {
	const history = useHistory();
 	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [checked, setChecked] = useState(true);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios.get(
		  		ADMIN_ADMINSBYSTATUS,
		  		{ params: { status: checked } }
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);


  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
	 		
		  	const item = {
		  		id: idx,
			 	adminid: key.adminid,
			 	name: key.firstname + ' ' + key.lastname,
			 	username: key.username,
			 	dorm: (key.property ===  null || key.privilege === 1) ? '---' : key.property,
			 	privilege: (key.privilege === 1) ? 'Super Admin' : 'Admin',
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const columns = [
 	  	{
			name: "id",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "adminid",
		 	label: "Admin ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "name",
		 	label: "Name",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "username",
		 	label: "Username",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "dorm",
		 	label: "Dorm",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "privilege",
		 	label: "Privilege",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
		downloadOptions: {
			filename: 'adminUsers.csv',
			separator: ',',
			filterOptions: {
			    useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true
			}
		},
  		print: false,
  		setRowProps: row => {
			return {
            	style: { cursor: "pointer" }
          	};
  		},
  		onRowClick: async (rowData, rowMeta) => {
	 		const res = await axios.get(
				ADMIN_ADMINBYID,
				{ params: { adminId: data[rowMeta.dataIndex].adminid } }
			);

			const result = res.data[0]

	  		history.push({
		        	pathname: '/superadmin/edit-admin-user',
		        	state: { record: result }
		    })  
  		}
	};


  	const handleChange = async () => {
	   	let prev = checked
	   	setChecked(!prev);
	   	const result = await axios.get(
		  	ADMIN_ADMINSBYSTATUS,
		  	{ params: { status: !prev } }
		);
		const d = handleData(result.data)
		setData(d);
  	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Admin Users
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link href="#" aria-current="page">
						View Admin Users
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
			   <FormControlLabel
	        		control={ <Switch checked={checked} onChange={ handleChange } /> }
	        		label={ checked ? "Active" : "Archived" }
	      		/>
	      	</Grid>

		  	<Paper className="card-box">
				{data &&
					<MUIDataTable
						title={"Admin Users"}
						data={data}
						columns={columns}
						options={options}
					/>
				}
		 	 </Paper>
	 </>
  )
}

export default AdminUsers;