import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
	Box,
	Breadcrumbs,
	Button,
	FormControl,
	Grid,
	InputLabel,
	Link,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
	FormHelperText
} from '@material-ui/core';

import { 
		Alert 
} from '@material-ui/lab';

import {
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';

import { 
	makeStyles 
} from '@material-ui/core/styles';


import { 
	BoxTitle,
	PageTitle 
} from './../../../_components';

import { 
	Home as HomeIcon
} from '@material-ui/icons';

import { 
	authenticationService
} from './../../../_services';

import { 
    ADMIN_MANNERCREDITS,
    ADMIN_TENANTSBYLOCATION,
    ADMIN_UPDATECREDIT
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%'
	},
	button: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		marginRight: 5
	},
}));


const EditCredit = (props) => {
   	const classes = useStyles();
   	const history = useHistory();
   	const [error, setError] = useState(null);
   	const [success, setSuccess] = useState(null);
   	const [creditsList, setCreditsList] = useState([]);
   	const [tenantsList, setTenantsList] = useState([]);
   	const [currentUser] = useState(authenticationService.currentUserValue);
   	const [credit] = useState(props.location.state && props.location.state.record);
   	const [selectedDate, handleDateChange] = useState(credit ? credit.selectedDate : new Date());

   	useEffect(() => {
    	async function fetchData() {
        	const result = await axios.get(
            	ADMIN_MANNERCREDITS, {}
         	);
         	setCreditsList(result.data);

         	const result2 = await axios.get(
            	ADMIN_TENANTSBYLOCATION,
            	{ params: { location: currentUser.locations } }
         	);
         	setTenantsList(result2.data);
      	}
      	console.log(credit)
      	if(currentUser) fetchData();
   	}, [setCreditsList, setTenantsList, currentUser]);

   	const goBack = () => {
     	history.push({
        	pathname: '/admin/view-credits'
      	})
   	}

   	const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
      	setSubmitting(true);
      	setError(null);
      	setSuccess(null);

      	
      	setSubmitting(false);
	    const  data = {
	    	creditid: credit.id,
	    	creditdate: selectedDate,
	        selectedDate: selectedDate,
	        tenant: values.tenant,
	        amount: values.amount,
	        mannerofcredit: values.mannerOfCredit,
	        notes: values.remarks,
	    }

        axios
            .post(  
                ADMIN_UPDATECREDIT,
                data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               (result.isSuccess) ? setSuccess(result.message) : setError(result.message)
               setSubmitting(false);         
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
                setError(result.message) 
            });
  	}

   	const renderSelectMannerOfCreditOptions = () => {
     	return creditsList.map((dt, i) => {
        	return (
            	<MenuItem key={i} value={dt.id}>
                	{dt.name}
               	</MenuItem>
            );
       	});
   	}

    const renderSelectTenantOptions = () => {
    	return tenantsList.map((dt, i) => {
         	return (
            	<MenuItem key={i} value={dt.id}>
               	{dt.lastname + ', ' + dt.firstname + ' ' + dt.middlename + ' - Unit ' + dt.roomnumber}
            	</MenuItem>
         	);
      	});
   	}

	return (
		<>
			<PageTitle>
				<Typography 
					component="h1" 
					variant="h1" 
					paragraph={false}
				>
					Payments
				</Typography>

				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
					</Link>
					<Link color="inherit" href="#">
						Payments
					</Link>
					<Link href="#" aria-current="page">
						Edit Payment
					</Link>
				</Breadcrumbs>
			</PageTitle>

         	{ error &&            
            	<Alert severity="error">{ error } </Alert>
         	}

				{ success &&
					<>
					<Alert severity="success">{ success } </Alert>
	            <Button 
	            	type="button"
	               variant="contained" 
	               color="secondary" 
	               className={classes.button}
	               onClick={ () => history.push({pathname: '/admin/view-credits'}) }
	            >
	               Close
	            </Button>
	            </>
				}

         	<Formik
            	initialValues={{ 
               		tenant: credit ? credit.tenant : '', 
               		amount: credit ? credit.amount : '',
               		mannerOfCredit: credit ? credit.mannerofcredit : '',
               		remarks: credit ? credit.notes : '',
            	}}
            	onSubmit={ handleSubmit }
            	validationSchema={Yup.object().shape({
               		tenant: Yup.string()
                  		.required('Required'),
               		amount: Yup.number()
                  		.required('Required'),
               		mannerOfCredit: Yup.string()
                  		.required('Required'),
               		remarks: Yup.string(),
            	})}
         	>
            	{(props) => {
               		const {
                  		values,
                  		touched,
                  		errors,
                  		isSubmitting,
                  		handleChange,
                  		handleBlur,
                  		handleSubmit
               		} = props;
               
               		return (
                  		<form onSubmit={handleSubmit} >
                     		<Paper className="card-box">
                        		<BoxTitle>Edit Payment</BoxTitle>
                        		
                        		<Box  m={4} className="card-content">
                        			<Grid container spacing={2} justify="center">

                           				<Grid item xs={12}>
                              				<MuiPickersUtilsProvider utils={DateFnsUtils}>
                                 				<DatePicker
				                                    fullWidth
				                                    label="Date of Payment"
				                                    inputVariant="outlined"
				                                    format="MM/dd/yyyy"
				                                    value={selectedDate}
				                                    onChange={handleDateChange}
				                                    animateYearScrolling
				                                    disabled={ credit.mannerofcredit === 6 ? true : false}
				                                />
                              				</MuiPickersUtilsProvider>
                           				</Grid>

                           				<Grid item xs={12}>
                              				<FormControl variant="outlined" className={classes.formControl}>
                                 				<InputLabel id="tenant-name-label">Tenant Name</InputLabel>
				                                <Select
				                                	label="Tenant Name"
				                                    labelId="tenant-name-label"
				                                    id="tenant"
				                                    name="tenant"
				                                    value={values.tenant}
				                                    onChange={handleChange}
				                                    onBlur={handleBlur}
				                                    error={errors.tenant && touched.tenant}
				                                    disabled={ credit.mannerofcredit === 6 ? true : false}
				                                >
                                    				{renderSelectTenantOptions()}
                                 				</Select>
                                 				<FormHelperText>{(errors.tenant && touched.tenant) && errors.tenant}</FormHelperText>
                              				</FormControl>
                           				</Grid>

			                           	<Grid item xs={12}>
			                            	<TextField 
			                                 	fullWidth
			                                 	label="Amount"
			                                 	id="amount"
			                                 	variant="outlined"
			                                 	value={values.amount}
			                                 	onChange={handleChange}
			                                 	onBlur={handleBlur}
			                                 	helperText={(errors.amount && touched.amount) && errors.amount}
			                                 	error={errors.amount && touched.amount}
			                                 	disabled={ credit.mannerofcredit === 6 ? true : false}
			                            	/>
			                           	</Grid>

			                           	<Grid item xs={12}>
			                            	<FormControl variant="outlined" className={classes.formControl}>
			                                	<InputLabel id="manner-of-credit-label">Manner of Payment</InputLabel>
			                                 	<Select
			                                    	label="Manner of Credit"
			                                    	labelId="manner-of-credit-label"
			                                    	id="mannerOfCredit"
				                                    name="mannerOfCredit"
				                                    value={values.mannerOfCredit}
				                                    onChange={handleChange}
				                                    onBlur={handleBlur}
				                                    error={errors.mannerOfCredit && touched.mannerOfCredit}
				                                    disabled={ credit.mannerofcredit === 6 ? true : false}
			                                 	>
			                                    	{renderSelectMannerOfCreditOptions()}
			                                 	</Select>
			                                 	<FormHelperText>{(errors.mannerOfCredit && touched.mannerOfCredit) && errors.mannerOfCredit}</FormHelperText>
			                              	</FormControl>
			                           	</Grid>

			                           	<Grid item xs={12}>
			                            	<TextField 
				                                fullWidth
				                                label="Remarks"
				                                id="remarks"
				                                variant="outlined"
				                                value={values.remarks}
				                                onChange={handleChange}
				                                onBlur={handleBlur}
				                                helperText={(errors.remarks && touched.remarks) && errors.remarks}
				                                error={errors.remarks && touched.remarks}
				                                disabled={ credit.mannerofcredit === 6 ? true : false}
			                              	/>
			                           	</Grid>
			                        </Grid>

                  					<Grid item xs={12} container justify="flex-start">
                     					<Button
                        					type="button"
                        					variant="contained" 
                        					color="default" 
                        					className={classes.button}
                        					onClick={ () => goBack() }
                     					>
                        					Back
                     					</Button>
										<Button
											type="submit"
											disabled={isSubmitting}
											variant="contained"
											color="secondary"
											className={classes.button}
											disabled={ credit.mannerofcredit === 6 ? true : false}
										>
										 Update
										</Button>
                  					</Grid>
                       	 		</Box>
                     		</Paper>
                  		</form>
               		);
            	}}
        	</Formik>

		</>
	)
}

export default EditCredit;