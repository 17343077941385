// endpoint url
// export const API_URL = 'http://localhost:30000/api/'
export const API_URL = 'https://dorms.ph:30000/api/'

export const ADMIN_ADMINBYID		= API_URL + 'admins/adminById'
export const ADMIN_ADMINSBYSTATUS	= API_URL + 'admins/adminsByStatus'
export const ADMIN_ADMINSUNVERIFIED	= API_URL + 'admins/adminsUnverified'
export const ADMIN_ADMINSDELETE		= API_URL + 'admins/removeUnverifiedAdmins'
export const ADMIN_ADMINFINANCIAL 	= API_URL + 'admins/adminFinancialStatus'
export const ADMIN_ALLROOMS			= API_URL + 'admins/allRooms'
export const ADMIN_ALLROOMSBYLOCATION	= API_URL + 'admins/allRoomsByLocation'
export const ADMIN_ANNOUNCEMENTS	= API_URL + 'admins/announcements'
export const ADMIN_BALANCESBYLOCATION	= API_URL + 'admins/balancesByLocation'
export const ADMIN_BOOKINGBYID 		= API_URL + 'admins/bookingById'
export const ADMIN_BOOKINGSBYLOCATION 	= API_URL + 'admins/bookingsByLocation'
export const ADMIN_CHANGEPASSWORD 	= API_URL + 'admins/changePassword'
export const ADMIN_CREDITSBYID 		= API_URL + 'admins/creditsById'
export const ADMIN_CREDITSBYLOCATION 	= API_URL + 'admins/creditsByLocation'
export const ADMIN_DEBITSBYID 		= API_URL + 'admins/debitsById'
export const ADMIN_DEBITSBYLOCATION 	= API_URL + 'admins/debitsByLocation'
export const ADMIN_DORMS			= API_URL + 'admins/dorms'
export const ADMIN_FACILITIES 		= API_URL + 'admins/facilities'
export const ADMIN_FACILITIESBYLOCATION	= API_URL + 'admins/facilitiesByLocation'
export const ADMIN_FLOORSBYLOCATION	= API_URL + 'admins/floorsByLocation'
export const ADMIN_FORGOTPASSWORD 	= API_URL + 'admins/forgotPassword'
export const ADMIN_LOCATIONBYID		= API_URL + 'admins/locationById'
export const ADMIN_MANNERCREDITS	= API_URL + 'admins/mannercredits'
export const ADMIN_NATUREDEBITS 	= API_URL + 'admins/naturedebits'
export const ADMIN_NATUREDEBITSBYLOCATION	= API_URL + 'admins/naturedebitsByLocation'
export const ADMIN_PROFILEDETAILS	= API_URL + 'admins/profileDetails'
export const ADMIN_PROFILESBYLOCATION	=	API_URL + 'admins/profilesByLocation'
export const ADMIN_PROFILESUNVERIFIEDBYLOCATION	=	API_URL + 'admins/profilesUnverifiedByLocation'
export const ADMIN_RESETUSERPASSWORD	= API_URL + 'admins/resetUserPassword'
export const ADMIN_ROOMSBYLOCATION	= API_URL + 'admins/roomsByLocation'
export const ADMIN_ROOMSBYLOCATIONTENANT	= API_URL + 'admins/roomsByLocationTenant'
export const ADMIN_SAVEADMINUSER	= API_URL + 'admins/saveAdminUser'
export const ADMIN_SAVEBOOKING		= API_URL + 'admins/saveBooking'
export const ADMIN_SAVECREDIT		= API_URL + 'admins/saveCredit'
export const ADMIN_SAVEDEBIT		= API_URL + 'admins/saveDebit'
export const ADMIN_SAVEDORM			= API_URL + 'admins/saveDorm'
export const ADMIN_SAVEFACILITY		= API_URL + 'admins/saveFacility'
export const ADMIN_SAVENATUREDEBIT	= API_URL + 'admins/saveNatureDebit'
export const ADMIN_SAVETENANT		= API_URL + 'admins/saveTenantAccount'
export const ADMIN_SIGNIN 			= API_URL + 'admins/signin'
export const ADMIN_SUPERFINANCIAL 	= API_URL + 'admins/superFinancialStatus'
export const ADMIN_TENANTSBYLOCATION	= API_URL + 'admins/tenantsByLocation'
export const ADMIN_TENANTSDELETE	= API_URL + 'admins/removeUnverifiedTenants'
export const ADMIN_TOP5BOOKINGS		= API_URL + 'admins/top5BookingsByLocation'
export const ADMIN_UPDATEANNOUNCEMENT	= API_URL + 'admins/updateAnnouncement'
export const ADMIN_UPDATETENANT		= API_URL + 'admins/updateTenantAccount'
export const ADMIN_UPDATEBOOKING		= API_URL + 'admins/updateBooking'
export const ADMIN_UPDATECREDIT		= API_URL + 'admins/updateCredit'
export const ADMIN_UPDATEDEBIT		= API_URL + 'admins/updateDebit'
export const ADMIN_UPDATEDORM		= API_URL + 'admins/updateDorm'
export const ADMIN_UPDATEADMINACCOUNT	= API_URL + 'admins/updateAdminAccount'
export const ADMIN_VERIFYACCOUNT 	= API_URL + 'admins/verifyAccount'


export const TENANT_ARCHIVE			= API_URL + 'tenants/getArchivedTenants'
export const TENANT_BOOKINGS 		= API_URL + 'tenants/bookingsByTenantId'
export const TENANT_CHANGEPASSWORD 	= API_URL + 'tenants/changePassword'
export const TENANT_CREDITS 		= API_URL + 'tenants/creditsByTenantId'
export const TENANT_DEBITS 			= API_URL + 'tenants/debitsByTenantId'
export const TENANT_FINANCIALSTATUS	= API_URL + 'tenants/financialStatus'
export const TENANT_FORGOTPASSWORD 	= API_URL + 'tenants/forgotPassword'
export const TENANT_RESETUSERPASSWORD	= API_URL + 'tenants/resetUserPassword'
export const TENANT_SIGNIN 			= API_URL + 'tenants/signin'
export const TENANT_TOP3DEBITS		= API_URL + 'tenants/top3DebitsByTenantId'
export const TENANT_TOP5BOOKINGS	= API_URL + 'tenants/top5BookingsByTenantId'
export const TENANT_TOP5ARCHIVE		= API_URL + 'tenants/getTop5ArchivedTenants'
export const TENANT_VERIFYACCOUNT	= API_URL + 'tenants/verifyAccount'
