import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Breadcrumbs,
   Button,
   Grid,
   Link,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
    ADMIN_SAVEADMINUSER
} from './../../../../_helpers';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const VerifyCreateAdminUser = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [admin] = useState(props.location.state && props.location.state.record);

   const goBack = () => {
      history.push({
         pathname: '/superadmin/create-admin-user',
         state: { record: admin }
      })
   }

   const creteAdmin = () => {
      axios
          .post( 
              ADMIN_SAVEADMINUSER,
              admin,
              {
                  headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Content-Type': 'application/json',
                  }
              },
          )
          .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }
               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               history.push({
                  pathname: '/superadmin/verified',
                  state: { record: admin, result: result }
               })
          })
          .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: (error.response) ? error.response.data.msg :  error.message
               }
               history.push({
                  pathname: '/superadmin/verified',
                  state: { record: admin, result: result }
               })
          });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Admin Users
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link href="#" aria-current="page">
                  Create Admin User
               </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Verify Admin User</BoxTitle>

            <Box m={4} className="card-content">
               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={admin.email}
                           secondary="Email" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={admin.firstname}
                           secondary="First Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={admin.lastname} 
                           secondary="Last Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={ (admin.privilege === 1) ? 'Super Admin' : 'Admin'} 
                           secondary="Privilege" />
                     </ListItem>
                     {admin.property &&
                     <ListItem>
                        <ListItemText 
                           primary={admin.property} 
                           secondary="Dorm" />
                     </ListItem>
                     }
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => creteAdmin() }
                     >
                        Create User
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default VerifyCreateAdminUser;