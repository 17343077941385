import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import { 
    Alert 
} from '@material-ui/lab';

import { 
  makeStyles 
} from '@material-ui/core/styles';

import { 
  BoxTitle,
  PageTitle
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  expansionPanelHeading: {
    marginBottom: theme.spacing(1)
  }
}));


const CreateDorm = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState(null);
   const [dorm] = useState(props.location.state && props.location.state.record);

   const handleSubmit = (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
        setSubmitting(true);
        setError(null);
        setSuccess(null);

        console.log(values)
         history.push({
            pathname: '/superadmin/verify-create-dorm',
            state: { record: values }
         })
   }


   const onChangeFloors = (e, values, setValues) => {
      e.stopPropagation()
      const rooms = [];
      const numberOfFloors = e.target.value || 0;

      for (let i = 0; i < numberOfFloors; i++) {
        rooms.push({ 
          floornum: ( i+1 ),
          numroom: 0,
          names: []
        });
      }
      setValues({ ...values, rooms });
   }

   const n = (n) => {
      return n > 9 ? "" + n: "0" + n;
   }

   const onChangeRooms = (e, flooridx, values, setValues) => {
      e.stopPropagation()
      const names = [];
      let rooms = [];
      let roomnames = [];
      const numberOfRooms = e.target.value || 0;

      for (let i = 0; i < numberOfRooms; i++) {
        names.push({
          roomname: (flooridx+1) + n(i+1)
        })
      }

      rooms = values.rooms;
      roomnames = rooms[flooridx].names
      roomnames = []
      roomnames.push(names)

      rooms[flooridx].names = roomnames

      setValues({ ...values, names });
   }

  return (
    <>
      <PageTitle>
        <Typography 
          component="h1" 
          variant="h1" 
          paragraph={false}
        >
          Dorms
        </Typography>

        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/dashboard">
            <HomeIcon fontSize="small" />
          </Link>
          <Link href="#" aria-current="page">
            Create Dorm
          </Link>
        </Breadcrumbs>
      </PageTitle>

            { error &&            
                <Alert severity="error">{ error } </Alert>
            }

            { success &&
                <Alert severity="success">{ success } </Alert>
            }

            <Formik
                initialValues={{ 
                     name: dorm ? dorm.name : '',
                     address: dorm ? dorm.address : '',
                     status: dorm ? dorm.status : '',
                     numfloors: dorm ? dorm.numfloors : 0,
                     rooms: dorm ? dorm.rooms : []
                }}
                onSubmit={ handleSubmit }
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .required('Required'),
                  address: Yup.string()
                    .required('Required'),
                  status: Yup.string()
                    .required('Required'),
                  numfloors: Yup.number()
                    .required('Required')
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setValues
                  } = props;
                  return (

            <form onSubmit={handleSubmit} >
              <Paper className="card-box">
                <BoxTitle>Create Dorm</BoxTitle>

                <Box m={4} className="card-content">
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                      <TextField 
                        fullWidth 
                        label="Property Name"
                        id="name"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.name && touched.name) && errors.name}
                        error={errors.name && touched.name}  
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField 
                        fullWidth 
                        label="Address"
                        id="address"
                        variant="outlined"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.address && touched.address) && errors.address}
                        error={errors.address && touched.address} 
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                          label="Status"
                          labelId="status-label"
                          id="status"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.status && touched.status}
                        >
                          {[
                            { label: 'Active', value: 1 }, 
                            { label: 'Inactive', value: 0 }
                          ].map((item) => (
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{(errors.status && touched.status) && errors.status}</FormHelperText>
                      </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                      <TextField 
                        fullWidth 
                        label="Number of Floors"
                        id="numfloors"
                        variant="outlined"
                        value={values.numfloors}
                        onChange={ handleChange }
                        onBlur={ (e) => onChangeFloors(e, values, setValues) }
                        helperText={(errors.numfloors && touched.numfloors) && errors.numfloors}
                        error={errors.numfloors && touched.numfloors}  
                      />
                    </Grid>

                    { values.numfloors > 0 &&
                      <Grid item xs={12}>
                        { values.rooms.map( (room, i) => {
                          return (
                            <Accordion key={"rooms-" + i} defaultExpanded={false}>

                              <AccordionSummary  expandIcon={<ExpandMore />}>
                                <Grid container>
                                  <Grid item xs={1}>
                                    <Typography>Floor {room.floornum}</Typography>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField 
                                      fullWidth 
                                      label="Number of Rooms"
                                      id={"rooms[" + i + "].numroom"}
                                      variant="outlined"
                                      value={room.numroom}
                                      onChange={handleChange}
                                      onBlur={ (e) => onChangeRooms(e, i, values, setValues) }
                                      helperText={(errors.numroom && touched.numroom) && errors.numroom}
                                      error={errors.numroom && touched.numroom} 
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionSummary>

                              <AccordionDetails>
                                {room.names.length === 0 &&
                                  <Typography>Please enter number of rooms</Typography>
                                }
                                { room.names && room.names.map( (name, y) => {
                                  let elements=[];
                                  for(let x=0;x<name.length;x++){
                                    elements.push(
                                      <>
                                      <TextField
                                        key={"roomname-" + x}
                                        fullWidth 
                                        id={"rooms[" + i + "].names[0].[" + x + "].roomname"}
                                        variant="outlined"
                                        value={name[x].roomname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={(errors.roomname && touched.roomname) && errors.roomname}
                                        error={errors.roomname && touched.roomname} 
                                      />
                                      </>
                                    );
                                  }
                                  return(
                                    <span key={y}>{elements}</span>
                                  )
                                })}
                              </AccordionDetails>

                            </Accordion>
                          )
                        })}
                      </Grid>
                    }

                  </Grid>

                  <Button 
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained" 
                    color="secondary" 
                    className={classes.button}
                  >
                    Next
                  </Button>
                </Box>
              </Paper>
            </form>

          );
        }}
      </Formik>
    </>
  )
}


export default CreateDorm;