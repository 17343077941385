import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Button,
   Grid,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
  authenticationService
} from './../../../../_services';

import { 
    ADMIN_SAVECREDIT
} from './../../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const VerifyAddCredit = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [data] = useState(props.location.state && props.location.state.record);
   const [currentTenant] = useState(authenticationService.currentTenantValue);

   const goBack = () => {
      history.push({
         pathname: '/tenant/add-credits',
         state: { record: data }
      })
   }

   const addCredit = () => {

        const credit = {
            creditdate: new Date(),
            tenant: currentTenant.id,
            amount: data.amount,
            txnid: null,
            refno: null,
            mannerofcredit: 6,
            location: currentTenant.location,
            notes: null,
            createdBy: null
        }

        axios
            .post(  
                ADMIN_SAVECREDIT,
                credit,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               history.push({
                  pathname: '/tenant/verified-add-credits',
                  state: { record: data, result: result }
               })
            
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
               history.push({
                  pathname: '/tenant/verified-add-credits',
                  state: { record: data, result: result }
               })
            });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Payments
            </Typography>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Verify Reload Wallet</BoxTitle>

            <Box m={4} className="card-content">
               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={new Intl.NumberFormat("en-US", {
                            style: "currency",
                             currency: "PHP",
                             minimumFractionDigits: 2,
                             maximumFractionDigits: 2
                            }).format(data.amount)}
                           secondary="Amount" />
                     </ListItem>
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => addCredit() }
                     >
                        Reload Wallet
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default VerifyAddCredit;