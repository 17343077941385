import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom';

import {
	Box,
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography,
  	Switch,
  	FormControlLabel,
  	Grid
} from '@material-ui/core';

import { 
  	BoxTitle,
  	PageTitle 
} from './../../../_components';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';


import { 
    ADMIN_PROFILESBYLOCATION
} from './../../../_helpers';

const Profiles = props => {
	const history = useHistory();
  	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [checked, setChecked] = useState(true);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios.get(
		  		ADMIN_PROFILESBYLOCATION,
		  		{ params: { location: currentUser.locations, status: checked } }
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);

  	const handleChange = async () => {
	   	let prev = checked
	   	setChecked(!prev);
	   	const result = await axios.get(
		  	ADMIN_PROFILESBYLOCATION,
		  	{ params: { location: currentUser.locations, status: !prev } }
		);
		const d = handleData(result.data)
		setData(d);
  	};


	const formatNumber = (decimalsFormated) => {
	    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	 }

  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
	 		const cash = parseFloat(key.credits) 
	 		const revenues = parseFloat(key.debits)

	 		const credits = parseFloat(key.totcredits)
	 		const debits = parseFloat(key.totdebits)
	 		const regular = (credits-key.security)


	 		const paymentsapplied = Math.min(debits, regular)
	 		// const overunder = cash - revenues
	 		const overunder = regular - debits
	 		const receivables = debits - paymentsapplied


		  	const item = {
			 	id: idx,
			 	tenantid: key.id,
			 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
			 	unit: key.roomnumber,
			 	credits: credits ? formatNumber(credits.toFixed(2)) : 0,
			 	debits: debits ? formatNumber(debits.toFixed(2)) : 0,
			 	overundercredit: overunder ? formatNumber(overunder.toFixed(2)) : 0,
			 	receivables: receivables ? formatNumber(receivables.toFixed(2)) : 0,
		  	}
		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const columns = [
	  	{
			name: "tenantid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "tenant",
		 	label: "Tenant",
		 	options: {
		  		display: true,
		  		filter: true,
		  		sort: false,
			}
	  	},
	  	{
			name: "unit",
		 	label: "Unit",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "credits",
		 	label: "Total Payments (Special + Regular Payments)",
		 	options: {
		  		filter: false,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "debits",
		 	label: "Bills",
		 	options: {
		  		filter: false,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "overundercredit",
		 	label: "Over/Under Payment",
		 	options: {
		  		filter: false,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "receivables",
		 	label: "Unpaid Balance",
		 	options: {
		  		filter: false,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
		downloadOptions: {
			filename: 'profiles.csv',
			separator: ',',
			filterOptions: {
			    useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true
			}
		},
  		print: false,
  		rowHover: true,
  		onDownload: (buildHead, buildBody, columns, data) => {
  			let rData = data
  			data.forEach( (value, key) => {
  				let num = 0
  				//check string 
  				let snum = isNaN(value.data[4])
  				//check if comma
  				if(snum) {
  					let comma = value.data[4].includes(',')
  					num = (comma) ? Number(value.data[4].replace(/,/g, '')) : Number(value.data[4])
  			
  				}

  				rData[key].data[4] = num
  			})
  			console.log(rData)
    		return "\uFEFF" + buildHead(columns) + buildBody(data); 
		},
  		setRowProps: row => {
			return {
            	style: { cursor: "pointer" }
          	};
  		},
  		onRowClick: (rowData) => {
  			history.push({
	        	pathname: '/admin/edit-account',
	        	state: { record: rowData }
	    	})  
  		}
	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Profiles
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Reports
			 		</Link>
			 		<Link href="#" aria-current="page">
						Profiles
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
			   <FormControlLabel
	        		control={ <Switch checked={checked} onChange={ handleChange } /> }
	        		label={ checked ? "Active" : "Archived" }
	      		/>
	      	</Grid>

			<Box component="div">
		  		<Paper className="card-box">
			 		<BoxTitle>Tenants</BoxTitle>
					{data &&
						<MUIDataTable
							data={data}
							columns={columns}
							options={options}
						/>
					}
		  		</Paper>
			</Box>
	 	</>
  	)
}


export default Profiles;