import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
  AppBar,
  Button,
  IconButton,
  Toolbar
} from '@material-ui/core';

import { 
  createMuiTheme,
  makeStyles
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { 
  Menu as MenuIcon,
  ExitToApp as LogoutIcon
} from '@material-ui/icons';


const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.tertiary.dark,
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoutButton: {
    textTransform: 'capitalize',
    marginLeft: theme.spacing(2)
  },
  toolbar: {
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
  }
}));

const Topbar = props => {
  const { onSidebarOpen } = props;

  const classes = useStyles();
  const history = useHistory();

  // Theme Styles
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
    typography: {
      body1: {
        fontSize: 'inherit',
        letterSpacing: 'inherit',
        lineHeight: 'inherit'
      }
    }
  });

  const handleLogout = () => {
      localStorage.removeItem('tenant')
      localStorage.clear()
      history.replace({
        pathname: '/tenant/sign-in'
      })
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onSidebarOpen}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>

          <div>
            <Button
              onClick={ () => handleLogout() }
              className={classes.logoutButton}
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;