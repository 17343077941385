import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { 
   Alert 
} from '@material-ui/lab';

import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';

import { 
  makeStyles 
} from '@material-ui/core/styles';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';


// Style
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
}));


const AddCredits = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState(null);
   const [data] = useState(props.location.state && props.location.state.record);

   const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
      setSubmitting(true);
      setError(null);
      setSuccess(null);

      history.push({
         pathname: '/tenant/verify-add-credits',
         state: { record: values }
      })
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Payments
            </Typography>
         </PageTitle>

         { error &&            
            <Alert severity="error">{ error } </Alert>
         }

         { success &&
            <Alert severity="success">{ success } </Alert>
         }

         <Formik
            initialValues={{ 
               amount: data ? data.amount : '',
            }}
            onSubmit={ handleSubmit }
            validationSchema={Yup.object().shape({
               amount: Yup
                  .string()
                  .matches(/^[0-9]+$/, "Must be numbers")
                  .min(4, 'Must be exactly 5 digits')
                  .required('Required')
            })}
         >
            {(props) => {
               const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
               } = props;
               
               return (
                  <form onSubmit={handleSubmit} >
                     <Paper className="card-box">
                        <BoxTitle>Reload Wallet</BoxTitle>

                        <Box m={4} className="card-content">
                           <Grid container spacing={2} justify="center">
                              <Grid item xs={12}>
                                 <TextField 
                                    fullWidth
                                    label="Amount"
                                    id="amount"
                                    variant="outlined"
                                    value={values.amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText="* Please note that the minimum amount of credit infusion is P1,000"
                                    error={errors.amount && touched.amount}
                                 />
                              </Grid>
                           </Grid>

                           <Button
                              type="submit"
                              disabled={isSubmitting}
                              variant="contained"
                              color="secondary"
                              className={classes.button}
                           >
                              Next
                           </Button>
                        </Box>
                     </Paper>
                  </form>
               );
            }}
         </Formik>
      </>
  )
}


export default AddCredits;