import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
 	Box,
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography
} from '@material-ui/core';

import {
  PageTitle
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
  authenticationService
} from './../../../_services';

import { 
    ADMIN_FACILITIES
} from './../../../_helpers';


const ViewFacilities = props => {
 	const [data, setData] = useState([]);
  	const [currentUser] = useState(authenticationService.currentUserValue);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios(
		  		ADMIN_FACILITIES,
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser)  fetchData();
  	}, [setData, currentUser]);


  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
		  	const item = {
			 	id: idx,
			 	dorm: key.property,
			 	facility: key.name,
			 	status: (key.status === 1) ? 'Active' : 'Inactive',
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

 	const columns = [
	  	{
			name: "id",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "dorm",
		 	label: "Dorm",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "facility",
		 	label: "Facility",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "status",
		 	label: "Status",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
			downloadOptions: {
			  filename: 'facilities.csv',
			  separator: ',',
			  filterOptions: {
			    useDisplayedColumnsOnly: true,
			    useDisplayedRowsOnly: true
				}
			},
  		print: false,
  		onRowClick: rowData => console.log(rowData)
	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Facilities
		  		</Typography>

			  	<Breadcrumbs aria-label="breadcrumb">
				 	<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
				 	</Link>
				 	<Link href="#" aria-current="page">
						View Facilities
				 	</Link>
			  	</Breadcrumbs>
			</PageTitle>

			<Box component="div">
			  <Paper className="card-box">
		  			{data &&
						<MUIDataTable
							title={"Facilities"}
							data={data}
							columns={columns}
							options={options}
						/>
					}
			  </Paper>
			</Box>
	 	</>
  	)
}


export default ViewFacilities;
