import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles } from '@material-ui/core/styles';
import { Topbar, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    }
  },
}));

function MainAdmin(props) {
  const { children } = props;

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Topbar onSidebarOpen={ handleDrawerToggle } />
      <Sidebar onSidebarOpen={ handleDrawerToggle } mobileOpen={ mobileOpen } />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

MainAdmin.propTypes = {
  /**
  * Injected by the documentation to work in an iframe.
  * You won't need it on your project.
  */
  container: PropTypes.any,
};

export default MainAdmin;