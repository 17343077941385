import React from 'react';
import { 
    BrowserRouter,
    Redirect,
    Switch 
} from 'react-router-dom';

import { 
    RouteWithLayout, 
} from '_components';
import { 
    Minimal as MinimalLayout,
    Main as MainLayout,
    MainAdmin as MainAdminLayout,
    MainTenant as MainTenantLayout,
} from 'layouts';
import {
    AdminAnnouncement as AdminAnnouncementView,
    Announcement as AnnouncementView,
    AddCredits as AddCreditsView,
    AdminDashboard as AdminDashboardView,
    ChangePassword as ChangePasswordView,
    CreateAccount as CreateAccountView,
    CreateAdminUser as CreateAdminUserView,
    CreateBooking as CreateBookingView,
    CreateCredit as CreateCreditView,
    CreateDebit as CreateDebitView,
    CreateDorm as CreateDormView,
    CreateFacility as CreateFacilityView,
    CreateNatureOfDebit as CreateNatureOfDebitView,
    EditAdminUser as EditAdminUserView,
    EditAccount as EditAccountView,
    EditBooking as EditBookingView,
    EditCredit as EditCreditView,
    EditDebit as EditDebitView,
    EditDorm as EditDormView,
    ForgotPassword as ForgotPasswordView,
    PasswordReset as PasswordResetView,
    Profiles as ProfilesView,
    ProfilesUnverified as ProfilesUnverifiedView,
    SignIn as SignInView,
    Success as SuccessView,
    SuperDashboard as SuperDasboardView,
    TenantDashboard as TenantDashboardView,
    TenantViewBookings as TenantViewBookingsView,
    TenantViewCredits as TenantViewCreditsView,
    TenantViewDebits as TenantViewDebitsView,
    Verified as VerifiedView,
    VerifiedAddCredit as VerifiedAddCreditView,
    VerifiedCreateAccount as VerifiedCreateAccountView,
    VerifiedCreateBooking as VerifiedCreateBookingView,
    VerifiedCreateCredit as VerifiedCreateCreditView,
    VerifiedCreateDebit as VerifiedCreateDebitView,
    VerifiedCreateDorm as VerifiedCreateDormView,
    VerifiedCreateFacility as VerifiedCreateFacilityView,
    VerifiedCreateNatureOfDebit as VerifiedCreateNatureOfDebitView,
    VerifyAccount as VerifyAccountView,
    VerifyAddCredit as VerifyAddCreditView,
    VerifyCreateAccount as VerifyCreateAccountView,
    VerifyCreateAdminUser as VerifyCreateAdminUserView,
    VerifyCreateBooking as VerifyCreateBookingView,
    VerifyCreateCredit as VerifyCreateCreditView,
    VerifyCreateDebit as VerifyCreateDebitView,
    VerifyCreateDorm as VerifyCreateDormView,
    VerifyCreateFacility as VerifyCreateFacilityView,
    VerifyCreateNatureOfDebit as VerifyCreateNatureOfDebitView,
    ViewAdminUsers as ViewAdminUsersView,
    ViewAdminUsersUnverified as ViewAdminUsersUnverifiedView,
    ViewArchivedAccounts as ViewArchivedAccountsView,
    ViewBalances as ViewBalancesView,
    ViewBookings as ViewBookingsView,
    ViewCredits as ViewCreditsView,
    ViewDebits as ViewDebitsView,
    ViewDorms as ViewDormsView,
    ViewFacilities as ViewFacilitiesView,
    ViewNatureOfDebits as ViewNatureOfDebitsView,
    ViewProfile as ViewProfileView,
    ViewTenant as ViewTenantView
} from 'views';


const Routes = () => {
    return (
        <BrowserRouter  basename={'/app'}>
            <Switch>

                <Redirect
                    exact
                    from="/"
                    to="/sign-in"
                />

                <RouteWithLayout
                    component={SignInView}
                    exact
                    layout={MinimalLayout}
                    path="/sign-in"
                />

                <RouteWithLayout
                    component={SignInView}
                    exact
                    layout={MinimalLayout}
                    path="/tenant/sign-in"
                />

                <RouteWithLayout
                    component={ChangePasswordView}
                    exact
                    layout={MinimalLayout}
                    path="/change-password"
                />

                <RouteWithLayout
                    component={ChangePasswordView}
                    exact
                    layout={MinimalLayout}
                    path="/tenant/change-password"
                />

                <RouteWithLayout
                    component={ForgotPasswordView}
                    exact
                    layout={MinimalLayout}
                    path="/forgot-password"
                />

                <RouteWithLayout
                    component={ForgotPasswordView}
                    exact
                    layout={MinimalLayout}
                    path="/tenant/forgot-password"
                />

                <RouteWithLayout
                    component={PasswordResetView}
                    exact
                    layout={MinimalLayout}
                    path="/password-reset/:email"
                />

                <RouteWithLayout
                    component={PasswordResetView}
                    exact
                    layout={MinimalLayout}
                    path="/tenant/password-reset/:email"
                />

                <RouteWithLayout
                    component={SuccessView}
                    exact
                    layout={MinimalLayout}
                    path="/tenant/success"
                />

                <RouteWithLayout
                    component={SuccessView}
                    exact
                    layout={MinimalLayout}
                    path="/success"
                />

                <RouteWithLayout
                    component={VerifyAccountView}
                    exact
                    layout={MinimalLayout}
                    path="/verify-account/:email/:code"
                />

                <RouteWithLayout
                    component={VerifyAccountView}
                    exact
                    layout={MinimalLayout}
                    path="/tenant/verify-account/:email/:code"
                />
                
                <RouteWithLayout
                    component={AnnouncementView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/announcement"
                />

                <RouteWithLayout
                    component={SuperDasboardView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/dashboard"
                />

                <RouteWithLayout
                    component={CreateAdminUserView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/create-admin-user"
                />

                <RouteWithLayout
                    component={EditAdminUserView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/edit-admin-user"
                />

                <RouteWithLayout
                    component={ViewAdminUsersView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-admin-users"
                />

                <RouteWithLayout
                    component={ViewAdminUsersUnverifiedView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-admin-users-unverified"
                />

                <RouteWithLayout
                    component={ViewArchivedAccountsView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-archived-accounts"
                />

                <RouteWithLayout
                    component={CreateDormView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/create-dorm"
                />

                <RouteWithLayout
                    component={VerifiedView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verified"
                />

                <RouteWithLayout
                    component={VerifiedCreateDormView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verified-create-dorm"
                />

                <RouteWithLayout
                    component={EditDormView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/edit-dorm"
                />

                <RouteWithLayout
                    component={VerifiedCreateFacilityView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verified-create-facility"
                />

                <RouteWithLayout
                    component={VerifiedCreateNatureOfDebitView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verified-create-nature-of-debit"
                />

                <RouteWithLayout
                    component={VerifyCreateAdminUserView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verify-create-admin-user"
                />

                <RouteWithLayout
                    component={VerifyCreateDormView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verify-create-dorm"
                />

                <RouteWithLayout
                    component={VerifyCreateFacilityView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verify-create-facility"
                />

                <RouteWithLayout
                    component={VerifyCreateNatureOfDebitView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/verify-create-nature-of-debit"
                />

                <RouteWithLayout
                    component={ViewDormsView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-dorms"
                />

                <RouteWithLayout
                    component={CreateFacilityView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/create-facility"
                />

                <RouteWithLayout
                    component={ViewFacilitiesView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-facilities"
                />

                <RouteWithLayout
                    component={CreateNatureOfDebitView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/create-nature-of-debit"
                />

                <RouteWithLayout
                    component={ViewNatureOfDebitsView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-nature-of-debits"
                />

                <RouteWithLayout
                    component={ViewTenantView}
                    exact
                    layout={MainLayout}
                    path="/superadmin/view-tenant"
                />


                <RouteWithLayout
                    component={AdminAnnouncementView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/announcement"
                />

                <RouteWithLayout
                    component={AdminDashboardView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/dashboard"
                />

                <RouteWithLayout
                    component={CreateAccountView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/create-account"
                />

                <RouteWithLayout
                    component={CreateBookingView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/create-booking"
                />

                <RouteWithLayout
                    component={CreateDebitView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/create-debit"
                />

                <RouteWithLayout
                    component={CreateCreditView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/create-credit"
                />

                <RouteWithLayout
                    component={EditAccountView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/edit-account"
                />

                <RouteWithLayout
                    component={EditBookingView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/edit-booking"
                />

                <RouteWithLayout
                    component={EditCreditView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/edit-credit"
                />

                <RouteWithLayout
                    component={EditDebitView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/edit-debit"
                />

                <RouteWithLayout
                    component={ProfilesView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/profiles"
                />

                <RouteWithLayout
                    component={ProfilesUnverifiedView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/profiles-unverified"
                />

                <RouteWithLayout
                    component={VerifyCreateAccountView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verify-create-account"
                />

                <RouteWithLayout
                    component={VerifyCreateBookingView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verify-create-booking"
                />

                <RouteWithLayout
                    component={VerifyCreateCreditView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verify-create-credit"
                />

                <RouteWithLayout
                    component={VerifyCreateDebitView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verify-create-debit"
                />

                <RouteWithLayout
                    component={VerifiedCreateAccountView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verified-create-account"
                />

                <RouteWithLayout
                    component={VerifiedCreateBookingView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verified-create-booking"
                />

                <RouteWithLayout
                    component={VerifiedCreateCreditView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verified-create-credit"
                />

                <RouteWithLayout
                    component={VerifiedCreateDebitView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/verified-create-debit"
                />

                <RouteWithLayout
                    component={ViewBalancesView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/view-balances"
                />

                <RouteWithLayout
                    component={ViewBookingsView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/view-bookings"
                />

                <RouteWithLayout
                    component={ViewCreditsView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/view-credits"
                />

                <RouteWithLayout
                    component={ViewDebitsView}
                    exact
                    layout={MainAdminLayout}
                    path="/admin/view-debits"
                />



                <RouteWithLayout
                    component={TenantDashboardView}
                    exact
                    layout={MainTenantLayout}
                    path="/tenant/dashboard"
                />

                <RouteWithLayout
                    component={TenantViewBookingsView}
                    exact
                    layout={MainTenantLayout}
                    path="/tenant/view-bookings"
                />

                <RouteWithLayout
                    component={TenantViewCreditsView}
                    exact
                    layout={MainTenantLayout}
                    path="/tenant/view-credits"
                />

                <RouteWithLayout
                    component={TenantViewDebitsView}
                    exact
                    layout={MainTenantLayout}
                    path="/tenant/view-debits"
                />
                
                <RouteWithLayout
                    component={ViewProfileView}
                    exact
                    layout={MainTenantLayout}
                    path="/tenant/view-profile"
                />

            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
