import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Link,
  useHistory
} from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';

import { 
  makeStyles,
  useTheme
} from '@material-ui/core/styles';

import { 
  authenticationService
} from './../../../../_services';

import {
  KeyboardArrowDown as ChevronDownIcon,
  KeyboardArrowRight as ChevronRightIcon,
  DashboardTwoTone as DashboardTwoToneIcon,
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
  PaymentOutlined as PaymentOutlinedIcon,
  BookOutlined as BookOutlinedIcon
} from '@material-ui/icons';

import balaySoliven from "assets/dorms/balay-soliven.jpg"
import dormsLogo from "assets/logo/dorms-logo-white.png"

// Style
const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    border: 0,
    boxShadow: '0 5px 5px rgba(0, 0, 0, 0.15)'
  },
  logoContainer: {
    backgroundColor: theme.palette.tertiary.main,
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: 0,
    width: drawerWidth,
    padding: theme.spacing(0, 2),
    zIndex: 5
  },
  logo: {
    width: theme.spacing(6),
    marginRight: theme.spacing(2)
  },
  userDetails: {
    backgroundImage: 'url('+ balaySoliven +')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.white,
    padding: theme.spacing(2)
  },
  avatar: {
    color: theme.palette.tertiary.contrastText,
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: `0 auto ${theme.spacing(1)}px`
  },
  smallText: {
    fontSize: 13,
    color: theme.palette.white
  },
  name: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.white
  },
  profileButton: {
    marginTop: theme.spacing(2)
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  listSubheader: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(4)
  },
  arrowIcon: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1)
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));


const Sidebar = props => {
  const { container, onSidebarOpen, mobileOpen } = props;

  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState({
    debits: true,
    credits: true,
    bookings: true
  });
  const [currentTenant] = useState(authenticationService.currentTenantValue);
  
  if(!currentTenant) {
      authenticationService.logoutTenant()
      history.replace({
          pathname: '/tenant/sign-in'
      })
  }

  const drawer = (
    <div className="sidebar">
      <Box className={classes.toolbar} />
      <Box 
        className={clsx(classes.toolbar, classes.logoContainer)}
        display="flex"
        alignItems="center"
      >
        <img src={dormsLogo} className={classes.logo} alt="Logo" />
      </Box>

      <Box
        component="div" 
        className={classes.userDetails} 
        textAlign="center"
      > 
        <Avatar className={classes.avatar} alt="Avatar">NA</Avatar>

        <div>
          <Typography component="span" className={classes.smallText}>Welcome</Typography>
          <Typography className={classes.name}>
            {currentTenant && currentTenant.firstname + " " + currentTenant.lastname}
          </Typography>
        </div>

        <Button 
          variant="outlined" 
          size="small" 
          color="inherit"
          className={classes.profileButton}
          onClick={ () => { history.push('/tenant/view-profile') } }
        >
            View Profile
        </Button>
      </Box>

      <List component="div">
        <Link to="/tenant/dashboard" color="inherit" underline="none">
          <ListItem button>
            <ListItemIcon>
              <DashboardTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Link>

        <ListItem 
          button 
          onClick={() => setOpen({...open, debits: !open.debits})}
        >
          <ListItemIcon>
            <AccountBalanceWalletOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Bills" />
          {open.debits ? <ChevronDownIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
        </ListItem>
        <Collapse in={open.debits} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[{ label: 'View', link: 'view-debits' }
            ].map((item, index) => (
              <Link key={index} to={item.link} color="inherit" underline="none">
                <ListItem 
                  button 
                  className={classes.nested}
                  key={item.link}
                >
                  <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>

        <ListItem 
          button 
          onClick={() => setOpen({...open, credits: !open.credits})}
        >
          <ListItemIcon>
            <PaymentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" />
          {open.credits ? <ChevronDownIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
        </ListItem>
        <Collapse in={open.credits} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[{ label: 'View', link: 'view-credits' }
            ].map((item, index) => (
              <Link key={index} to={item.link} color="inherit" underline="none">
                <ListItem 
                  button 
                  className={classes.nested}
                  key={item.link}
                >
                  <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>

        <ListItem 
          button 
          onClick={() => setOpen({...open, bookings: !open.bookings})}
        >
          <ListItemIcon>
            <BookOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Bookings" />
          {open.bookings ? <ChevronDownIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
        </ListItem>
        <Collapse in={open.bookings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[{ label: 'View', link: 'view-bookings' }
            ].map((item, index) => (
              <Link key={index} to={item.link} color="inherit" underline="none">
                <ListItem 
                  button 
                  className={classes.nested}
                  key={item.link}
                >
                  <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="nav sidebar">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={onSidebarOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  )
};


Sidebar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  container: PropTypes.any,
  mobileOpen: PropTypes.bool
};

export default Sidebar;