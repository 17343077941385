import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { 
      Alert 
} from '@material-ui/lab';

import {
   Box,
   Breadcrumbs,
   Button,
   FormControl,
   Grid,
   InputLabel,
   Link,
   MenuItem,
   Paper,
   Select,
   TextField,
   Typography,
   FormHelperText
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import { 
  makeStyles 
} from '@material-ui/core/styles';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
  authenticationService
} from './../../../_services';

import { 
    ADMIN_MANNERCREDITS,
    ADMIN_TENANTSBYLOCATION
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
   },
}));


const CreateCredit = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState(null);
   const [creditsList, setCreditsList] = useState([]);
   const [tenantsList, setTenantsList] = useState([]);
   const [currentUser] = useState(authenticationService.currentUserValue);
   const [credit] = useState(props.location.state && props.location.state.record);
   const [selectedDate, handleDateChange] = useState(credit ? credit.selectedDate : new Date());

   useEffect(() => {
      async function fetchData() {
         const result = await axios.get(
            ADMIN_MANNERCREDITS, {}
         );
         setCreditsList(result.data);

         const result2 = await axios.get(
            ADMIN_TENANTSBYLOCATION,
            { params: { location: currentUser.locations } }
         );
         setTenantsList(result2.data);
      }
            
      if(currentUser) fetchData();
   }, [setCreditsList, setTenantsList, currentUser]);

   const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
      setSubmitting(true);
      setError(null);
      setSuccess(null);

      const  data = {
         creditdate: selectedDate,
         selectedDate: selectedDate,
         tenant: values.tenant,
         amount: values.amount,
         mannerOfCredit: values.mannerOfCredit,
         remarks: values.remarks,
         location: currentUser.locations,
         createdBy: currentUser.id
      }

      const t  = tenantsList.filter(tenants => tenants.id === values.tenant)
      data.tenantname = t[0].lastname + ', ' + t[0].firstname + ' ' + t[0].middlename + ' - Unit ' + t[0].roomnumber

      const c  = creditsList.filter(credits => credits.id === values.mannerOfCredit) 
      data.mannerofcreditname = c[0].name


      history.push({
         pathname: '/admin/verify-create-credit',
         state: { record: data }
      })
   }

   const renderSelectMannerOfCreditOptions = () => {
      return creditsList.map((dt, i) => {
         return (
               <MenuItem key={i} value={dt.id}>
                  {dt.name}
               </MenuItem>
            );
         });
   }

    const renderSelectTenantOptions = () => {
      return tenantsList.map((dt, i) => {
         return (
            <MenuItem key={i} value={dt.id}>
               {dt.lastname + ', ' + dt.firstname + ' ' + dt.middlename + ' - Unit ' + dt.roomnumber}
            </MenuItem>
         );
      });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Payments
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link color="inherit" href="#">
                  Tenants
               </Link>
               <Link href="#" aria-current="page">
                  Create Payment
               </Link>
            </Breadcrumbs>
         </PageTitle>

         { error &&            
            <Alert severity="error">{ error } </Alert>
         }

         { success &&
            <Alert severity="success">{ success } </Alert>
         }

         <Formik
            initialValues={{ 
               tenant: credit ? credit.tenant : '', 
               amount: credit ? credit.amount : '',
               mannerOfCredit: credit ? credit.mannerOfCredit : '',
               remarks: credit ? credit.remarks : '',
            }}
            onSubmit={ handleSubmit }
            validationSchema={Yup.object().shape({
               tenant: Yup.string()
                  .required('Required'),
               amount: Yup.number()
                  .required('Required'),
               mannerOfCredit: Yup.string()
                  .required('Required'),
               remarks: Yup.string(),
            })}
         >
            {(props) => {
               const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
               } = props;
               
               return (
                  <form onSubmit={handleSubmit} >
                     <Paper className="card-box">
                        <BoxTitle>Create Payment</BoxTitle>
                        <Box  m={4} className="card-content">
                        <Grid container spacing={2} justify="center">
                           <Grid item xs={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                 <DatePicker
                                    fullWidth
                                    label="Date of Payment"
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    animateYearScrolling
                                 />
                              </MuiPickersUtilsProvider>
                           </Grid>

                           <Grid item xs={12}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                 <InputLabel id="tenant-name-label">Tenant Name</InputLabel>
                                 <Select
                                    label="Tenant Name"
                                    labelId="tenant-name-label"
                                    id="tenant"
                                    name="tenant"
                                    value={values.tenant}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.tenant && touched.tenant}
                                 >
                                    {renderSelectTenantOptions()}
                                 </Select>
                                 <FormHelperText>{(errors.tenant && touched.tenant) && errors.tenant}</FormHelperText>
                              </FormControl>
                           </Grid>

                           <Grid item xs={12}>
                              <TextField 
                                 fullWidth
                                 label="Amount"
                                 id="amount"
                                 variant="outlined"
                                 value={values.amount}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 helperText={(errors.amount && touched.amount) && errors.amount}
                                 error={errors.amount && touched.amount}
                              />
                           </Grid>

                           <Grid item xs={12}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                 <InputLabel id="manner-of-credit-label">Manner of Payment</InputLabel>
                                 <Select
                                    label="Manner of Credit"
                                    labelId="manner-of-credit-label"
                                    id="mannerOfCredit"
                                    name="mannerOfCredit"
                                    value={values.mannerOfCredit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.mannerOfCredit && touched.mannerOfCredit}
                                 >
                                    {renderSelectMannerOfCreditOptions()}
                                 </Select>
                                 <FormHelperText>{(errors.mannerOfCredit && touched.mannerOfCredit) && errors.mannerOfCredit}</FormHelperText>
                              </FormControl>
                           </Grid>

                           <Grid item xs={12}>
                              <TextField 
                                 fullWidth
                                 label="Remarks"
                                 id="remarks"
                                 variant="outlined"
                                 value={values.remarks}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 helperText={(errors.remarks && touched.remarks) && errors.remarks}
                                 error={errors.remarks && touched.remarks}
                              />
                           </Grid>
                        </Grid>


                        <Button
                           type="submit"
                           disabled={isSubmitting}
                           variant="contained"
                           color="secondary"
                           className={classes.button}
                        >
                           Next
                        </Button>
                        </Box>
                     </Paper>
                  </form>
               );
            }}
         </Formik>
    </>
  )
}


export default CreateCredit;