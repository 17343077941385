
export const TRUE = 1
export const FALSE = 0

//privilege
export const ROLE_SUPERADMIN = 1
export const ROLE_ADMIN = 2
export const ROLE_DUMMY_ADMIN = 3

export const PRIVILEGE_SUPERADMIN_ID = 1
export const PRIVILEGE_SUPERADMIN = 'Super Admin'
export const PRIVILEGE_ADMIN_ID = 2
export const PRIVILEGE_ADMIN = 'Admin'