import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
	Box,
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import { 
  	BoxTitle,
  	PageTitle 
} from './../../../_components';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';


import { 
    ADMIN_PROFILESUNVERIFIEDBYLOCATION,
    ADMIN_TENANTSDELETE
} from './../../../_helpers';

const ProfilesUnverified = props => {
  	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
   	const [error, setError] = useState(null);
   	const [success, setSuccess] = useState(null);

	 async function fetchData() {
		const result = await axios.get(
		  	ADMIN_PROFILESUNVERIFIEDBYLOCATION,
		  	{ params: { location: currentUser.locations } }
		);
		const d = handleData(result.data)
		setData(d);
	 }

  	useEffect(() => {
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);


  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
	 		const credits = parseFloat(key.credits) - parseFloat(key.security)
	 		const overunder = credits - key.debits
	 		const receivables = key.debits - (Math.min(key.debits, credits))

		  	const item = {
			 	id: idx,
			 	tenantid: key.id,
			 	username: key.username,
			 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
			 	unit: key.roomnumber,
			 	credits: credits.toLocaleString(undefined, { maximumFractionDigits: 2 }),
			 	debits: key.debits.toLocaleString(undefined, { maximumFractionDigits: 2 }),
			 	overundercredit: overunder.toLocaleString(undefined, { maximumFractionDigits: 2 }),
			 	receivables: receivables.toLocaleString(undefined, { maximumFractionDigits: 2 }),
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const columns = [
	  	{
			name: "tenantid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "tenant",
		 	label: "Tenant",
		 	options: {
		  		display: true,
		  		filter: true,
		  		sort: false,
			}
	  	},
	  	{
			name: "username",
		 	label: "Username",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "unit",
		 	label: "Unit",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: false,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
		downloadOptions: {
			filename: 'unverifiedProfiles.csv',
			separator: ',',
			filterOptions: {
			    useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true
			}
		},
  		print: false,
		onRowsDelete: (rowsDeleted, dataRows) => {
			const usersToDelete = rowsDeleted.data.map(d => 
				data[d.dataIndex].tenantid
			);

			console.log(usersToDelete)
		    axios
		    	.post( 
		              ADMIN_TENANTSDELETE,
		              {tenantids: usersToDelete, location: currentUser.locations},
		              {
		                  headers: {
		                      'Access-Control-Allow-Origin': '*',
		                      'Content-Type': 'application/json',
		                  }
		              },
		        )
		        .then( (response)  => {
		        	console.log(response)
					const d = handleData(response.data)
					setData(d);
      				setSuccess('Tenant/s successfully deleted');
		        })
		        .catch( (error) => {
		          	setError(error);
		        });
		}
  		// onRowClick: rowData => console.log(rowData)
	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Profiles
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Reports
			 		</Link>
			 		<Link href="#" aria-current="page">
						Profiles
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

         { error && <Alert severity="error">{ error } </Alert> }
         { success && <Alert severity="success">{ success } </Alert> }

			<Box component="div">
		  		<Paper className="card-box">
			 		<BoxTitle>Unverified Tenants</BoxTitle>
					{data &&
						<MUIDataTable
							data={data}
							columns={columns}
							options={options}
						/>
					}
		  		</Paper>
			</Box>
	 	</>
  	)
}


export default ProfilesUnverified;