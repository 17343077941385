import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Breadcrumbs,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from '@material-ui/core';

import { 
    Alert 
} from '@material-ui/lab';

import { 
    makeStyles 
} from '@material-ui/core/styles';


import { 
    BoxTitle,
    PageTitle 
} from './../../../_components';

import { 
    Home as HomeIcon
} from '@material-ui/icons';

import { 
  authenticationService
} from './../../../_services';

import { 
    ADMIN_DORMS
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
}));


const CreateFacility = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [currentUser] = useState(authenticationService.currentUserValue);
    const [facility] = useState(props.location.state && props.location.state.record);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const result = await axios(
                ADMIN_DORMS,
            );
            console.log(result.data);
            setData(result.data);
        }
        if(currentUser) fetchData();
    }, [setData, currentUser]);


   const renderSelectOptions = () => {
        return data.map((dt, i) => {
            return (
                <MenuItem key={i} value={dt.id}>
                    {dt.property + ' (' + dt.address + ')'}
                </MenuItem>
            );
        });
    }

    const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
        setSubmitting(true);
        setError(null);
        setSuccess(null);
        console.log(values);

        const d  = data.filter(dorm => dorm.id === values.dorm)
        values.property = d[0].property + ' (' + d[0].address + ')'

        history.push({
            pathname: '/superadmin/verify-create-facility',
            state: { record: values }
        })
   }

    return (
        <>
            <PageTitle>
                <Typography 
                    component="h1" 
                    variant="h1" 
                    paragraph={false}
                >
                    Facilities
                </Typography>

                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/dashboard">
                        <HomeIcon fontSize="small" />
                    </Link>
                    <Link href="#" aria-current="page">
                        Create Facility
                    </Link>
                </Breadcrumbs>
            </PageTitle>

            { error &&            
                <Alert severity="error">{ error } </Alert>
            }

            { success &&
                <Alert severity="success">{ success } </Alert>
            }

            <Formik
                initialValues={{ 
                    dorm: facility ? facility.dorm : '', 
                    name: facility ? facility.name : '',
                    status: facility ? facility.status : ''
                }}
                onSubmit={ handleSubmit }
                validationSchema={Yup.object().shape({
                  dorm: Yup.string()
                    .required('Required'),
                  name: Yup.string()
                    .required('Required'),
                  status: Yup.string()
                    .required('Required'),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (

            <form onSubmit={handleSubmit} >
                <Paper className="card-box">
                    <BoxTitle>Create Facility</BoxTitle>
                    <Box m={4} className="card-content" >
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="dorm-label">Dorm</InputLabel>
                                    <Select
                                        label="Dorm"
                                        labelId="dorm-label"
                                        id="dorm"
                                        name="dorm"
                                        value={values.dorm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.dorm && touched.dorm}
                                    >
                                        {renderSelectOptions()}
                                    </Select>
                                    <FormHelperText>{(errors.dorm && touched.dorm) && errors.dorm}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField 
                                    fullWidth 
                                    label="Name"
                                    id="name"
                                    variant="outlined"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={(errors.name && touched.name) && errors.name}
                                    error={errors.name && touched.name}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        label="Status"
                                        labelId="status-label"
                                        id="status"
                                        name="status"
                                        value={values.status}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.status && touched.status}
                                    >
                                        {[
                                            { label: 'Active', value: 1 }, 
                                            { label: 'Inactive', value: 0 }
                                        ].map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{(errors.status && touched.status) && errors.status}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Button 
                            type="submit"
                            disabled={isSubmitting}
                            variant="contained" 
                            color="secondary" 
                            className={classes.button}
                        >
                            Next
                        </Button>
                    </Box>
                </Paper>
            </form>

                  );
                }}
              </Formik>
        </>
    )
}


export default CreateFacility;
