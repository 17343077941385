import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
	 authenticationService
} from './../../../_services';

import {
    TENANT_BOOKINGS
} from './../../../_helpers';

const ViewBookings = props => {
	 const [data, setData] = useState(null);
	 const [currentUser] = useState(authenticationService.currentTenantValue);

	 useEffect(() => {
		async function fetchData() {
		  const result = await axios.get(
			 TENANT_BOOKINGS,
			 { params: { tenant: currentUser.id } }
		  );
		  const d = handleData(result.data)
		  setData(d);
		}
		if(currentUser) fetchData();
	 }, [setData, currentUser]);

	 const columns = [
		{
		name: "bookingid",
		label: "ID",
		options: {
			 display: false,
			 filter: false,
			 sort: false,
		}
		},
		{
		name: "bookingdate",
		label: "Booking Date",
		options: {
			 display: true,
			 filter: false,
			 sort: false,
		}
		},
		{
		name: "amount",
		label: "Amount",
		options: {
			 filter: true,
			 sort: false,
		}
		},
		{
		name: "facility",
		label: "Facility",
		options: {
			 filter: true,
			 sort: false,
		}
		},
		{
		name: "notes",
		label: "Notes",
		options: {
			 filter: true,
			 sort: false,
		}
		},
		{
		name: "status",
		label: "Status",
		options: {
			 filter: true,
			 sort: false,
		}
		},
	 ];

  const options = {
	 filterType: 'multiselect',
		responsive: 'vertical',
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		viewColumns: false,
		download: false,
		print: false,
		onRowClick: rowData => console.log(rowData)
  };

	 const handleData = (result) => {
	 const record = [];
	 result.map( (key, idx) => {
		  const item = {
		  id: idx,
		  bookingid: key.id,
		  bookingdate: new Date(key.bookingdate).toLocaleDateString(),
		  tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
		  unit: key.roomnumber,
		  amount: new Intl.NumberFormat("en-US", {
										style: "currency",
										currency: "PHP",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2
							 }).format(key.amount),
		  facility: key.facilityname,
		  notes: key.notes,
		  status: (key.status === 1) ? 'Active' : 'Inactive',
		  }

		  record.push(item)
		  return console.log('');
	 })

	 return record;
	 }

	 return (
	 <>
		<PageTitle>
			 <Typography 
			 component="h1" 
			 variant="h1" 
			 paragraph={false}
			 >
			 Bookings
			 </Typography>

			 <Breadcrumbs aria-label="breadcrumb">
			 <Link color="inherit" href="/dashboard">
				<HomeIcon fontSize="small" />
			 </Link>
			 <Link color="inherit" href="#">
				Reports
			 </Link>
			 <Link href="#" aria-current="page">
				Bookings
			 </Link>
			 </Breadcrumbs>
		</PageTitle>

		<Paper className="card-box">
			 <BoxTitle>Bookings</BoxTitle>
		  {data &&
			 <MUIDataTable
				data={data}
				columns={columns}
				options={options}
			 />
		  }
		</Paper>
	 </>
  )
}

export default ViewBookings;