import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom';

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  	Switch,
  	FormControlLabel,
  	Grid
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle
} from './../../../_components';

import {
  Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_DEBITSBYLOCATION,
    ADMIN_DEBITSBYID
} from './../../../_helpers';

const ViewDebits = props => {
	const history = useHistory();
   	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [checked, setChecked] = useState(true);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios.get(
		  		ADMIN_DEBITSBYLOCATION,
		  		{ params: { location: currentUser.locations, status: checked } }
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);


  	const monthName = (month) => {
  		if(month === 1)
  			return 'Jan'
  		if(month === 2)
  			return 'Feb'
  		if(month === 3)
  			return 'Mar'
  		if(month === 4)
  			return 'Apr'
  		if(month === 5)
  			return 'May'
  		if(month === 6)
  			return 'Jun'
  		if(month === 7)
  			return 'Jul'
  		if(month === 8)
  			return 'Aug'
  		if(month === 9)
  			return 'Sep'
  		if(month === 10)
  			return 'Oct'
  		if(month === 11)
  			return 'Nov'
  		if(month === 12)
  			return 'Dec'
  	}

  	const handleData = (result) => {
		 	const record = [];
		 	result.map( (key, idx) => {
			  	const item = {
				 	id: idx,
				 	debitid: key.id,
				 	debitdate: new Date(key.debitdate).toLocaleDateString(),
				 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
					tenantcode: key.tenantcode,
				 	unit: key.roomnumber,
				 	period: monthName(key.month) + ' ' + key.year,
				 	amount: formatNumber(key.amount.toFixed(2)),
				 	natureofdebit: key.name,
				 	notes: key.remarks
			 }

			  	record.push(item)
			  	return;
			})

			return record;
  	}

	const formatNumber = (decimalsFormated) => {
	    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

  	const handleChange = async () => {
	   	let prev = checked
	   	setChecked(!prev);
	   	const result = await axios.get(
		  	ADMIN_DEBITSBYLOCATION,
		  	{ params: { location: currentUser.locations, status: !prev } }
		  );
			const d = handleData(result.data)
			setData(d);
  	};

  	const columns = [
	  	{
			name: "debitid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "debitdate",
		 	label: "Bill Date",
		 	options: {
		  		display: true,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "tenantcode",
		 	label: "Tenant Code",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "tenant",
		 	label: "Tenant",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "unit",
		 	label: "Unit",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "amount",
		 	label: "Amount",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "natureofdebit",
		 	label: "Nature of Bills",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "period",
		 	label: "Period",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
			downloadOptions: {
			  filename: 'billingHistory.csv',
			  separator: ',',
			  filterOptions: {
			    useDisplayedColumnsOnly: false,
			    useDisplayedRowsOnly: true
			  }
			},
  		print: false,
  		setRowProps: row => {
				return {
        	style: { cursor: "pointer" }
        };
  		},
  		onRowClick: async (rowData, rowMeta) => {
	 			const res = await axios.get(
					ADMIN_DEBITSBYID,
					{ params: { debitid: data[rowMeta.dataIndex].debitid } }
				);
				const result = res.data[0]

				const period = result.year + "-" + result.month + "-10"
				result.selectedPeriod = new Date(period)
				result.selectedDate = new Date(result.debitdate)

		  		history.push({
			        	pathname: '/admin/edit-debit',
			        	state: { record: result}
			    })  
  		}
// 		onDownload: (buildHead, buildBody, columns, data) => {
//   		 return "\uFEFF" + buildHead(columns) + buildBody(data); 
// }
	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Bills
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Reports
			 		</Link>
			 		<Link href="#" aria-current="page">
						Billing History
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
			   <FormControlLabel
	        		control={ <Switch checked={checked} onChange={ handleChange } /> }
	        		label={ checked ? "Active" : "Archived" }
	      		/>
	    </Grid>

			<Paper className="card-box">
		  		<BoxTitle datePickerRange={false}>Billing History</BoxTitle>
		  		{data &&
					<MUIDataTable
						data={data}
						columns={columns}
						options={options}
					/>
				}
			</Paper>
		</>
  	)
}

export default ViewDebits;