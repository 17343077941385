import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';

import {
  Box,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
	 authenticationService
} from './../../../_services';

import { 
    ADMIN_LOCATIONBYID,
    ADMIN_ANNOUNCEMENTS,
    ADMIN_TOP5BOOKINGS,
    ADMIN_FLOORSBYLOCATION,
    ADMIN_ALLROOMSBYLOCATION,
    ADMIN_ADMINFINANCIAL
} from './../../../_helpers';

import { makeStyles } from '@material-ui/core/styles';


// Style
const useStyles = makeStyles((theme) => ({
  spacing: {
	 padding: theme.spacing(3, 2)
  },
  borderRight: {
	 [theme.breakpoints.up('lg')]: {
		borderRight: `2px solid ${theme.palette.grey[300]}`
	 }
  },
  borderBottom: {
	 [theme.breakpoints.up('lg')]: {
		borderBottom: `2px solid ${theme.palette.grey[300]}`
	 }
  }
}));




const Dashboard = () => {
  const classes = useStyles();
  const [globalAnnouncement, setGlobalAnnouncement] = useState([]);
  const [reminderAnnouncement, setReminderAnnouncement] = useState([]);
  const [adminAnnouncement, setAdminAnnouncement] = useState([]);
  const [superadminAnnouncement, setSuperadminAnnouncement] = useState([]);
  const [bookingsHistory, setBookingsHistory] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [location, setLocation] = useState([]);
  const [currentUser] = useState(authenticationService.currentUserValue);
  const [announcements, setAnnouncements] = useState([]);
  const [occupancyRows, setOccupancyRows] = useState([]);
  const [financialStatusRows, setFinancialStatusRows] = useState([]);
  const [total, setTotal] = useState([])

  async function processData(floor, room) {
  	let temp = []
	let total = {
		available: 0, 
		occupied: 0, 
		vacant: 0,
		occupancy: 0
	}

	floor.forEach(f => {
		let occupied = 0
		let vacant = 0

		room.forEach(r => {
			if(f.floornum === r.floornum) {
				if(r.tenants === null)
					vacant += 1
				else
					occupied += 1
			}
		})

		let available = vacant + occupied
		let x = ( occupied / available ) * 100
		let totOcc = 0

		total.available += available
		total.occupied += occupied
		total.vacant += vacant
		totOcc = (total.occupied / total.available) * 100
		total.occupancy = totOcc.toFixed(2)

	  	let occupancy = {
	  		id: f.floornum, 
		 	floor: f.floornum,
		 	available: available, 
		 	occupied: occupied, 
		 	vacant: vacant,
		 	occupancy: x.toFixed(2) + '%'
	  	}
	  	
	  	temp.push(occupancy)
	});

	setOccupancyRows(temp)
	setTotal(total)

  }

const formatVal = (decimalsFormated) => {
    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  
  async function processFinancials(financials) {
    const data = [
      {
        id: 1,
        label: 'Cash Collected',
        amount: financials.credits ? formatVal(financials.credits.toFixed(2)) : 0
      },
      {
        id: 2,
        label: 'Revenues Recognized',
        amount: financials.debits ? formatVal(financials.debits.toFixed(2)) : 0
      },
      {
        id: 3,
        label: 'Over/(Under) Payment',
        amount: financials.overunder ? formatVal(financials.overunder.toFixed(2)) : 0
      },
      {
        id: 4,
        label: 'Unpaid Balance',
        amount: financials.receivables ? formatVal(financials.receivables.toFixed(2)) : 0
      }
    ];

    setFinancialStatusRows(data)
  }

	 useEffect(() => {
		  async function fetchData() {
				const locationname = await axios(
					 ADMIN_LOCATIONBYID,
					 { params: { location: currentUser.locations } }
				);
				setLocation(locationname.data[0].property)

				const result = await axios(
					 ADMIN_ANNOUNCEMENTS,
				);
				setGlobalAnnouncement(result.data[0].announcement);
				setReminderAnnouncement(result.data[1].announcement);
				setSuperadminAnnouncement(result.data[2].announcement);
				setAdminAnnouncement(result.data[3].announcement);
				setAnnouncements(result.data);

				if(currentUser.locations !== null) {
				  const result2 = await axios(
						ADMIN_TOP5BOOKINGS,
						{ params: { location: currentUser.locations } }
				  );
				  setBookingsHistory(result2.data)
				}

				if(currentUser.locations !== null) {
				  const result3 = await axios(
						ADMIN_FLOORSBYLOCATION,
						{ params: { location: currentUser.locations } }
				  );
				  setFloors(result3.data)

				  const result4 = await axios(
						ADMIN_ALLROOMSBYLOCATION,
						{ params: { location: currentUser.locations } }
				  );
				  setRooms(result4.data)
				  processData(result3.data, result4.data)


					const financials = await axios(
						 ADMIN_ADMINFINANCIAL,
						 { params: { location: currentUser.locations } }
					);
					processFinancials(financials.data)

				}

		  }
		  fetchData();
	 }, [setGlobalAnnouncement]);


  return (
	 <>
		<PageTitle>
		  <Typography 
			 component="h1" 
			 variant="h1" 
			 paragraph={false}
		  >
			 Dashboard
		  </Typography>
		</PageTitle>

		<Grid container spacing={4}>
		  <Grid item xs={12}>
			 <Paper className="card-box">
	            <BoxTitle 
	              button={true} 
	              buttonType={"update"} 
	              buttonLink={"/admin/announcement"}
	              buttonData={announcements}
	            >
	              Announcements
	            </BoxTitle>

				<Grid container>
				  <Grid item lg={6}>
					 <Box m={4} className={clsx("card-content", classes.borderRight)}>
						<Box className="scroll-area">
						  <Box className="scroll-content">
							 <Typography component="h3" variant="h3">{ location }</Typography>
							 <Typography style={{whiteSpace: 'pre-line'}}>{ superadminAnnouncement }</Typography>
						  </Box>
						</Box>
					 </Box>
				  </Grid>

				  <Grid item lg={6}>
					 <Box m={4} className={clsx("card-content", classes.borderBottom)}>
						<Typography component="h3" variant="h3">Global</Typography>
						<Box className="scroll-area small">
						  <Box className="scroll-content">
							 <Typography paragraph={true} style={{whiteSpace: 'pre-line'}}>{ globalAnnouncement }</Typography>
						  </Box>
						</Box>
					 </Box>

					 <Box m={4} className={clsx("card-content", classes.borderBottom)}>
						<Typography component="h3" variant="h3">Reminders from Super Admin</Typography>
						<Box className="scroll-area small">
						  <Box className="scroll-content">
							 <Typography paragraph={ true } style={{whiteSpace: 'pre-line'}}>{ reminderAnnouncement }</Typography>
						  </Box>
						</Box>
					 </Box>
					 <Box m={4} className="card-content">
						<Typography component="h3" variant="h3">Message to Super Admin</Typography>
						<Box className="scroll-area small">
						  <Box className="scroll-content">
							 <Typography paragraph={ true }  style={{whiteSpace: 'pre-line'}}>{ adminAnnouncement }</Typography>
						  </Box>
						</Box>
					 </Box>
				  </Grid>
				</Grid>
			 </Paper>
		  </Grid>

		  <Grid item xs={12} lg={6}>
			 <Paper className="card-box">
				<BoxTitle>Financial Status</BoxTitle>

				<TableContainer component={Paper} className={classes.spacing}>
				  <Table size="small">
					 <TableBody>
						{financialStatusRows.map((row, index) => (
						  <TableRow key={'fin-' + index}>
							 <TableCell>{row.label}</TableCell>
							 <TableCell>₱ {row.amount}</TableCell>
						  </TableRow>
						))}
					 </TableBody>
				  </Table>
				</TableContainer>
			 </Paper>
		  </Grid>

		  <Grid item xs={12} lg={6}>
			 <Paper className="card-box">
				<BoxTitle>Occupancy Status</BoxTitle>
				<TableContainer component={Paper}>
				  <Table>
					 <TableHead>
						<TableRow>
						  <TableCell>Floor</TableCell>
						  <TableCell>Units Available</TableCell>
						  <TableCell>Units Occupied</TableCell>
						  <TableCell>Units Vacant</TableCell>
						  <TableCell>Occupancy %</TableCell>
						</TableRow>
					 </TableHead>
					 <TableBody>
						{ occupancyRows.length !== 0
						  ? occupancyRows.map((row, index) => (
							 <>
								<TableRow key={'occ-' + index}>
								  <TableCell>{row.floor}</TableCell>
								  <TableCell>{row.available}</TableCell>
								  <TableCell>{row.occupied}</TableCell>
								  <TableCell>{row.vacant}</TableCell>
								  <TableCell>{row.occupancy}</TableCell>
								</TableRow>


								{ occupancyRows.length === (index + 1) &&
								  <TableRow key={'tot-' + index }>
									 <TableCell>TOTAL</TableCell>
									 <TableCell>{ total.available }</TableCell>
									 <TableCell>{ total.occupied }</TableCell>
									 <TableCell>{ total.vacant }</TableCell>
									 <TableCell>{ total.occupancy + '%' }</TableCell>
								  </TableRow>
								}
								</>
							 ))
						  : <TableRow>
								<TableCell>No Data</TableCell>
							 </TableRow>
						}
					 </TableBody>
				  </Table>
				</TableContainer>
			 </Paper>
		  </Grid>

		  <Grid item xs={12} lg={12}>
			 <Paper className="card-box">
				<BoxTitle 
				  button={true} 
				  buttonType="icon"
				  buttonLink="/app/admin/view-bookings"
				>
				  Bookings
				</BoxTitle>
				
				<TableContainer component={Paper}>
				  <Typography>*Last 5 transactions only. Click eye icon for more data</Typography>

				  <Table>
					 <TableHead>
						<TableRow>
						  <TableCell>Date</TableCell>
						  <TableCell>Tenant</TableCell>
						  <TableCell>Unit #</TableCell>
						  <TableCell>Facility</TableCell>
						  <TableCell>Remarks</TableCell>
						  <TableCell>Amount</TableCell>
						</TableRow>
					 </TableHead>
					 <TableBody>
						{ bookingsHistory.length !== 0
						  ? bookingsHistory.map((row, index) => (
								<TableRow key={'boo-' + index}>
								  <TableCell>{ new Date(row.bookingdate).toLocaleDateString() }</TableCell>
								  <TableCell>{ row.lastname + ', ' + row.firstname + ' ' + row.middlename }</TableCell>
								  <TableCell>{row.unit}</TableCell>
								  <TableCell>{ row.facilityname }</TableCell>
								  <TableCell>{ row.notes }</TableCell>
								  <TableCell>{ new Intl.NumberFormat("en-US", {
						  style: "currency",
						  currency: "PHP",
						  currencyDisplay: 'narrowSymbol',
						  minimumFractionDigits: 2,
						  maximumFractionDigits: 2
					}).format(row.amount) }</TableCell>
								</TableRow>
							 )) 
						  : <TableRow>
								<TableCell>No Transactions</TableCell>
							 </TableRow>
						}
					 </TableBody>
				  </Table>
				</TableContainer>
			 </Paper>
		  </Grid>
		</Grid>
	 </>
  )
}


export default Dashboard;