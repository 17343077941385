import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';

import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography
} from '@material-ui/core';


import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { makeStyles } from '@material-ui/core/styles';

import { 
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@material-ui/icons';

import { 
  authenticationService
} from './../../../_services';

import {
    ADMIN_ANNOUNCEMENTS,
    TENANT_TOP3DEBITS,
    TENANT_TOP5BOOKINGS,
    TENANT_FINANCIALSTATUS
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
  spacing: {
    padding: theme.spacing(3, 2)
  },
  borderBottom: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(1)
  },
  datePickerRangeStart: {
    marginRight: theme.spacing(1)
  },
  button: {
    height: '38px',
    margin: theme.spacing(1, 0, 0, 2)
  }
}));



const Dashboard = () => {
  const classes = useStyles();
  const [globalAnnouncement, setGlobalAnnouncement] = useState([]);
  const [adminAnnouncement, setAdminAnnouncement] = useState([]);
  const [debitHistory, setDebitHistory] = useState([]);
  const [bookingHistory, setBookingHistory] = useState([]);
  const [financialStatus, setFinacialStatus] = useState([]);
  const [currentTenant] = useState(authenticationService.currentTenantValue);

  useEffect(() => {
        async function fetchData() {
            const result = await axios(
                ADMIN_ANNOUNCEMENTS,
            );
            setGlobalAnnouncement(result.data[0].announcement);
            setAdminAnnouncement(result.data[2].announcement);

            const debits = await axios.get(
              TENANT_TOP3DEBITS,
              { params: { tenant: currentTenant.id } }
            );
            setDebitHistory(debits.data);

            const bookings = await axios.get(
              TENANT_TOP5BOOKINGS,
              { params: { tenant: currentTenant.id } }
            );
            setBookingHistory(bookings.data);

            const financial = await axios.get(
              TENANT_FINANCIALSTATUS,
              { params: { tenant: currentTenant.id } }
            );
            console.log(financial.data)
            setFinacialStatus(financial.data)

        }
        if(currentTenant)  fetchData();
  }, [setGlobalAnnouncement, setDebitHistory, currentTenant]);

    const monthName = (month) => {
      if(month === 1)
        return 'Jan'
      if(month === 2)
        return 'Feb'
      if(month === 3)
        return 'Mar'
      if(month === 4)
        return 'Apr'
      if(month === 5)
        return 'May'
      if(month === 6)
        return 'Jun'
      if(month === 7)
        return 'Jul'
      if(month === 8)
        return 'Aug'
      if(month === 9)
        return 'Sep'
      if(month === 10)
        return 'Oct'
      if(month === 11)
        return 'Nov'
      if(month === 12)
        return 'Dec'
    }

const formatVal = (decimalsFormated) => {
    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

// Data
const financialStatusRows = [
  {
    id: 1,
    label: 'Downpayment',
    amount: financialStatus.downpayment ? formatVal(financialStatus.downpayment.toFixed(2)) : 0,
    action: false,
    url: null
  },
  {
    id: 2,
    label: 'Available Cash Balance',
    amount: financialStatus.cashbalance ? ( (financialStatus.cashbalance > 0 ) ? formatVal(financialStatus.cashbalance.toFixed(2)) : 0) : 0,
    action: false,
    url: null
  },
  {
    id: 3,
    label: 'Payments',
    amount: financialStatus.credits ? formatVal(financialStatus.credits.toFixed(2)) : 0,
    action: true,
    url: '/app/tenant/view-credits'
  },
  {
    id: 4,
    label: 'Bills',
    amount: financialStatus.debits ? formatVal(financialStatus.debits.toFixed(2))  : 0,
    action: true,
    url: '/app/tenant/view-debits'
  },
  {
    id: 5,
    label: 'Over/(Under) Payments',
    amount: financialStatus.overunder ? formatVal(financialStatus.overunder.toFixed(2))  : 0,
    action: false,
    url: null
  },
  {
    id: 6,
    label: 'Unpaid Balance',
    amount: financialStatus.receivables ? formatVal(financialStatus.receivables.toFixed(2))  : 0,
    action: false,
    url: null
  }
];


  return (
    <>
      <PageTitle>
        <Typography 
          component="h1" 
          variant="h1" 
          paragraph={false}
        >
          Dashboard
        </Typography>
      </PageTitle>

      <Grid container spacing={4}>
        <Grid item lg={6}>
          <Paper className="card-box">
            <BoxTitle>Announcements</BoxTitle>

            <Box m={4} className={clsx("card-content", classes.borderBottom)}>
              <Typography component="h3" variant="h3">Global</Typography>
              <Box className="scroll-area medium">
                <Box className="scroll-content">
                  <Typography paragraph={true} style={{whiteSpace: 'pre-line'}}>{ globalAnnouncement }</Typography>
                </Box>
              </Box>
            </Box>
            <Box  m={4} className={clsx("card-content", classes.bottomSpacing)}>
              <Typography component="h3" variant="h3">Reminders From Admin</Typography>
              <Box className="scroll-area medium">
                <Box className="scroll-content">
                  <Typography paragraph={ true }  style={{whiteSpace: 'pre-line'}}>{ adminAnnouncement }</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item lg={6}>
          <Paper className="card-box">
            <BoxTitle>Financial Status</BoxTitle>

            <TableContainer component={Paper} className={classes.spacing}>
              
              <Table size="small">
                <TableBody>
                  {financialStatusRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                      { row.action === true ? 
                        <TableCell>
                          <Link className="icon" href={row.url}>
                            <VisibilityOutlinedIcon fontSize="small" />
                          </Link>
                        </TableCell> 
                      : 
                        <TableCell /> 
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper className="card-box">
            <BoxTitle 
              button={true} 
              buttonType="icon"
              buttonLink="/app/tenant/view-debits"
            >
              Bills History
            </BoxTitle>

            <TableContainer component={Paper}>
              <Typography>*Last 3 transactions only. Click eye icon for more data</Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Period</TableCell>
                    <TableCell>Nature of Bills</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { debitHistory.length !== 0
                    ? debitHistory.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{new Date(row.debitdate).toLocaleDateString()}</TableCell>
                          <TableCell>{monthName(row.month) + ' ' + row.year}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "PHP",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                      }).format(row.amount)}</TableCell>
                        </TableRow>
                      )) 
                    : <TableRow>
                        <TableCell>No Transactions</TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item lg={12}>
          <Paper className="card-box">
            <BoxTitle 
              button={true} 
              buttonType="icon"
              buttonLink="/app/tenant/view-bookings"
            >
              Booking Status
            </BoxTitle>
            
            <TableContainer component={Paper}>
              <Typography>*Last 5 transactions only. Click eye icon for more data</Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Facility</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { bookingHistory.length !== 0
                    ? bookingHistory.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{ new Date(row.bookingdate).toLocaleDateString() }</TableCell>
                          <TableCell>{ row.facilityname }</TableCell>
                          <TableCell>{row.notes}</TableCell>
                          <TableCell>{new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "PHP",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
               }).format(row.amount)}</TableCell>
                        </TableRow>
                      )) 
                    : <TableRow>
                        <TableCell>No Transactions</TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}


export default Dashboard;