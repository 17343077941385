import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        marginTop: theme.spacing(6),
    }
}));


function Success(props) {
    const classes = useStyles();

    const pathname = props.location.pathname;
    return (
        <div className={classes.paper}>
            <Typography align='center' className={classes.title}>
                { props.location.state && props.location.state.type === 'change-password'  && 
                    <span>Password successfully changed. Please <Link to={ pathname === '/success' ? '/sign-in' : '/tenant/sign-in' }>Sign-in</Link> again.</span>
                }

                { props.location.state && props.location.state.type === 'forgot-password'  && 
                    <span>Password sent to your email. Please check and and <Link to={ pathname === '/success' ? '/sign-in' : '/tenant/sign-in' }>Sign-in</Link> again.</span>
                }
            </Typography>
        </div>
    );
}


export default Success;