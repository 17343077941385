import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { 
	Alert 
} from '@material-ui/lab';

import {
 	Box,
  	Breadcrumbs,
  	Button,
  	FormControl,
  	Grid,
  	InputAdornment,
  	InputLabel,
  	Link,
  	MenuItem,
  	OutlinedInput,
  	Paper,
  	Select,
  	TextField,
  	Typography,
  	FormHelperText,
} from '@material-ui/core';

import {
  	MuiPickersUtilsProvider,
  	DatePicker,
} from '@material-ui/pickers';

import { 
  	makeStyles 
} from '@material-ui/core/styles';


import { 
  	BoxTitle,
  	PageTitle,
} from './../../../_components';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_PROFILEDETAILS,
    ADMIN_UPDATETENANT,
    ADMIN_ROOMSBYLOCATIONTENANT
} from './../../../_helpers';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
 	root: {
		flexGrow: 1,
  	},
  	formControl: {
	 	width: '100%'
  	},
  	button: {
	 	margin: theme.spacing(4, 2, 0, 0)
  	},
   tenantContainer: {
      paddingBottom: '5%'
   }
}));

const EditAccount = (props) => {
 	const classes = useStyles();
 	const history = useHistory();
  	const [error, setError] = useState(null);
  	const [success, setSuccess] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [selectedDate, handleDateChange] = useState(new Date());
  	const [startDate, handleStartDateChange] = useState(new Date());
  	const [account, setAccount] = useState([]);
   const [roomList, setRoomList] = useState([]);

  	useEffect(() => {
  		async function fetchData() {

         const result = await axios.get(
            ADMIN_ROOMSBYLOCATIONTENANT, 
            { params: { location: currentUser.locations, tenant: props.location.state.record[0] } }
         );
         setRoomList(result.data);

  			const tenants = await axios(
  				ADMIN_PROFILEDETAILS,
  				{ params: { tenant: props.location.state.record[0] } }
  			);
  			handleAccount(tenants.data)

  		}

  	 	if(currentUser) fetchData();
  	}, [setRoomList, setAccount]);

  	const handleAccount = async (data) => {
  		const details = data.details;
  		const ref = data.references;

  		let account = {
            tenantid: details.id,
            firstname: details.firstname, 
            middlename: details.middlename,
            lastname: details.lastname,
            address: details.address,
            gender: (details.gender === 1) ? 'male' : 'female',
            mobile: details.mobile,
            position: details.positioncourse,
            landline: details.landline,
            civilstatus: (details.civilstatus === 1) ? 'single' : 'married',
            email: details.email,
            partnersname: details.spouse,
            instagram: details.instagram,
            sourceoffunds: details.sourceofpayment,
            employername: details.nameempschool,
            employeraddress: details.addressempschool,
            tenantcode: details.tenantcode,
            roomnumber: details.roomnumber,
            rentamount: details.rent,
            withaircon: (details.aircon === 1) ? 'yes' : 'no',
            advance: details.advance,
            deposit: details.deposit,
            contractterm: details.contractterm,
            refid1: ref[0].id,
			refname1: ref[0].name,
            refaddress1: ref[0].address,
            refmobile1: ref[0].mobile,
            reflandline1: ref[0].landline,
            refrelationship1: ref[0].relationship,
            refid2: ref[1].id,
            refname2: ref[1].name,
            refaddress2: ref[1].address,
            refmobile2: ref[1].mobile,
            reflandline2: ref[1].landline,
            refrelationship2: ref[1].relationship
  		}
  		handleDateChange(details.birthdate)
		handleStartDateChange(details.rentstart)
  		setAccount(account)

  	}

	const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {

      setSubmitting(true);
      setError(null);
      setSuccess(null);

      const data = values;
      data.tenant = account.tenantid;
      data.location = currentUser.locations;
      data.birthdate = selectedDate;
      data.startdate = startDate;
      data.roomnumber = values.roomnumber
      data.prevRoomNumber = account.roomnumber
      data.aircon = (values.withaircon === 'yes' ? 1 : 0)
      data.civil = (values.civilstatus === 'single' ? 1 : 2)
      data.sex = (values.gender === 'male' ? 1 : 2)
      data.refid1 = account.refid1
      data.refid2 = account.refid2

      axios
            .post(  
                ADMIN_UPDATETENANT,
                data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
                  setSuccess(result.message);
               } else {
                  result.isSuccess = false;
                  result.message = response.data
                  setError(result.message)
               }

               setSubmitting(false);
                           
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
               setError(result.message)
            });
	}

   const renderSelectRoomsOptions = () => {
      return roomList.map((dt, i) => {
         return (
               <MenuItem key={i} value={dt.roomname}>
                  {dt.roomname}
               </MenuItem>
            );
         });
   }

	return (
		<div className={classes.root}>
			<PageTitle>
				<Typography 
					component="h1" 
					variant="h1" 
					paragraph={false}
				>
					Accounts
				</Typography>

				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
					</Link>
					<Link color="inherit" href="#">
						Tenants
					</Link>
					<Link href="#" aria-current="page">
						Edit Account
					</Link>
				</Breadcrumbs>
			</PageTitle>

			{ error &&            
				<Alert severity="error">{ error } </Alert>
			}

			{ success &&
				<>
				<Alert severity="success">{ success } </Alert>
            <Button 
            	type="button"
               variant="contained" 
               color="secondary" 
               className={classes.button}
               onClick={ () => history.push({pathname: '/admin/profiles'}) }
            >
               Close
            </Button>
            </>
			}
			
			{ account.firstname ? 
         <Formik
            initialValues={{ 
               firstname: account ? account.firstname : '', 
               middlename: account ? account.middlename : '',
               lastname: account ? account.lastname : '',
               address: account ? account.address : '',
               gender: account ? account.gender : '',
               mobile: account ? account.mobile : '',
               position: account ? account.position : '',
               landline: account ? account.landline : '',
               civilstatus: account ? account.civilstatus : '',
               email: account ? account.email : '',
               partnersname: account ? account.partnersname : '',
               instagram: account ? account.instagram : '',
               sourceoffunds: account ? account.sourceoffunds : '',
               employername: account ? account.employername : '',
               employeraddress: account ? account.employeraddress : '',
               tenantcode: account ? account.tenantcode : '',
               roomnumber: account ? account.roomnumber : '',
               rentamount: account ? account.rentamount : '',
               withaircon: account ? account.withaircon : '',
               advance: account ? account.advance : '',
               deposit: account ? account.deposit : '',
               contractterm: account ? account.contractterm : '',
               refname1: account ? account.refname1 : '',
               refaddress1: account ? account.refaddress1 : '',
               refmobile1: account ? account.refmobile1 : '',
               reflandline1: account ? account.reflandline1 : '',
               refrelationship1: account ? account.refrelationship1 : '',
               refname2: account ? account.refname2 : '',
               refaddress2: account ? account.refaddress2 : '',
               refmobile2: account ? account.refmobile2 : '',
               reflandline2: account ? account.reflandline2 : '',
               refrelationship2: account ? account.refrelationship2 : ''
            }}
            onSubmit={ handleSubmit }
            validationSchema={Yup.object().shape({
               firstname: Yup.string()
                  .required('Required'),
               middlename: Yup.string(),
               lastname: Yup.string()
                  .required('Required'),
               address: Yup.string()
               	.required('Required'),
               gender: Yup.string()
                  .required('Required'),
               mobile: Yup.string()
                  .required('Required'),
               position: Yup.string()
                  .required('Required'),
               landline: Yup.string(),
               civilstatus: Yup.string()
                  .required('Required'),
               email: Yup.string()
               	.email('Invalid email')
                  .required('Required'),
               partnersname: Yup.string()
                  .when('civilstatus', {
				      	is: value => value === 'married'  ,
				        	then: Yup.string().required('Required'),
				        	otherwise: Yup.string().nullable()
				      }),
               instagram: Yup.string(),
               sourceoffunds: Yup.string()
                  .required('Required'),
               employername: Yup.string()
                  .required('Required'),
               employeraddress: Yup.string()
                  .required('Required'),
               roomnumber: Yup.string()
                  .required('Required'),
               rentamount: Yup.string()
                  .required('Required'),
               withaircon: Yup.string()
                  .required('Required'),
               advance: Yup.string()
                  .required('Required'),
               deposit: Yup.string()
                  .required('Required'),
               contractterm: Yup.string()
                  .required('Required'),
               refname1: Yup.string()
                  .required('Required'),
               refaddress1: Yup.string()
                  .required('Required'),
               refmobile1: Yup.string()
                  .required('Required'),
               reflandline1: Yup.string(),
               refrelationship1: Yup.string()
                  .required('Required'),
               refname2: Yup.string(),
               refaddress2: Yup.string(),
               refmobile2: Yup.string(),
               reflandline2: Yup.string(),
               refrelationship2: Yup.string(),
            })}
         >
            {(props) => {
               const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
               } = props;
               
               return (
                  <form onSubmit={handleSubmit} >
							<Grid container spacing={4}>
			 					<Grid item lg={6}>
									<Paper className="card-box">
				  						<BoxTitle>Tenant</BoxTitle>

				  						<Box m={4} className="card-content">
					 						<Grid container spacing={2}>
						
												<Grid item xs={12}>
			                              <TextField 
			                                 fullWidth
			                                 label="First Name"
			                                 id="firstname"
			                                 variant="outlined"
			                                 value={values.firstname}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.firstname && touched.firstname) && errors.firstname}
			                                 error={errors.firstname && touched.firstname}
			                              />
												</Grid>
												<Grid item xs={12}>
			                              <TextField 
			                                 fullWidth
			                                 label="Middle Name"
			                                 id="middlename"
			                                 variant="outlined"
			                                 value={values.middlename}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.middlename && touched.middlename) && errors.middlename}
			                                 error={errors.middlename && touched.middlename}
			                              />
												</Grid>
												<Grid item xs={12}>
			                              <TextField 
			                                 fullWidth
			                                 label="Last Name"
			                                 id="lastname"
			                                 variant="outlined"
			                                 value={values.lastname}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.lastname && touched.lastname) && errors.lastname}
			                                 error={errors.lastname && touched.lastname}
			                              />
												</Grid>
												<Grid item xs={12}>
			                              <TextField 
			                                 fullWidth
			                                 label="Address"
			                                 id="address"
			                                 variant="outlined"
			                                 value={values.address}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.address && touched.address) && errors.address}
			                                 error={errors.address && touched.address}
			                              />
												</Grid>
												<Grid item xs={12} md={6}>
							 						<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DatePicker
															fullWidth
														  	label="Birth Date"
														  	inputVariant="outlined"
														  	format="MM/dd/yyyy"
														  	value={selectedDate}
														  	onChange={handleDateChange}
														  	animateYearScrolling
														/>
							 						</MuiPickersUtilsProvider>
												</Grid>
                                    <Grid item xs={12} md={6}>
                                       <TextField 
                                          fullWidth
                                          label="Name of Employer/School"
                                          id="employername"
                                          variant="outlined"
                                          value={values.employername}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          helperText={(errors.employername && touched.employername) && errors.employername}
                                          error={errors.employername && touched.employername}
                                       />
                                    </Grid>
												<Grid item xs={12} md={6}>
													<FormControl variant="outlined" className={classes.formControl}>
														<InputLabel id="gender-label">Gender</InputLabel>
			                                 <Select
			                                    label="Gender"
			                                    labelId="gender-label"
			                                    id="gender"
			                                    name="gender"
			                                    value={values.gender}
			                                    onChange={handleChange}
			                                    onBlur={handleBlur}
			                                    error={errors.gender && touched.gender}
			                                 >
														  	{[
																{ label: 'Male', value: 'male' }, 
															 	{ label: 'Female', value: 'female' }
														  	].map((item) => (
																<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
														  	))}
			                                 </Select>
			                                 <FormHelperText>{(errors.gender && touched.gender) && errors.gender}</FormHelperText>
													</FormControl>
												</Grid>
                                    <Grid item xs={12} md={6}>
                                       <TextField 
                                          fullWidth
                                          label="Address of Employer/School"
                                          id="employeraddress"
                                          variant="outlined"
                                          value={values.employeraddress}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          helperText={(errors.employeraddress && touched.employeraddress) && errors.employeraddress}
                                          error={errors.employeraddress && touched.employeraddress}
                                       />
                                    </Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Mobile Number"
			                                 id="mobile"
			                                 variant="outlined"
			                                 value={values.mobile}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.mobile && touched.mobile) && errors.mobile}
			                                 error={errors.mobile && touched.mobile}
			                              />
												</Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Position/Course"
			                                 id="position"
			                                 variant="outlined"
			                                 value={values.position}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.position && touched.position) && errors.position}
			                                 error={errors.position && touched.position}
			                              />
												</Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Landline Number"
			                                 id="landline"
			                                 variant="outlined"
			                                 value={values.landline}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.landline && touched.landline) && errors.landline}
			                                 error={errors.landline && touched.landline}
			                              />
												</Grid>
												<Grid item xs={12} md={6}>
													<FormControl variant="outlined" className={classes.formControl}>
														<InputLabel id="civil-status-label">Civil Status</InputLabel>
			                                 <Select
			                                    label="Civil Status"
			                                    labelId="civil-status-label"
			                                    id="civilstatus"
			                                    name="civilstatus"
			                                    value={values.civilstatus}
			                                    onChange={handleChange}
			                                    onBlur={handleBlur}
			                                    error={errors.civilstatus && touched.civilstatus}
			                                 >
														  	{[
																{ label: 'Single/Widow/Widower/Divorced', value: 'single' }, 
															 	{ label: 'Married/Common Law', value: 'married' }
														  	].map((item) => (
																<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
														  	))}
			                                 </Select>
			                                 <FormHelperText>{(errors.civilstatus && touched.civilstatus) && errors.civilstatus}</FormHelperText>
												  	</FormControl>
												</Grid>
												<Grid item xs={12} md={6}>
                          <Typography variant="caption" display="block" gutterBottom>
                            Email
                          </Typography>
                          <Typography variant="body1" >
                            {values.email}
                          </Typography>
												</Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Partner's Name"
			                                 id="partnersname"
			                                 variant="outlined"
			                                 value={values.partnersname}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.partnersname && touched.partnersname) && errors.partnersname}
			                                 error={errors.partnersname && touched.partnersname}
			                              />
												</Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Instagram"
			                                 id="instagram"
			                                 variant="outlined"
			                                 value={values.instagram}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.instagram && touched.instagram) && errors.instagram}
			                                 error={errors.instagram && touched.instagram}
			                              />
												</Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Source of Funds"
			                                 id="sourceoffunds"
			                                 variant="outlined"
			                                 value={values.sourceoffunds}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.sourceoffunds && touched.sourceoffunds) && errors.sourceoffunds}
			                                 error={errors.sourceoffunds && touched.sourceoffunds}
			                              />
												</Grid>
					 						</Grid>
				  						</Box>
									</Paper>
			 					</Grid>

			 					<Grid item lg={6}>
									<Paper className="card-box">
				  						<BoxTitle>Rent Information</BoxTitle>

				  						<Box m={4} className="card-content">
					 						<Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption" display="block" gutterBottom>
                            Tenant Code
                          </Typography>
                          <Typography variant="body1" >
                            {values.tenantcode}
                          </Typography>
                        </Grid>
                                    <Grid item xs={12} md={6}>
                                       <FormControl variant="outlined" className={classes.formControl}>
                                          <InputLabel id="room-number-label">Room Number</InputLabel>
                                          <Select
                                             label="Room Number"
                                             labelId="room-number-label"
                                             id="roomnumber"
                                             name="roomnumber"
                                             value={values.roomnumber}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             error={errors.roomnumber && touched.roomnumber}
                                          >
                                             {renderSelectRoomsOptions()}
                                          </Select>
                                          <FormHelperText>{(errors.roomnumber && touched.roomnumber) && errors.roomnumber}</FormHelperText>
                                       </FormControl>
                                    </Grid>
												<Grid item xs={12} md={6}>
													<FormControl fullWidth className={classes.margin} variant="outlined">
													 	<InputLabel htmlFor="rent-amount">Rent Amount</InputLabel>
				                              <OutlinedInput 
				                                 fullWidth
				                                 label="Rent Amount"
				                                 startAdornment={<InputAdornment position="start">₱</InputAdornment>}
				                                 id="rentamount"
				                                 variant="outlined"
				                                 value={values.rentamount}
				                                 onChange={handleChange}
				                                 onBlur={handleBlur}
				                                 helperText={(errors.rentamount && touched.rentamount) && errors.rentamount}
				                                 error={errors.rentamount && touched.rentamount}
				                              />	
												  	</FormControl>
												</Grid>
												<Grid item xs={12} md={6}>
													<FormControl variant="outlined" className={classes.formControl}>
													 	<InputLabel id="with-aircon-label">With Aircon</InputLabel>
			                                 <Select
			                                    label="With Aircon"
			                                    labelId="with-aircon-label"
			                                    id="withaircon"
			                                    name="withaircon"
			                                    value={values.withaircon}
			                                    onChange={handleChange}
			                                    onBlur={handleBlur}
			                                    error={errors.withaircon && touched.withaircon}
			                                 >
														  	{[
																{ label: 'Yes', value: 'yes' }, 
															 	{ label: 'No', value: 'no' }
														  	].map((item) => (
																<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
														  	))}
			                                 </Select>
			                                 <FormHelperText>{(errors.withaircon && touched.withaircon) && errors.withaircon}</FormHelperText>
												  	</FormControl>
												</Grid>
												<Grid item xs={12} md={6}>
													<FormControl fullWidth className={classes.margin} variant="outlined">
													 	<InputLabel htmlFor="advance-amount">Advance</InputLabel>
				                              <OutlinedInput 
				                                 fullWidth
				                                 label="Advance"
				                                 startAdornment={<InputAdornment position="start">₱</InputAdornment>}
				                                 id="advance"
				                                 variant="outlined"
				                                 value={values.advance}
				                                 onChange={handleChange}
				                                 onBlur={handleBlur}
				                                 helperText={(errors.advance && touched.advance) && errors.advance}
				                                 error={errors.advance && touched.advance}
				                              />	
												  	</FormControl>
												</Grid>
												<Grid item xs={12} md={6}>
							 						<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DatePicker
															fullWidth
														  	label="Start Date"
														  	inputVariant="outlined"
														  	format="MM/dd/yyyy"
														  	value={startDate}
														  	onChange={handleStartDateChange}
														  	animateYearScrolling
														/>
							 						</MuiPickersUtilsProvider>
												</Grid>
												<Grid item xs={12} md={6}>
													<FormControl fullWidth className={classes.margin} variant="outlined">
													 	<InputLabel htmlFor="deposit-amount">Deposit</InputLabel>
				                              <OutlinedInput 
				                                 fullWidth
				                                 label="Deposit"
				                                 startAdornment={<InputAdornment position="start">₱</InputAdornment>}
				                                 id="deposit"
				                                 variant="outlined"
				                                 value={values.deposit}
				                                 onChange={handleChange}
				                                 onBlur={handleBlur}
				                                 helperText={(errors.deposit && touched.deposit) && errors.deposit}
				                                 error={errors.deposit && touched.deposit}
				                              />	
												  	</FormControl>
												</Grid>
												<Grid item xs={12} md={6}>
			                              <TextField 
			                                 fullWidth
			                                 label="Contract Term (in months)"
			                                 id="contractterm"
			                                 variant="outlined"
			                                 value={values.contractterm}
			                                 onChange={handleChange}
			                                 onBlur={handleBlur}
			                                 helperText={(errors.contractterm && touched.contractterm) && errors.contractterm}
			                                 error={errors.contractterm && touched.contractterm}
			                              />
												</Grid>
					 						</Grid>
				  						</Box>
									</Paper>

									<Paper className="card-box">
				  						<BoxTitle>Emergency Contact Person</BoxTitle>
				  
				  						<Box m={4} className="card-content">
					 						<Grid container spacing={2}>
												<Grid item xs={12} md={6}>
						  							<Grid container spacing={2}>
							 						{[
														{ id: 'refname1', label: 'Name', value: values.refname1, errors: errors.refname1, touched: touched.refname1 }, 
														{ id: 'refaddress1', label: 'Address', value: values.refaddress1, errors: errors.refaddress1, touched: touched.refaddress1 }, 
														{ id: 'refmobile1', label: 'Mobile Number', value: values.refmobile1, errors: errors.refmobile1, touched: touched.refmobile1 }, 
														{ id: 'reflandline1', label: 'Landline Number', value: values.reflandline1, errors: errors.reflandline1, touched: touched.reflandline1 }, 
														{ id: 'refrelationship1', label: 'Relationship', value: values.refrelationship1, errors: errors.refrelationship1, touched: touched.refrelationship1 }
							 						].map((item, index) => (
														<Grid  key={index} item xs={12}>
					                              <TextField 
					                                 fullWidth
					                                 label={item.label}
					                                 id={item.id}
					                                 variant="outlined"
					                                 value={item.value}
					                                 onChange={handleChange}
					                                 onBlur={handleBlur}
					                                 helperText={(item.errors && item.touched) && item.errors}
					                                 error={item.errors && item.touched}
					                              />
														</Grid>
							 						))}
						  						</Grid>
											</Grid>
											<Grid item xs={12} md={6}>
						  						<Grid container spacing={2}>
							 						{[
														{ id: 'refname2', label: 'Name', value: values.refname2, errors: errors.refname2, touched: touched.refname2 }, 
														{ id: 'refaddress2', label: 'Address', value: values.refaddress2, errors: errors.refaddress2, touched: touched.refaddress2 }, 
														{ id: 'refmobile2', label: 'Mobile Number', value: values.refmobile2, errors: errors.refmobile2, touched: touched.refmobile2 }, 
														{ id: 'reflandline2', label: 'Landline Number', value: values.reflandline2, errors: errors.reflandline2, touched: touched.reflandline2 }, 
														{ id: 'refrelationship2', label: 'Relationship', value: values.refrelationship2, errors: errors.refrelationship2, touched: touched.refrelationship1 }
							 						].map((item, index) => (
														<Grid  key={index} item xs={12}>
					                              <TextField 
					                                 fullWidth
					                                 label={item.label}
					                                 id={item.id}
					                                 variant="outlined"
					                                 value={item.value}
					                                 onChange={handleChange}
					                                 onBlur={handleBlur}
					                                 helperText={(item.errors && item.touched) && item.errors}
					                                 error={item.errors && item.touched}
					                              />
														</Grid>
							 						))}
						  						</Grid>
											</Grid>
					 					</Grid>
				  					</Box>
								</Paper>

	                     <Button
	                        type="submit"
	                        disabled={isSubmitting}
	                        variant="contained"
	                        color="secondary"
	                        className={classes.button}
	                     >
	                        Submit
	                     </Button>

            <Button 
               type="button"
               variant="contained" 
               color="default" 
               className={classes.button}
               onClick={ () => history.push({pathname: '/admin/profiles'}) }
            >
               Cancel
            </Button>
			 				</Grid>
		  				</Grid>
					</form>
		      );
         }}
      </Formik>	:
      <span>loading...</span> }
		</div>
  	)
}

export default EditAccount;