import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#015384',
    main: '#0277BD',
    light: '#3492ca'
  },
  secondary: {
    contrastText: white,
    dark: colors.green[700],
    main: colors.green[600]
  },
  tertiary: {
    contrastText: white,
    dark: '#35495a',
    main: '#445b6f',
    light: colors.blueGrey[50]
  },
  error: {
    contrastText: white,
    main: colors.pink[700]
  },
  text: {
    primary: colors.grey[900],
    link: colors.blue[600]
  },
  background: {
    default: colors.grey[100],
    paper: white
  }
};
