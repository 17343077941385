import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiButton from './MuiButton';
import MuiList from './MuiList';
import MuiPaper from './MuiPaper';
import MuiTable from './MuiTable';
import MuiTableContainer from './MuiTableContainer';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';

export default {
  MuiBreadcrumbs,
  MuiButton,
  MuiList,
  MuiPaper,
  MuiTable,
  MuiTableContainer,
  MuiTableCell,
  MuiTableHead
};
