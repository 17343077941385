import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Breadcrumbs,
   Button,
   Grid,
   Link,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
    ADMIN_SAVENATUREDEBIT
} from './../../../../_helpers';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const VerifyCreateNatureOfDebit = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [natureofdebit] = useState(props.location.state && props.location.state.record);

   const goBack = () => {
      history.push({
         pathname: '/superadmin/create-nature-of-debit',
         state: { record: natureofdebit }
      })
   }

   const createNatureOfDebit = () => {
        axios
            .post( 
                ADMIN_SAVENATUREDEBIT,
                natureofdebit,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               history.push({
                  pathname: '/superadmin/verified-create-nature-of-debit',
                  state: { record: natureofdebit, result: result }
               })
            
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
               history.push({
                  pathname: '/superadmin/verified-nature-of-debit',
                  state: { record: natureofdebit, result: result }
               })
            });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Nature of Bills
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link href="#" aria-current="page">
                  Create Nature of Bills
               </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Verify Create Nature of Bills</BoxTitle>

            <Box m={4} className="card-content">
               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={natureofdebit.property}
                           secondary="Dorm" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={natureofdebit.name}
                           secondary="Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={ (natureofdebit.status === 1) ? 'Active' : 'Inactive'} 
                           secondary="Status" />
                     </ListItem>
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => createNatureOfDebit() }
                     >
                        Create Nature of Bills
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default VerifyCreateNatureOfDebit;