import React, { useState } from 'react';
import { Formik } from 'formik';
import { TextareaAutosize } from '@material-ui/core';
import * as Yup from 'yup';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import {
  Breadcrumbs,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';

import { 
  makeStyles,
} from '@material-ui/core/styles';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import {
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
    ADMIN_UPDATEANNOUNCEMENT
} from './../../../_helpers';

// Style
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  white: {},
  formControl: {
   width: '100%'
  },
  button: {
   marginTop: theme.spacing(4),
   marginBottom: theme.spacing(4)
  },
}));


const Announcement = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [type, setType] = useState('0');
    const [data] = useState(props.location.state && props.location.state.record);

    const handleSubmit = (values, {setSubmitting, setErrors, setStatus}) => {
      setSubmitting(true)
      const announcement = {
        id: parseInt(type) + 1,
        message: values.message
      }
      axios
          .post( 
              ADMIN_UPDATEANNOUNCEMENT,
              announcement,
              {
                  headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Content-Type': 'application/json',
                  }
              },
          )
          .then( (response)  => {
             setSubmitting(false)

              history.push({
                 pathname: '/superadmin/dashboard'
              })
          })
          .catch( (error) => {
              setSubmitting(false)
              console.log(error.message)
          });
    }

    const handleType = (event, setFieldValue) => {
      const val = event.target.value
      setType(val);

      return setFieldValue('message', data[val].announcement)
    };

    return (
      <>
        <PageTitle>
          <Typography 
            component="h1" 
            variant="h1" 
            paragraph={false}
          >
            Announcements
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/dashboard">
              <HomeIcon fontSize="small" />
            </Link>
            <Link color="inherit" href="#">
              Dashboard
            </Link>
            <Link href="#" aria-current="page">
              Announcement
            </Link>
          </Breadcrumbs>
        </PageTitle>

        <Formik
            initialValues={{ 
              message: data[0].announcement,
            }}
            onSubmit={ handleSubmit }
            validationSchema={Yup.object().shape({
               message: Yup.string()
                  .required('Required'),
            })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            } = props;
               
            return (
              <form onSubmit={handleSubmit} >
                <Paper className="card-box">
                  <BoxTitle>Edit Announcement</BoxTitle>
                  <Box m={4} className="card-content">

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup aria-label="type" name="type" value={type} onChange={(e) => handleType(e, setFieldValue)}>
                          <FormControlLabel value="0" control={<Radio />} label="Global" />
                          <FormControlLabel value="1" control={<Radio />} label="Reminder to Admin" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextareaAutosize
                        className={classes.formControl}
                        aria-label="minimum height" 
                        rowsMin={10} 
                        placeholder="Type message here" 
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormHelperText>{(errors.message && touched.message) && errors.message}</FormHelperText>
                    </Grid>

                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      Update
                    </Button>

                  </Box>
                </Paper>
              </form>
            );
          }}
        </Formik>
      </>
    )
}


export default Announcement;