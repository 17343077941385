import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { 
    ADMIN_VERIFYACCOUNT,
    TENANT_VERIFYACCOUNT
} from '../../_helpers';

const useStyles = makeStyles(theme => ({
	 paper: {
		  display: 'flex',
		  flexDirection: 'column',
		  alignItems: 'center'
	 },
	 title: {
		  marginTop: theme.spacing(6),
	 }
}));


function VerifyAccount(props) {
	const classes = useStyles();
	const {email, code} = useParams();
	const [valid, setValid] = useState(false);

	const pathname = props.location.pathname;
	const values = {
		email:  email,
		code: code
	}

	useEffect(() => {

		async function fetchData() {
			axios
				.post(  
					ADMIN_VERIFYACCOUNT,
					values,
					{
						headers: {
							'Access-Control-Allow-Origin': '*',
							'Content-Type': 'application/json',
						}
					},
				)
				.then( (response)  => {
					if(response.status === 200){
						setValid(response.data.existing)
					}
				})
				.catch( (error) => {
					setValid(false)
				});
		}

		async function fetchTenantData() {
			axios
				.post(  
					TENANT_VERIFYACCOUNT,
					values,
					{
						headers: {
							'Access-Control-Allow-Origin': '*',
							'Content-Type': 'application/json',
						}
					},
				)
				.then( (response)  => {
					if(response.status === 200){
						setValid(response.data.existing)
					}
				})
				.catch( (error) => {
					setValid(false)
				});
		}

		if(pathname === ('/verify-account/' + values.email + '/' + values.code))
			fetchData();
		else
			fetchTenantData();

	}, []);

	 return (
		  <div className={classes.paper}>
				{ valid &&
					<>
					<Typography align='center' className={classes.title}>
						 Your account {email} has been verified. 
					</Typography>
					<Typography align='center'>
						Please check your email for your temporary password and 
						<Link to={ pathname === ('/verify-account/' + email + '/' + code) ? '/sign-in' : '/tenant/sign-in' }>sign-in</Link> again.
						</Typography>
					</>
				}
				{ !valid &&
					<Typography align='center' className={classes.title}>
						 Account is already verified or does not exist. Please check with system administrator.
					</Typography>
				}
		  </div>
	 );
}


export default VerifyAccount;