
export const authenticationService = {
	logout,
	logoutTenant,
	get currentUserValue () { return getCurrentUser() },
	get currentTenantValue () { return getCurrentTenant() }
}

function getCurrentUser() {
	const userSession = localStorage.getItem('user');
  	let user = userSession
    
  	if(userSession) user = JSON.parse(userSession)
  	return user
}

function getCurrentTenant() {
	const userSession = localStorage.getItem('tenant');
  	let user = userSession
    
  	if(userSession) user = JSON.parse(userSession)
  	return user
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
    localStorage.clear()
}

function logoutTenant() {
    // remove user from local storage to log user out
    localStorage.removeItem('tenant')
    localStorage.clear()
}