import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
   Box,
   Breadcrumbs,
   Button,
   Grid,
   Link,
   Paper,
   Typography,
   List,
   ListItem,
   ListItemText
} from '@material-ui/core';

import { 
   makeStyles 
} from '@material-ui/core/styles';

import {
   BoxTitle,
   PageTitle,
} from './../../../../_components';

import { 
    ADMIN_SAVEBOOKING
} from './../../../../_helpers';

import { 
   Home as HomeIcon
} from '@material-ui/icons';


// Style
const useStyles = makeStyles((theme) => ({
   formControl: {
      width: '100%'
   },
   button: {
      margin: theme.spacing(1)
   },
}));


const VerifyCreateBooking = (props) => {
   const classes = useStyles();
   const history = useHistory();
   const [booking] = useState(props.location.state && props.location.state.record);

   const goBack = () => {
      history.push({
         pathname: '/admin/create-booking',
         state: { record: booking }
      })
   }

   const createBooking = () => {

        const data = {
            bookingdate: booking.selectedDate,
            hour: booking.hour,
            minute: booking.minute,
            ampm: booking.ampm,
            tenant: booking.tenant,
            amount: booking.amount,
            facility: booking.facility,
            notes: booking.remarks,
            status: booking.status,
            location: booking.location,
            createdBy: booking.createdBy
        }

        axios
            .post(  
                ADMIN_SAVEBOOKING,
                data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
               const result = {
                  isSuccess: false,
                  message: ''
               }

               if(response.status === 200){
                  result.isSuccess = true;
                  result.message = response.data.data
               } else {
                  result.isSuccess = false;
                  result.message = response.data
               }

               history.push({
                  pathname: '/admin/verified-create-booking',
                  state: { record: booking, result: result }
               })
            
            })
            .catch( (error) => {
               const result = {
                  isSuccess: false,
                  message: error.message
               }
               history.push({
                  pathname: '/admin/verified-create-booking',
                  state: { record: booking, result: result }
               })
            });
   }

   return (
      <>
         <PageTitle>
            <Typography 
               component="h1" 
               variant="h1" 
               paragraph={false}
            >
               Bookings
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
               <Link color="inherit" href="/dashboard">
                  <HomeIcon fontSize="small" />
               </Link>
               <Link color="inherit" href="#">
                  Tenants
               </Link>
               <Link href="#" aria-current="page">
                  Create Booking
               </Link>
            </Breadcrumbs>
         </PageTitle>

         <Paper className="card-box">
            <BoxTitle>Verify Create Booking</BoxTitle>

            <Box m={4} className="card-content">
               <Grid container spacing={2} justify="flex-start">
                  <List>
                     <ListItem>
                        <ListItemText 
                           primary={booking.bookingdate.toLocaleDateString()}
                           secondary="Date of Booking" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={booking.hour + ':' + booking.minute + ' ' + booking.ampm}
                           secondary="Time" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={booking.tenantname}
                           secondary="Tenant Name" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={new Intl.NumberFormat("en-US", {
                              style: "currency",
                               currency: "PHP",
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2
                             }).format(booking.amount)}
                           secondary="Amount" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={booking.facilityname}
                           secondary="Facility" />
                     </ListItem>
                     <ListItem>
                        <ListItemText 
                           primary={ (booking.status === 1) ? 'Active' : 'Inactive' }
                           secondary="Status" />
                     </ListItem>
                     { booking.remarks !== "" &&
                     <ListItem>
                        <ListItemText 
                           primary={booking.remarks}
                           secondary="Remarks" />
                     </ListItem>
                    }
                  </List>

                  <Grid item xs={12} container justify="flex-start">
                     <Button
                        type="button"
                        variant="contained" 
                        color="default" 
                        className={classes.button}
                        onClick={ () => goBack() }
                     >
                        Back
                     </Button>
                     <Button 
                        type="button"
                        variant="contained" 
                        color="secondary" 
                        className={classes.button}
                        onClick={ () => createBooking() }
                     >
                        Create Booking
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Paper>
      </>
   )
}

export default VerifyCreateBooking;