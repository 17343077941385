import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme();

export default {
  root: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 0, 4)
    }
  }
}