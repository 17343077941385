import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom';

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  	Switch,
  	FormControlLabel,
  	Grid
} from '@material-ui/core';

import { 
  BoxTitle,
  PageTitle 
} from './../../../_components';

import { 
  Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    ADMIN_CREDITSBYLOCATION,
    ADMIN_CREDITSBYID
} from './../../../_helpers';

const ViewCredits = props => {
	const history = useHistory();
  	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
  	const [checked, setChecked] = useState(true);

  	useEffect(() => {
	 	async function fetchData() {
			const result = await axios.get(
		  		ADMIN_CREDITSBYLOCATION,
		  		{ params: { location: currentUser.locations, status: checked } }
			);
			const d = handleData(result.data)
			setData(d);
	 	}
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);

  	const handleData = (result) => {
		 	const record = [];
		 	result.map( (key, idx) => {
			  	const item = {
				 	id: idx,
				 	creditid: key.id,
				 	creditdate: new Date(key.creditdate).toLocaleDateString(),
					tenantcode: key.tenantcode,
				 	tenant: key.lastname + ', ' + key.firstname + ' ' + key.middlename,
				 	unit: key.roomnumber,
				 	refno: key.refno === null ? '-----' : key.refno,
				 	txnid: key.txnid === null ? '-----' : key.txnid,
				 	amount: formatNumber(key.amount.toFixed(2)),
				 	mannerofcreditid: key.mannerofcredit,
				 	mannerofcredit: key.name,
				 	notes: key.notes
			  	}

			  	record.push(item)
			  	return console.log('');
			})

			return record;
  	}

	const formatNumber = (decimalsFormated) => {
	    return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

  	const handleChange = async () => {
	   	let prev = checked
	   	setChecked(!prev);
	   	const result = await axios.get(
		  	ADMIN_CREDITSBYLOCATION,
		  	{ params: { location: currentUser.locations, status: !prev } }
		  );
			const d = handleData(result.data)
			setData(d);
  	};
  	

  	const columns = [
	  	{
			name: "creditid",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "creditdate",
		 	label: "Payment Date",
		 	options: {
		  		display: true,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "tenantcode",
		 	label: "Tenant Code",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "tenant",
		 	label: "Tenant",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "unit",
		 	label: "Unit",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "amount",
		 	label: "Amount",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "mannerofcredit",
		 	label: "Manner of Payment",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "notes",
		 	label: "Notes",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
			name: "txnid",
		 	label: "Transaction ID",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "refno",
		 	label: "Reference Number",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];

	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
			downloadOptions: {
			  filename: 'paymentHistory.csv',
			  separator: ',',
			  filterOptions: {
			    useDisplayedColumnsOnly: false,
			    useDisplayedRowsOnly: true
			  }
			},
  		print: false,
  		setRowProps: row => {
				return {
        	style: { cursor: "pointer" }
        };
  		},
  		onRowClick: async (rowData, rowMeta) => {
	 			const res = await axios.get(
					ADMIN_CREDITSBYID,
					{ params: { creditid: data[rowMeta.dataIndex].creditid } }
				);
				const result = res.data[0]

				result.selectedDate = new Date(result.creditdate)

				console.log(result)
	  			history.push({
			        pathname: '/admin/edit-credit',
			        state: { record: result }
			    }) 	
	  	}
	};


	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			  		Payments
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link color="inherit" href="#">
						Reports
			 		</Link>
			 		<Link href="#" aria-current="page">
						Payment History
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>

			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
			   <FormControlLabel
	        		control={ <Switch checked={checked} onChange={ handleChange } /> }
	        		label={ checked ? "Active" : "Archived" }
	      		/>
	    </Grid>

			<Paper className="card-box">
		  		<BoxTitle datePickerRange={false}>Payment History</BoxTitle>
				{data &&
					<MUIDataTable
						data={data}
						columns={columns}
						options={options}
					/>
				}
			</Paper>
		</>
  	)
}

export default ViewCredits;