import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Link,
  useHistory
} from "react-router-dom";

import {
    Avatar,
    Box,
    Collapse,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';

import { 
    makeStyles,
    useTheme
} from '@material-ui/core/styles';

import {
    BusinessOutlined as BusinessOutlinedIcon,
    DashboardTwoTone as DashboardTwoToneIcon,
    KeyboardArrowUp as ChevronUpIcon,
    KeyboardArrowDown as ChevronDownIcon,
    KeyboardArrowRight as ChevronRightIcon,
    PaymentOutlined as PaymentOutlinedIcon,
    PersonOutlined as PersonOutlinedIcon,
    Pool as PoolIcon,
} from '@material-ui/icons';

import { 
  authenticationService
} from './../../../../_services';

import balaySoliven from "assets/dorms/balay-soliven.jpg"
import dormsLogo from "assets/logo/dorms-logo-white.png"

// Style
const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        border: 0,
        boxShadow: '0 5px 5px rgba(0, 0, 0, 0.15)'
    },
    logoContainer: {
        backgroundColor: theme.palette.tertiary.main,
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        position: 'fixed',
        top: 0,
        width: drawerWidth,
        padding: theme.spacing(0, 2),
        zIndex: 5
    },
    logo: {
        width: theme.spacing(6),
        marginRight: theme.spacing(2)
    },
    userDetails: {
        backgroundImage: 'url('+ balaySoliven +')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(2)
    },
    avatar: {
        color: theme.palette.tertiary.contrastText,
        width: theme.spacing(7),
        height: theme.spacing(7),
        margin: `0 auto ${theme.spacing(1)}px`
    },
    smallText: {
        fontSize: 13,
        color: theme.palette.white
    },
    name: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.white
    },
    nested: {
        paddingLeft: theme.spacing(5)
    },
    listSubheader: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(4)
    },
    arrowIcon: {
        color: theme.palette.grey[400],
        marginRight: theme.spacing(1)
    },
    linkText: {
        color: 'inherit',
        textDecoration: 'none'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
}));


const Sidebar = props => {
    const { container, onSidebarOpen, mobileOpen } = props;

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [openAdmin, setOpenAdmin] = useState(true);
    const [openDorms, setOpenDorms] = useState(true);
    const [openFacilities, setOpenFacilities] = useState(true);
    const [openNatureDebit, setOpenNatureDebit] = useState(true);
    const [currentUser] = useState(authenticationService.currentUserValue);

    if(!currentUser) {
        authenticationService.logout()
        history.replace({
            pathname: '/sign-in'
        })
    }

    const handleClickAdmin = () => {
        setOpenAdmin(!openAdmin);
    };

    const handleClickDorms = () => {
        setOpenDorms(!openDorms);
    };

    const handleClickFacilities = () => {
        setOpenFacilities(!openFacilities);
    };

    const handleClickNatureDebit = () => {
        setOpenNatureDebit(!openNatureDebit);
    };

    const drawer = (
        <div className="sidebar">
            <Box className={classes.toolbar} />
            <Box 
                className={clsx(classes.toolbar, classes.logoContainer)}
                display="flex"
                alignItems="center"
            >
                <img src={dormsLogo} className={classes.logo} alt="Logo" />
            </Box>

            <Box
                component="div" 
                className={classes.userDetails} 
                textAlign="center"
            > 
                <Avatar className={classes.avatar} alt="Avatar">{ currentUser && currentUser.firstname.charAt(0) }</Avatar>

                <div>
                    <Typography component="span" className={classes.smallText}>Welcome</Typography>
                    <Typography className={classes.name}>{ currentUser && currentUser.firstname }</Typography>
                </div>
            </Box>

            <List component="div">
                <Link to="/superadmin/dashboard" className={classes.linkText}>
                    <ListItem button>
                        <ListItemIcon>
                            <DashboardTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </Link>

                <ListItem button onClick={handleClickAdmin}>
                    <ListItemIcon>
                        <PersonOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admin Users" />
                    { openAdmin ? <ChevronUpIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
                </ListItem>
                <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {[
                           { label: 'Create Admin User', link: 'create-admin-user' },
                           { label: 'View Admin Users', link: 'view-admin-users' },
                           { label: 'View Unverified Users', link: 'view-admin-users-unverified' },
                        ].map((item, index) => (
                            <Link key={index} to={item.link} className={classes.linkText}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    key={item.link}
                                >
                                    <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Collapse>


                <ListItem button onClick={handleClickDorms}>
                    <ListItemIcon>
                        <BusinessOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dorms" />
                    { openDorms ? <ChevronUpIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
                </ListItem>
                <Collapse in={openDorms} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {[
                            {label: 'Create Dorm', link: 'create-dorm' },
                            { label: 'View Dorms', link: 'view-dorms' },
                        ].map((item, index) => (
                            <Link key={index} to={item.link} className={classes.linkText}>
                                <ListItem 
                                    button 
                                    className={classes.nested}
                                    key={item.link}
                                >
                                    <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Collapse>

                <ListItem button onClick={handleClickFacilities}>
                    <ListItemIcon>
                        <PoolIcon />
                    </ListItemIcon>
                    <ListItemText primary="Facilities" />
                    { openFacilities ? <ChevronUpIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
                </ListItem>
                <Collapse in={openFacilities} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {[
                            { label: 'Create Facility', link: 'create-facility' },
                            { label: 'View Facilities', link: 'view-facilities' },
                        ].map((item, index) => (
                            <Link key={index} to={item.link} className={classes.linkText}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    key={item.link}
                                >
                                    <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Collapse>

                <ListItem button onClick={handleClickNatureDebit}>
                    <ListItemIcon>
                        <PaymentOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Nature of Bills" />
                    { openNatureDebit ? <ChevronUpIcon className="chevron-icon active" fontSize="small" /> : <ChevronDownIcon className="chevron-icon" fontSize="small" />}
                </ListItem>
                <Collapse in={openNatureDebit} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {[
                            { label: 'Create Nature of Bills', link: 'create-nature-of-debit' },
                            { label: 'View Nature of Bills', link: 'view-nature-of-debits' },
                        ].map((item, index) => (
                            <Link key={index} to={item.link} className={classes.linkText}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    key={item.link}
                                >
                                    <ChevronRightIcon fontSize="small" className={classes.arrowIcon} />
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Collapse>
            </List>
        </div>
    );

    return (
        <nav className={classes.drawer} aria-label="nav sidebar">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
            <Drawer
                container={container}
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={onSidebarOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawer}
            </Drawer>
            </Hidden>
      
            <Hidden xsDown implementation="css">
            <Drawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
            >
                {drawer}
            </Drawer>
            </Hidden>
        </nav>
    )
};


Sidebar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
    container: PropTypes.any,
    mobileOpen: PropTypes.bool
};

export default Sidebar;
