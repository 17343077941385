import React from 'react';

import {
  Box
} from '@material-ui/core';

import { 
  makeStyles
} from '@material-ui/core/styles';


// Style
const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  }
}));


const PageTitle = (props) => {
  const classes = useStyles();

  return (
    <Box 
      className={classes.root}
      paddingY={2}
      marginBottom={3}
    >
      {props.children}
    </Box>
  )
}


export default PageTitle;