import React, { useState } from 'react';
import {
    Link,
    useHistory
} from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    TextField,
    Typography 
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { 
    ADMIN_SIGNIN,
    ROLE_ADMIN,
    TENANT_SIGNIN,
    TRUE
} from '../../_helpers';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        marginTop: theme.spacing(3),
    }
}));

function SignIn(props) {
    const classes = useStyles();
    const history = useHistory();

    const [error, setError] = useState(null);

    const pathname = props.location.pathname;
    
    const handleSubmit = ( values, setSubmitting ) => {
        setSubmitting(true);
        console.log("===== START Logging in =====");
        console.log(values);
        console.log("===== END Logging in =====");

        if(pathname === '/sign-in') {
            adminSignIn(values, setSubmitting)
        }else {
            tenantSignIn(values, setSubmitting)
        }
    }

    const adminSignIn = ( values, setSubmitting ) => {
        axios
            .post(  
                ADMIN_SIGNIN,
                values,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
                if(response.status === 200){
                    let path = '/superadmin/dashboard'

                    if(response.data.record.forceChangePassword === TRUE )
                        path = '/change-password'
                    else
                    {
                        localStorage.setItem('user', JSON.stringify(response.data.record));

                        if(response.data.record.privilege === ROLE_ADMIN )
                            path = '/admin/dashboard'
                    }
                    
                    history.push({
                        pathname: path,
                        state: { record: response.data.record }
                    })

                }
            })
            .catch( (error) => {
                setSubmitting(false);
                (error.response) ? setError(error.response.data.msg) :  setError(error.message)
            });
    }


    const tenantSignIn  = ( values, setSubmitting ) => {
        axios
            .post(  
                TENANT_SIGNIN,
                values,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                },
            )
            .then( (response)  => {
                if(response.status === 200){
                    
                    let path = '/tenant/dashboard'
                    if(response.data.record.forceChangePassword === TRUE )
                        path = '/tenant/change-password'
                    else
                        localStorage.setItem('tenant', JSON.stringify(response.data.record));

                    history.push({
                        pathname: path,
                        state: { record: response.data.record }
                    })
                }
            })
            .catch( (error) => {
                setSubmitting(false);
                (error.response) ? setError(error.response.data.msg) :  setError(error.message)
            });
    }

    return (
        <div className={classes.paper}>
            <Typography className={classes.title} component="h2" variant="h1">
                Sign in
            </Typography>

            { error &&            
                <Alert severity="error">{ error } </Alert>
            }

            <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={ (values, { setSubmitting }) => { handleSubmit(values, setSubmitting) }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email()
                    .required('Required'),
                  password: Yup.string()
                    .required('Required'),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (


                    <form className={classes.form} onSubmit={handleSubmit} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.email && touched.email) && errors.email}
                            error={errors.email && touched.email}
                            autoComplete="email"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.password && touched.password) && errors.password}
                            error={errors.password && touched.password}
                            type="password"
                            id="password"
                        />
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to={ (pathname === '/sign-in') ? '/forgot-password' : '/tenant/forgot-password' } variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </form>

                  );
                }}
              </Formik>

        </div>
    );
}


export default SignIn;
