import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Container,
    CssBaseline,
 } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Copyright } from './components';

import dormsLogo from 'assets/logo/dorms-logo.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));


function Minimal(props) {
    const { children } = props;
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={ dormsLogo } alt="DORMS.PH" />
                {children}
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

Minimal.propTypes = {
    /**
    * Injected by the documentation to work in an iframe.
    * You won't need it on your project.
    */
    container: PropTypes.any,
};


export default Minimal;
