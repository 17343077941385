import React, {useState, useEffect} from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom';

import {
  	Breadcrumbs,
  	Link,
  	Paper,
  	Typography,
} from '@material-ui/core';

import {
   Alert
} from '@material-ui/lab';

import {
  	PageTitle
} from './../../../_components';

import { 
  	Home as HomeIcon
} from '@material-ui/icons';

import { 
  	authenticationService
} from './../../../_services';

import { 
    TENANT_ARCHIVE,
    ADMIN_PROFILEDETAILS
} from './../../../_helpers';

const ViewArchivedAccounts = props => {
	const history = useHistory();
 	const [data, setData] = useState(null);
  	const [currentUser] = useState(authenticationService.currentUserValue);
   	const [error, setError] = useState(null);
   	const [success, setSuccess] = useState(null);

	async function fetchData() {
		const result = await axios.get(
		  	TENANT_ARCHIVE, { }
		);
		const d = handleData(result.data)
		setData(d);
	}

  	useEffect(() => {
	 	if(currentUser) fetchData();
  	}, [setData, currentUser]);


  	const handleData = (result) => {
	 	const record = [];
	 	result.map( (key, idx) => {
		  	const item = {
			 	id: key.id,
			 	name: key.lastname + ', ' + key.firstname,
			 	unit: key.roomnumber,
			 	property: (key.property ===  null ) ? '---' : key.property,
			 	archivedate: new Date(key.dateupdated).toLocaleDateString()
		  	}

		  	record.push(item)
		  	return console.log('');
		})

		return record;
  	}

  	const convertDate = (datetime) => {
      const cdatetime = new Date(datetime);
      const cdate = cdatetime.toISOString().slice(0, 19).replace('T', ' ');
      return cdate.split(' ')[0];
  	}

  	const formatNum = (decimalsFormated) => {
    	return String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  	}

   const viewTenant = async (tenant_id) => {
      console.log(tenant_id)
      const tenants = await axios(
        ADMIN_PROFILEDETAILS,
        { params: { tenant: tenant_id} }
      );

      const account = handleAccount(tenants.data)

          history.push({
              pathname: '/superadmin/view-tenant',
              state: { record: account, prevPath: 'view-archived-accounts' }
          })  

   }

   const handleAccount = (data) => {
      const details = data.details;
      const ref = data.references;


      const birthdate = convertDate(details.birthdate)
      const startdate = convertDate(details.rentstart)

      let account = {
            tenantid: details.id,
            firstname: details.firstname, 
            middlename: details.middlename,
            lastname: details.lastname,
            address: details.address,
            birthdate: birthdate,
            gender: (details.gender === 1) ? 'Male' : 'Female',
            mobile: details.mobile,
            position: details.positioncourse,
            landline: details.landline,
            civilstatus: (details.civilstatus === 1) ? 'Single' : 'Married',
            email: details.email,
            partnersname: details.spouse,
            instagram: details.instagram,
            sourceoffunds: details.sourceofpayment,
            employername: details.nameempschool,
            employeraddress: details.addressempschool,
            tenantcode: details.tenantcode,
            roomnumber: details.roomnumber,
            rentamount: formatNum(details.rent),
            withaircon: (details.aircon === 1) ? 'Yes' : 'No',
            advance: formatNum(details.advance),
            deposit: formatNum(details.deposit),
            contractterm: details.contractterm,
            startdate: startdate,
            refid1: ref[0].id,
          refname1: ref[0].name,
            refaddress1: ref[0].address,
            refmobile1: ref[0].mobile,
            reflandline1: ref[0].landline,
            refrelationship1: ref[0].relationship,
            refid2: ref[1].id,
            refname2: ref[1].name,
            refaddress2: ref[1].address,
            refmobile2: ref[1].mobile,
            reflandline2: ref[1].landline,
            refrelationship2: ref[1].relationship
      }
      
      return account
    }

  	const columns = [
	  	{
			name: "id",
		 	label: "ID",
		 	options: {
		  		display: false,
		  		filter: false,
		  		sort: false,
			}
	  	},
	  	{
		 	name: "name",
		 	label: "Name",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "unit",
		 	label: "Unit #",
		 	options: {
		  		filter: true,
		  		sort: true,
		 	}
	  	},
	  	{
			name: "property",
		 	label: "Property",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
	  	{
			name: "archivedate",
		 	label: "Archive Date",
		 	options: {
		  		filter: true,
		  		sort: false,
		 	}
	  	},
  	];


	const options = {
 		filterType: 'multiselect',
  		responsive: 'vertical',
  		selectableRowsHideCheckboxes: true,
  		selectableRowsHeader: false,
  		viewColumns: false,
  		download: true,
		downloadOptions: {
			filename: 'archivedAccounts.csv',
			separator: ',',
			filterOptions: {
				useDisplayedColumnsOnly: true,
			   useDisplayedRowsOnly: true
			}
		},
  		print: false,
  		setRowProps: row => {
			return {
            	style: { cursor: "pointer" }
          	};
  		},
  		onRowClick: rowData => {
  			viewTenant(rowData[0])
	  	}
	};

  	return (
		<>
			<PageTitle>
		  		<Typography 
			 		component="h1" 
			 		variant="h1" 
			 		paragraph={false}
		  		>
			 		Archived Accounts
		  		</Typography>

		  		<Breadcrumbs aria-label="breadcrumb">
			 		<Link color="inherit" href="/dashboard">
						<HomeIcon fontSize="small" />
			 		</Link>
			 		<Link href="#" aria-current="page">
						View Archived Accounts
			 		</Link>
		  		</Breadcrumbs>
			</PageTitle>


         { error && <Alert severity="error">{ error } </Alert> }
         { success && <Alert severity="success">{ success } </Alert> }

		  	<Paper className="card-box">
				{data &&
					<MUIDataTable
						title={"Archived Accounts"}
						data={data}
						columns={columns}
						options={options}
					/>
				}
		 	 </Paper>
	 </>
  )
}

export default ViewArchivedAccounts;